import axios from "axios";
import Api from "./Api";

class Booking extends Api{


    createTalentBooking = async (receiverId,interview) => {
        return await axios.post(`${this.endPoint()}/bookings/${receiverId}`,interview,{ withCredentials: true });
    }
}

export default new Booking;