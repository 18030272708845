import styles from './styles.module.css';

const TextInputComponent = ({ label, placeholder, labelPresent, stateName, inputValue, handleUpdateItem, required, placeholderGrey, readOnly }) => {
    return <>
        {
            labelPresent ?
            <p className={styles.label}>{label}{required ? '*' : ''}</p> :
            <></>
        }
        <input 
            value={inputValue ? inputValue : ''} 
            onChange={stateName === 'name' ? () => {} : (e) => handleUpdateItem(stateName, e.target.value)} 
            className={`${styles.input__Item} ${(labelPresent || placeholderGrey) ? styles.label__Present : ''}`} 
            type={'text'} 
            placeholder={`${placeholder} ${(!labelPresent && required) ? '*' : ''}`} 
            readOnly={readOnly}
        />  
    </>
}

export default TextInputComponent;
