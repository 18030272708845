import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Router} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import history from './history';
import reportWebVitals from './reportWebVitals';
import "react-toastify/dist/ReactToastify.css";
import UserContextProvider from "./context/UserContext";

ReactDOM.render(
  <UserContextProvider>
                  <App />
      </UserContextProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
