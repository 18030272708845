import React, { useState, useEffect, useContext } from "react";
import "./Notification.css";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/spinners/LoadingSpinner/LoadingSpinner";
import image from "../imagess/playerPic.png";
import PageLayout from "../Layout/PageLayout";
import { UserContext } from "../../../context/UserContext";
import { getCoachWaves } from "../../../services/userService";
import { toast } from "react-toastify";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";

const Notifications = () => {

    const NotificationsLists = new Array(20).fill("John Doe");
    const [loading, setLoading] = useState(true);
const {userTokenDetails} = useContext(UserContext)

const [waves,setWaves] = useState([])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(()=>{
if(userTokenDetails?.accountType==="coach"){
    getCoachWaves(userTokenDetails.token).then(res=>{
        console.log(res)
        setWaves(res.data)
    }).catch(err=>{
        console.log(err)
        toast.error(err.response.data)
    })
}
    },[])
    
    //#00A01E

    return (
        <PageLayout>
            <div className="Notifications">
                <div className="Notifications__Content">
                    <div className="NC__Top">
                        <div className="NC__Big">
                        Notifications
                       {userTokenDetails?.accountType==="coach"?<ul>
                            <li>Posts</li>
                            <li>Interviews</li>
                        </ul>: 
                        <>
                        <br/>
                        <span>
                            All important updates!
                        </span></>}
                        </div>
                      
                    </div>
                    <div className="NC__Main">
                        {loading && <div className="Notifications__Loader">
                            <LoadingSpinner width={"100px"} height={"100px"} />
                        </div>}
                        {!loading && <div className="NC__Main__Content">
                            {waves.length===0 && userTokenDetails?.accountType==="coach"? 
                                <span className="NC__Big">No Notifications</span>:null
                            }
                            {waves.length >=1 && 
                                <ul>
                                    {waves.map((wave, idx) => (
                                        <li className="NC__Li" key={`notificationLists-${idx}`}>
                                            <Link to="#" className="NC__Li__Link"
                                            style={{textDecoration: "none"}}>
                                                {
                                                    wave?.player?.profilePhoto ?
                                                    <img src={wave?.player?.profilePhoto} alt="user profile" /> :
                                                    <AvatarComponent displayName={wave?.player?.newAccountStructure ? `${wave?.player?.firstName} ${wave?.player?.lastName}`: wave?.player?.name} width={'50px'} height={'50px'} />
                                                }
                                                <div className="NC__Li__Txt">
                                                    <span className="NC__Med">{wave?.player?.newAccountStructure ? `${wave?.player?.firstName} ${wave?.player?.lastName}`: wave?.player?.name}</span>
                                                    <span className="NC__Small">{wave?.message}</span>
                                                </div>
                                            </Link>
                                            <div className="NC__Svg">+</div>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default Notifications;