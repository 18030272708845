import React,{useContext, useEffect, useState} from "react";
import PostForm from "./PostForm";
import "./PostMain.css";
import PostComponent from "./PostComponent/PostComponent";
import PageLayout from "../Layout/PageLayout";
import {fetchPostsStart} from "../../../redux/post/post.actions";
import { connect, useDispatch, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectAllPosts, selectPostFetchingStatus} from "../../../redux/post/post.selectors";
import { UserContext } from "../../../context/UserContext";
import { getAllPosts } from "../../../services/userService";
import { toast } from "react-toastify";


const PostMain = () => {

   let {posts,userTokenDetails} = useContext(UserContext);
//    useEffect(()=>{
// if(posts?.length===0){
//     getAllPosts(userTokenDetails).then(res=>{
//         setPosts(res.data)
//     }).catch(err=>{
//         console.log(err);
//         toast.error(err.response.data)
//     })
// }
//    },[])

   

    return (
        <PageLayout>
            <div className="postMain">
                <PostForm />
                {posts?.map(post=><PostComponent post={post}/>)}
            </div>
        </PageLayout>
    )
}


export default PostMain;