const SVGs = {
    imgIcon: 
    <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H42V29H0V0ZM2.625 2.41667V26.5833H39.375V2.41667H2.625ZM5.26575 24.2319C5.26575 24.2319 7.99575 7.25967 12.0501 7.25967C16.1044 7.25967 17.8802 19.2318 21.1404 19.2318C24.4007 19.2318 24.1566 14.5544 26.5427 14.5544C28.9288 14.5544 37.1477 24.2331 37.1477 24.2331L5.26575 24.2319ZM32.8125 12.0833C32.2954 12.0833 31.7834 11.9896 31.3057 11.8074C30.828 11.6252 30.3939 11.3582 30.0283 11.0216C29.6626 10.685 29.3726 10.2854 29.1747 9.84556C28.9768 9.40576 28.875 8.93438 28.875 8.45833C28.875 7.98229 28.9768 7.51091 29.1747 7.07111C29.3726 6.6313 29.6626 6.23168 30.0283 5.89507C30.3939 5.55846 30.828 5.29144 31.3057 5.10927C31.7834 4.9271 32.2954 4.83333 32.8125 4.83333C33.8568 4.83333 34.8583 5.21525 35.5967 5.89507C36.3352 6.57489 36.75 7.49692 36.75 8.45833C36.75 9.41974 36.3352 10.3418 35.5967 11.0216C34.8583 11.7014 33.8568 12.0833 32.8125 12.0833Z" fill="#A0DBAA"/>
    </svg>,
    emoji: 
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_509_192)">
        <path d="M35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18Z" stroke="#A0DBAA"/>
        <path d="M10.515 23.621C10.56 23.8 11.683 28 18 28C24.318 28 25.44 23.8 25.485 23.621C25.5113 23.5154 25.5024 23.4042 25.4596 23.3042C25.4168 23.2042 25.3425 23.1209 25.248 23.067C25.1531 23.0133 25.0434 22.9918 24.9352 23.0057C24.8271 23.0196 24.7263 23.0681 24.648 23.144C24.629 23.163 22.694 25 18 25C13.306 25 11.37 23.163 11.352 23.145C11.2746 23.0685 11.1744 23.0192 11.0666 23.0046C10.9587 22.99 10.849 23.0108 10.754 23.064C10.6587 23.1179 10.5837 23.2015 10.5405 23.3021C10.4973 23.4028 10.4884 23.5148 10.515 23.621Z" fill="#A0DBAA"/>
        <path d="M12 17C13.3807 17 14.5 15.433 14.5 13.5C14.5 11.567 13.3807 10 12 10C10.6193 10 9.5 11.567 9.5 13.5C9.5 15.433 10.6193 17 12 17Z" fill="#A0DBAA"/>
        <path d="M24 17C25.3807 17 26.5 15.433 26.5 13.5C26.5 11.567 25.3807 10 24 10C22.6193 10 21.5 11.567 21.5 13.5C21.5 15.433 22.6193 17 24 17Z" fill="#A0DBAA"/>
        </g>
        <defs>
        <clipPath id="clip0_509_192">
        <rect width="36" height="36" fill="white"/>
        </clipPath>
        </defs>
    </svg>,
    send: 
    <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1067 16.5H4.49975L1.28713 3.71936C1.26655 3.64509 1.25402 3.56881 1.24975 3.49186C1.214 2.32024 2.50425 1.50774 3.62225 2.04399L33.7497 16.5L3.62225 30.956C2.51725 31.4874 1.24325 30.6976 1.24975 29.5471C1.25304 29.4443 1.2711 29.3425 1.30338 29.2449L3.68725 21.375" stroke="#A0DBAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    filter:
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 20.4801L12.667 21.8134V13.0267C12.6655 12.677 12.5267 12.3418 12.2803 12.0934L3.64033 3.33341H24.387L15.7603 12.0934C15.514 12.3418 15.3751 12.677 15.3737 13.0267L15.3337 23.3334L18.0003 24.6667V13.5734L26.7737 4.66675C27.1246 4.3107 27.3251 3.83328 27.3337 3.33341V2.00008C27.3337 1.64646 27.1932 1.30732 26.9431 1.05727C26.6931 0.807224 26.354 0.666748 26.0003 0.666748H2.00033C1.6467 0.666748 1.30757 0.807224 1.05752 1.05727C0.807468 1.30732 0.666992 1.64646 0.666992 2.00008V3.33341C0.675522 3.83328 0.87604 4.3107 1.22699 4.66675L10.0003 13.5734V20.4801Z" fill="#4ECB71"/>
    </svg>,
    search:
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.4195 30.2134L22.0214 19.8152C23.635 17.7292 24.5078 15.1787 24.5078 12.4961C24.5078 9.28496 23.2546 6.27402 20.9884 4.00381C18.7222 1.73359 15.7032 0.484375 12.4961 0.484375C9.28897 0.484375 6.27002 1.7376 4.00381 4.00381C1.73359 6.27002 0.484375 9.28496 0.484375 12.4961C0.484375 15.7032 1.7376 18.7222 4.00381 20.9884C6.27002 23.2586 9.28496 24.5078 12.4961 24.5078C15.1787 24.5078 17.7252 23.635 19.8112 22.0254L30.2094 32.4195C30.2399 32.45 30.2761 32.4742 30.3159 32.4908C30.3558 32.5073 30.3985 32.5158 30.4416 32.5158C30.4847 32.5158 30.5274 32.5073 30.5673 32.4908C30.6071 32.4742 30.6433 32.45 30.6738 32.4195L32.4195 30.6778C32.45 30.6473 32.4742 30.6111 32.4908 30.5713C32.5073 30.5315 32.5158 30.4887 32.5158 30.4456C32.5158 30.4025 32.5073 30.3598 32.4908 30.3199C32.4742 30.2801 32.45 30.2439 32.4195 30.2134ZM18.8383 18.8383C17.1406 20.5319 14.8904 21.4648 12.4961 21.4648C10.1018 21.4648 7.85156 20.5319 6.15391 18.8383C4.46025 17.1406 3.52734 14.8904 3.52734 12.4961C3.52734 10.1018 4.46025 7.84756 6.15391 6.15391C7.85156 4.46025 10.1018 3.52734 12.4961 3.52734C14.8904 3.52734 17.1446 4.45625 18.8383 6.15391C20.5319 7.85156 21.4648 10.1018 21.4648 12.4961C21.4648 14.8904 20.5319 17.1446 18.8383 18.8383Z" 
        fill="#545454"/>
    </svg>,
    interview:
    <svg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26 3.9H29.6C30.5548 3.9 31.4705 4.31089 32.1456 5.04228C32.8208 5.77368 33.2001 6.76566 33.2001 7.8V35.1C33.2001 36.1343 32.8208 37.1263 32.1456 37.8577C31.4705 38.5891 30.5548 39 29.6 39H4.40005C3.44527 39 2.5296 38.5891 1.85446 37.8577C1.17933 37.1263 0.800049 36.1343 0.800049 35.1V7.8C0.800049 5.655 2.42005 3.9 4.40005 3.9H8.00005V0H11.6V3.9H22.4V0H26V3.9ZM4.40005 11.7V35.1H29.6V11.7H4.40005ZM15.2 21.45V17.55H18.8V21.45H22.4V25.35H18.8V29.25H15.2V25.35H11.6V21.45H15.2Z" fill="#0FA958"/>
    </svg>,
    check:
    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
    style={{color: "#A0DBAA"}}>
        <polygon fill="#A0DBAA" fillRule="evenodd" points="9.707 14.293 19 5 20.414 6.414 9.707 17.121 4 11.414 5.414 10"/>
    </svg>,
    comment:
    <svg width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"
    style={{color: "#A0DBAA"}}>
        <path fill="#A0DBAA" d="M 3 6 L 3 26 L 12.585938 26 L 16 29.414063 L 19.414063 26 L 29 26 L 29 6 Z M 5 8 L 27 8 L 27 24 L 18.585938 24 L 16 26.585938 L 13.414063 24 L 5 24 Z M 9 11 L 9 13 L 23 13 L 23 11 Z M 9 15 L 9 17 L 23 17 L 23 15 Z M 9 19 L 9 21 L 19 21 L 19 19 Z"/>
    </svg>,
    delete:
    <svg width="32px" height="32px" fill="none" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg"
    style={{color: "#A0DBAA"}}>
        <defs style={{fill:"none"}}></defs><title>trash-can</title><rect x="12" y="12" width="2" height="12" fill="black" /><rect x="18" y="12" width="2" height="12" fill="black" /><path fill="black" d="M4,6V8H6V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V8h2V6ZM8,28V8H24V28Z"/><rect x="12" y="2" width="8" height="2" fill="black" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" color="black" />
    </svg>,
    share:
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve" style={{color: "#A0DBAA"}} fill="#A0DBAA">
        <g>
            <g>
                <path fill="#A0DBAA" d="M512,241.7L273.643,3.343v156.152c-71.41,3.744-138.015,33.337-188.958,84.28C30.075,298.384,0,370.991,0,448.222v60.436
                    l29.069-52.985c45.354-82.671,132.173-134.027,226.573-134.027c5.986,0,12.004,0.212,18.001,0.632v157.779L512,241.7z
                    M255.642,290.666c-84.543,0-163.661,36.792-217.939,98.885c26.634-114.177,129.256-199.483,251.429-199.483h15.489V78.131
                    l163.568,163.568L304.621,405.267V294.531l-13.585-1.683C279.347,291.401,267.439,290.666,255.642,290.666z"/>
            </g>
        </g>
    </svg>,
    like:
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 486.926 486.926" xmlSpace="preserve" style={{color: "#A0DBAA"}}>
        <g>
            <path fill="#A0DBAA" d="M462.8,181.564c-12.3-10.5-27.7-16.2-43.3-16.2h-15.8h-56.9h-32.4v-75.9c0-31.9-9.3-54.9-27.7-68.4
                c-29.1-21.4-69.2-9.2-70.9-8.6c-5,1.6-8.4,6.2-8.4,11.4v84.9c0,27.7-13.2,51.2-39.3,69.9c-19.5,14-39.4,20.1-41.5,20.8l-2.9,0.7
                c-4.3-7.3-12.2-12.2-21.3-12.2H24.7c-13.6,0-24.7,11.1-24.7,24.7v228.4c0,13.6,11.1,24.7,24.7,24.7h77.9c7.6,0,14.5-3.5,19-8.9
                c12.5,13.3,30.2,21.6,49.4,21.6h65.9h6.8h135.1c45.9,0,75.2-24,80.4-66l26.9-166.9C489.8,221.564,480.9,196.964,462.8,181.564z
                M103.2,441.064c0,0.4-0.3,0.7-0.7,0.7H24.7c-0.4,0-0.7-0.3-0.7-0.7v-228.4c0-0.4,0.3-0.7,0.7-0.7h77.9c0.4,0,0.7,0.3,0.7,0.7
                v228.4H103.2z M462.2,241.764l-26.8,167.2c0,0.1,0,0.3-0.1,0.5c-3.7,29.9-22.7,45.1-56.6,45.1H243.6h-6.8h-65.9
                c-21.3,0-39.8-15.9-43.1-36.9c-0.1-0.7-0.3-1.4-0.5-2.1v-191.6l5.2-1.2c0.2,0,0.3-0.1,0.5-0.1c1-0.3,24.7-7,48.6-24
                c32.7-23.2,49.9-54.3,49.9-89.9v-75.3c10.4-1.7,28.2-2.6,41.1,7c11.8,8.7,17.8,25.2,17.8,49v87.8c0,6.6,5.4,12,12,12h44.4h56.9
                h15.8c9.9,0,19.8,3.7,27.7,10.5C459,209.864,464.8,225.964,462.2,241.764z"/>
        </g>
    </svg>,
    thickLike:
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 487.57 487.57" xmlSpace="preserve" style={{color: "#A0DBAA"}}>
        <g>
            <path fill="#A0DBAA" d="M137.482,253.8c0.7-2,1.5-4.2,2.4-6.5l0,0l0,0c3.8-9.4,10.1-20.9,19.9-31.2c46-35.3,94.2-168,94.2-168s-7.5-48.1,15-48.1
                s76,15,43.9,112.3c-32.1,97.4,0,82.4,0,82.4c7.4-1.7,14.2-3.1,20.7-4.2l0,0c0,0,92.7-20.8,126.9,10.5c27.4,25,6.1,58,6.1,58
                s38.5,34.3-0.7,75.3c0,0,25.1,41.5-19,74.6c0,0,20.5,57.1-61.4,74.5c-24.1,5.1-54.4,4.9-83,2.7c-71.5,1.5-142.6-7.8-142.6-7.8
                l-46-200.1C127.782,269.8,134.382,260.1,137.482,253.8z M4.182,281l-0.1,200h108.7c9.7,0,16-7.7,14-17.2l-34.8-165.6
                c-2-9.5-11.5-17.2-21.2-17.2L4.182,281L4.182,281z"/>
        </g>
    </svg>,
    eyeShow:
    <svg width="35px" height="35px" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.3121 13.4208C41.2996 14.7125 41.2996 16.4562 40.3121 17.7458C37.2017 21.8062 29.7392 30.1667 21.0267 30.1667C12.3142 30.1667 4.85167 21.8062 1.74126 17.7458C1.26081 17.1274 1 16.3665 1 15.5833C1 14.8002 1.26081 14.0393 1.74126 13.4208C4.85167 9.36042 12.3142 1 21.0267 1C29.7392 1 37.2017 9.36042 40.3121 13.4208V13.4208Z" 
        stroke="#A0DBAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.0267 21.8333C24.4785 21.8333 27.2767 19.0351 27.2767 15.5833C27.2767 12.1316 24.4785 9.33334 21.0267 9.33334C17.5749 9.33334 14.7767 12.1316 14.7767 15.5833C14.7767 19.0351 17.5749 21.8333 21.0267 21.8333Z" 
        stroke="#A0DBAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    eyeHide:
    <svg width="36px" height="36px" viewBox="0 0 36 36" 
    version="1.1"  preserveAspectRatio="xMidYMid meet" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>eye-hide-line</title>
        <path d="M25.19,20.4A6.78,6.78,0,0,0,25.62,18a6.86,6.86,0,0,0-6.86-6.86,6.79,6.79,0,0,0-2.37.43L18,13.23a4.78,4.78,0,0,1,.74-.06A4.87,4.87,0,0,1,23.62,18a4.79,4.79,0,0,1-.06.74Z" 
        className="clr-i-outline clr-i-outline-path-1"></path>
        <path d="M34.29,17.53c-3.37-6.23-9.28-10-15.82-10a16.82,16.82,0,0,0-5.24.85L14.84,10a14.78,14.78,0,0,1,3.63-.47c5.63,0,10.75,3.14,13.8,8.43a17.75,17.75,0,0,1-4.37,5.1l1.42,1.42a19.93,19.93,0,0,0,5-6l.26-.48Z" 
        className="clr-i-outline clr-i-outline-path-2"></path>
        <path d="M4.87,5.78l4.46,4.46a19.52,19.52,0,0,0-6.69,7.29L2.38,18l.26.48c3.37,6.23,9.28,10,15.82,10a16.93,16.93,0,0,0,7.37-1.69l5,5,1.75-1.5-26-26Zm9.75,9.75,6.65,6.65a4.81,4.81,0,0,1-2.5.72A4.87,4.87,0,0,1,13.9,18,4.81,4.81,0,0,1,14.62,15.53Zm-1.45-1.45a6.85,6.85,0,0,0,9.55,9.55l1.6,1.6a14.91,14.91,0,0,1-5.86,1.2c-5.63,0-10.75-3.14-13.8-8.43a17.29,17.29,0,0,1,6.12-6.3Z" 
        className="clr-i-outline clr-i-outline-path-3"></path>
        <rect x="0" y="0" width="36" height="36" fillOpacity="0"/>
    </svg>
}

export default SVGs;