import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../imagess/logo-dark.png";
import NavData from "./NavbarData";
import { LOGOUT_USER, UserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouse, faCalendarPlus,
    faMessage, faUser, faBell, faCheck, faShield, faUserShield, faShirt
} from "@fortawesome/free-solid-svg-icons";
import { FiHome, FiMessageSquare, FiShoppingCart } from 'react-icons/fi';
import { BsCalendarCheck, BsFillPatchCheckFill } from 'react-icons/bs';
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";

const Navbar = ({ closeNav, navRef }) => {

    const path = window.location.pathname;
    // const navigate = useNavigate();
    const isRoute = (link) => {
        if (link === "/test/posts") return path.includes("/test/post");
        return path.includes(link)
    };
    const [selectedNav, setSelectedNav] = useState(0);
    const { user } = useContext(UserContext);

    // const handleNavClick = (idx, link) => {
    //     setSelectedNav(idx);
    //     if(link==="#") return;
    //     navigate(link);
    // }

    const { userTokenDetailsDispatch } = useContext(UserContext);
    const navigate = useNavigate()

    const handleLogout = () => {
        userTokenDetailsDispatch({ type: LOGOUT_USER })
        navigate("/login")
    }

    const navListIcons = {
        "Profile": (link) => <FontAwesomeIcon icon={faUser} color={isRoute(link) ? "#00A01E" : "black"} />,
        "Messages": (link) => <FiMessageSquare color={isRoute(link) ? "#00A01E" : "black"} />,
        "Interview": (link) => <BsCalendarCheck color={isRoute(link) ? "#00A01E" : "black"} />,
        "Notifications": (link) => <FontAwesomeIcon icon={faBell} color={isRoute(link) ? "#00A01E" : "black"} />,
        "Home": (link) => <FiHome color={isRoute(link) ? "#00A01E" : "black"} />,
        "Verification": (link) => <FontAwesomeIcon icon={faUserShield} color={isRoute(link) ? "#00A01E" : "black"} />,
        "Shop Kits": (link) => <FiShoppingCart color={isRoute(link) ? "#00A01E" : "black"} />,
    }

    return (
        <nav className={`sp__Nav ${closeNav}`} ref={navRef}>
            <div className="sp__Navv">
                {/* <div className="nav__Logo">
                    <img src={logo} />
                </div> */}
                <div className="nav__User__Avatar__Wrapper">
                    <div className="nav__User__Avatar__Container">
                        <AvatarComponent displayName={user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user?.name} width={'38px'} handleNavIconClick={() => navigate(`/${user?.username ? user?.username : 'user-profile'}`)} />
                        <div>
                            <span>{user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user?.name}</span>
                            <div className="nav__User__Details">
                                {
                                    user.accountDetailsVerified && user.accountDetailsVerified === "true" ?
                                        <BsFillPatchCheckFill color="green" /> : <></>
                                }
                                <span>{user?.accountType === 'coach' ? 'scout' : 'athlete'}</span>
                            </div>
                        </div>
                    </div>
                    {
                        !user.accountDetailsVerified || user.accountDetailsVerified === "false" ?
                            <span className="not__Verified__Info">Unverified Account </span> :
                            <></>
                    }
                </div>
                <ul className="nav__ul">
                    {NavData.map((val, idx) => (
                        <li className={`nav__li ${isRoute(val.link) ? 'active' : ''}`} key={`nav__li-${idx}`}>
                            <Link to={val.link} style={{ textDecoration: "none" }}
                                className="nav__Link">
                                {/* {val.link === path ? val.svg_active : val.svg_inactive} */}
                                {navListIcons[val.name] ? navListIcons[val.name](val.link) : <></>}
                                <span style={{ color: isRoute(val.link) ? "#00A01E" : "black" }}>
                                    {val.name}
                                </span>
                            </Link>
                        </li>
                    ))}
                    {/* <li className="nav__li pool">
                        <Link to={"/test/pool"} style={{textDecoration: "none"}}
                        className="nav__Link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{color: path.includes("pool")?"#00A01E":"black"}} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6">
                                <circle cx="12" cy="7" r="4" className="sc-htoDjs dXGcZC mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6"></circle>
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" className="sc-gzVnrw iLnVEa mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6"></path>
                            </svg>
                            <span style={{color: path.includes("pool")?"#00A01E":"black"}}>
                                Pool
                            </span>
                        </Link>
                    </li> */}
                </ul>
            </div>
            {/* <div className="nav__Link logout" onClick={handleLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" 
                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round" className="feather feather-log-out" 
                style={{color: "black"}}>
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                <span>Logout</span>
            </div> */}
        </nav>
    )
}

export default Navbar;