import { IoMdClose } from "react-icons/io";
import styles from "./styles.module.css";
import verificationImg from "./assets/account-verification.png";
import React, { useContext, useRef, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { toast } from "react-toastify";
import uploadImgVector from "./assets/upload-illustration.png";
import useClickInside from "../../../hooks/useClickInside";
import { convertFileObjectToImageStr } from "../../../utils/ImageHelper";
import { UserContext } from "../../../context/UserContext";
import httpInstance from "../../../services/httpService";

const AccountVerification = ({ handleClose }) => {
  const [showVerificationSteps, setShowVerificationSteps] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [localUserStream, setLocalUserStream] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [isFileUploaded, setFileUploaded] = useState(false);
  const [fileUploadPreview, setFileUploadPreview] = useState(null);
  const [dataToSend, setDataToSend] = useState({
    userImage: null,
    userFile: null,
    currency: null,
  });

  const userVideoRef = useRef();
  const canvasRef = useRef();
  const userImageRef = useRef();
  const userFileRef = useRef();
  const uploadContainerRef = useRef();
  const { userTokenDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useClickInside(uploadContainerRef, () => userFileRef.current.click());

  const updateDataToSend = (key, value) =>
    setDataToSend((prevValue) => {
      return { ...prevValue, [key]: value };
    });

  const closeModal = () => {
    if (!handleClose || typeof handleClose !== "function") return;

    setCurrentPage(1);
    setShowVerificationSteps(false);
    setImageCaptured(false);

    if (localUserStream) localUserStream.getVideoTracks()[0].stop();
    handleClose();
  };

  const handleVerificationBtnClick = async (userImageCaptured = false) => {
    if (currentPage > 3) return;

    if (currentPage === 1) {
      if (userImageCaptured) {
        localUserStream.getVideoTracks()[0].stop();
        setCurrentPage(currentPage + 1);
        return;
      }

      try {
        let userStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        setLocalUserStream(userStream);

        userVideoRef.current.srcObject = userStream;
        userVideoRef.current.play();

        return;
      } catch (error) {
        toast.error("Verification failed. Please click allow on the prompt");
        return closeModal();
      }
    }

    if (currentPage === 2) {
      if (!dataToSend.userFile)
        return toast.info("Please upload a id to continue");
      if (userTokenDetails.accountType === "player") {
        sendDataToServer();
        return;
      }

      setCurrentPage(currentPage + 1);
    }

    if (currentPage === 3) {
      if (!dataToSend.currency)
        return toast.info("Please select a currency to continue");
      sendDataToServer();
    }
  };

  const captureUserImage = () => {
    const context = canvasRef.current.getContext("2d");

    const [videoWidth, videoHeight] = [
      userVideoRef.current.videoWidth,
      userVideoRef.current.videoHeight,
    ];

    canvasRef.current.width = videoWidth ? videoWidth : 160;
    canvasRef.current.height = videoHeight ? videoHeight : 160;

    context.drawImage(
      userVideoRef.current,
      0,
      0,
      videoWidth ? videoWidth : 160,
      videoHeight ? videoHeight : 160
    );

    const imgData = canvasRef.current.toDataURL("image/png");

    userImageRef.current.src = imgData;

    setImageCaptured(true);
    updateDataToSend("userImage", imgData);
  };

  const clearImage = () => {
    setImageCaptured(false);
  };

  const handleAddImageId = (fileUploaded) => {
    updateDataToSend("userFile", fileUploaded);

    convertFileObjectToImageStr(fileUploaded)
      .then((res) => {
        setFileUploadPreview(res);
        setFileUploaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendDataToServer = async () => {
    console.log(dataToSend);
    const formData = new FormData();
    // formData.append("image", dataToSend.userImage)
    // formData.append("doc", dataToSend.userFile)
    // setLoading(true)
    // try {
    //     const res = await httpInstance.post("/kyc")
    //     toast(res.data, {type:"success"})
    //     setLoading(false)
    //     closeModal();
    // } catch (error) {
    //     setLoading(false)
    //     toast(error?.response?.data?.message, {type:"error"})
    // }
    // toast.infos('Feature still in development');
    toast(
      "Document is under review, you would be notified when review is complete!",
      { type: "success" }
    );
    closeModal();
  };

  return (
    <>
      <div className={styles.modal__Overlay}>
        <div className={styles.modal__Container}>
          <div className={styles.close__Container} onClick={closeModal}>
            <IoMdClose />
          </div>
          {!showVerificationSteps ? (
            <>
              <div className={styles.intro__Section}>
                <h4>
                  <b>Verified Sportspadi Account</b>
                </h4>
                <p>
                  Verifying your account on Sportspadi unlocks exclusive
                  benefits.
                </p>
              </div>
              <div className={styles.main__Section}>
                <div className={styles.benefit__Wrapper}>
                  {React.Children.toArray(
                    verificationBenefits.map((item) => {
                      return (
                        <div className={styles.benefit__Item}>
                          <AiOutlineCheck className={styles.check__Icon} />
                          <div>
                            <p>
                              <b>{item.title}</b>
                            </p>
                            <p>{item.benefit}</p>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div className={styles.image__Illustration}>
                  <img
                    src={verificationImg}
                    alt="verify account illustration"
                  />
                </div>
              </div>
              <button
                className={styles.btn}
                onClick={() => setShowVerificationSteps(true)}
              >
                <span>Start Verification</span>
              </button>
            </>
          ) : (
            <>
              <div className={`${styles.intro__Section} ${styles.center}`}>
                <h4>
                  <b>{pageContents[currentPage]?.title}</b>
                </h4>
              </div>
              <div className={styles.verification__Content}>
                {currentPage === 1 ? (
                  <>
                    {!localUserStream ? (
                      <div className={styles.user__Image}></div>
                    ) : (
                      <video
                        className={
                          imageCaptured
                            ? styles.no__User__Image
                            : styles.user__Image
                        }
                        ref={userVideoRef}
                      ></video>
                    )}
                    <img
                      className={
                        !imageCaptured
                          ? styles.no__User__Image
                          : styles.user__Image
                      }
                      ref={userImageRef}
                      alt="current user"
                    />
                    <canvas
                      style={{ display: "none" }}
                      ref={canvasRef}
                    ></canvas>
                  </>
                ) : currentPage === 2 ? (
                  <>
                    <input
                      ref={userFileRef}
                      type={"file"}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={({ target }) =>
                        handleAddImageId(target.files[0])
                      }
                    />
                    <div
                      className={styles.upload__Container}
                      ref={uploadContainerRef}
                    >
                      <img
                        src={
                          isFileUploaded && fileUploadPreview
                            ? fileUploadPreview
                            : uploadImgVector
                        }
                        alt={isFileUploaded ? "government id" : "upload vector"}
                        className={
                          isFileUploaded && dataToSend.userFile
                            ? styles.upload__Preview
                            : ""
                        }
                      />
                    </div>
                  </>
                ) : currentPage === 3 ? (
                  <>
                    <span className={styles.processing__Fee}>₦5000</span>

                    <div className={styles.currency__Dropdown}>
                      <span>Select currency</span>
                      <select
                        onChange={({ target }) =>
                          updateDataToSend("currency", target.value)
                        }
                      >
                        <option disabled selected>
                          Select currency
                        </option>
                        <option value={"Naira"}>Naira</option>
                        <option value={"Dollar"}>Dollar</option>
                      </select>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <p className={styles.verification__Info__Content}>
                {currentPage === 1 && imageCaptured ? (
                  <>
                    <span style={{ color: "#00A01E" }}>Image taken!</span>
                    <br />
                    Please verify your face fills the circle before continuing.
                  </>
                ) : (
                  pageContents[currentPage]?.infoText
                )}
              </p>
              <button
                className={`${styles.btn} 
                                ${
                                  localUserStream &&
                                  currentPage === 1 &&
                                  imageCaptured
                                    ? styles.grey__Btn
                                    : ""
                                }`}
                onClick={
                  localUserStream && currentPage === 1
                    ? imageCaptured
                      ? () => clearImage()
                      : () => captureUserImage()
                    : () => handleVerificationBtnClick()
                }
              >
                <span>
                  {localUserStream && currentPage === 1
                    ? imageCaptured
                      ? "Take again"
                      : "Capture image"
                    : pageContents[currentPage]?.buttonText}
                </span>
              </button>
              {localUserStream && currentPage === 1 && imageCaptured && (
                <button
                  className={styles.btn}
                  onClick={() => handleVerificationBtnClick(true)}
                >
                  <span>Next step</span>
                </button>
              )}
              <span className={styles.verification__Page__Indicator}>
                {currentPage}/
                {userTokenDetails.accountType === "player" ? 2 : 3}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const pageContents = {
  1: {
    title: "Verify your Profile",
    infoText:
      "Check your lighting, ensure you are in a well-lighted environment. Place your face in the circle ",
    buttonText: "Proceed Now",
  },
  2: {
    title: "Verify your Identity",
    infoText:
      "Upload a picture of a government approved ID card - e.g NIN, Drivers Licence, International Passport etc",
    buttonText: "Proceed Now",
  },
  3: {
    title: "Processing Fee",
    infoText: "This is a onetime payment to process your verification",
    buttonText: "Pay Now",
  },
};

const verificationBenefits = [
  {
    title: "Increased visibility",
    benefit:
      "Verified accounts receives more exposure and visibility on our platform.",
  },
  {
    title: "Access to exclusive features",
    benefit: "Verified accounts have access to exclusive features.",
  },
  {
    title: "Trust and credibility",
    benefit: "A verified account can help you establish trust and credibility.",
  },
  {
    title: "Opportunities for sponsorship and endorsements",
    benefit:
      "Verified accounts may be more attractive to potential sponsors or brands.",
  },
  {
    title: "Priority consideration for club recruitment",
    benefit:
      "Verified athletes may receive priority consideration for recruitment by clubs.",
  },
  {
    title: "Opportunities for mentorship or coaching",
    benefit: "Verified athletes will have access to mentorship opportunities.",
  },
  {
    title: "Exclusive scouting invitations",
    benefit:
      "Verified athletes will receive invitations to attend scouting & exclusive events.",
  },
];

export default AccountVerification;
