import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";
import { getUserDetailsFromUsername, getUserPostsForExternalUser } from "../../services/userService";
import PageLayout from "../SportsPadi/Layout/PageLayout";
import UserProfilePage from "../SportsPadi/ProfilePage/UserProfilePage";
import ball from "../SportsPadi/imagess/coverPic.png";
import profilePic from "../SportsPadi/imagess/playerPic.png";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserSkills from "../SportsPadi/ProfilePage/Skills/UserSkills";
import PostComponent from "../SportsPadi/Posts/PostComponent/PostComponent";
import React from "react";
import AwardsPage from "../SportsPadi/ProfilePage/AwardsPage";
import AvatarComponent from "../../components/AvatarComponent/AvatarComponent";

const VerifyUserProfilePage = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const [ currentUserDetails, setCurrentUserDetails ] = useState(null);
    const [ userDetailsLoading, setUserDetailsLoading ] = useState(true);
    const [ userPosts, setUserPosts ] = useState([])
    const { userTokenDetails } = useContext(UserContext);
    const { pathname } = useLocation();

    useEffect(() => {

        if (!username) return navigate("/")

        setUserDetailsLoading(true);
        
        getUserDetailsFromUsername(username).then(async (res) => {
            let fetchedUserPosts;
            
            try {
                fetchedUserPosts = await (await getUserPostsForExternalUser(username)).data;
                setUserPosts(fetchedUserPosts);      
            } catch (error) {
                console.log(error.response ? error.response.data : error.message);
            }

            setUserDetailsLoading(false);
            
            if (!fetchedUserPosts) return setCurrentUserDetails(res.data);
            setCurrentUserDetails({...res.data, posts: fetchedUserPosts});

        }).catch(err => {
            console.log(err.response ? err.response.data : err.message);
            setUserDetailsLoading(false);
            toast.error(err.response ? err.response.data : err.message);
        })

    }, [username])

    const handleCopyUsername = async () => {
        await navigator.clipboard.writeText(window.location.origin + "/" + currentUserDetails.username)
        toast.success("Copied username")
    }

    const navigateTo = (link) => {
        if (!link) return navigate(`/${currentUserDetails.username}`)
        
        navigate(`/${currentUserDetails.username}/${link}`)
    }

    const PostsRoute = () => (
        <div className="ppC__Route">
            {React.Children.toArray(userPosts.map(post => <PostComponent post={post} usernameToRouteTo={username} disableBtns={userTokenDetails ? false : true} accountType={currentUserDetails.accountType} />))}
        </div>
    );
    const SkillsRoute = () => (
        <div className="ppC__Route">
            <UserSkills hideSkillsBtns={true} />
        </div>
    );

    if (userDetailsLoading) return <p style={{ textAlign: "center" }}>Loading...</p>

    if (!currentUserDetails) return <p style={{ textAlign: "center" }}>User not found</p>
    
    if (userTokenDetails) return <UserProfilePage userDetails={currentUserDetails} />

    return <>
        <PageLayout noCurrentUser={true}>
            <div className="profilePage">
                <div className="profilePage__Content">
                    <div className="pP__Content__Top">
                        <div className="ppC__Top">
                            <img src={ball} alt="ball bg" />
                            <div className="ppC__Top__Absolute">
                                <div className="ppC__WithPic">
                                    {
                                        currentUserDetails.profilePhoto ? <img src={currentUserDetails.profilePhoto} alt="user profile" /> :
                                        <AvatarComponent style={{ backgroundColor: '#d3d3d3' }} width={'110px'} height={'110px'} displayName={currentUserDetails.newAccountStructure ? `${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`: currentUserDetails.name} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ppC__Mid">
                            <span className="ppC__Big">{currentUserDetails.newAccountStructure ? `${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`: currentUserDetails.name}</span>
                            <div className="ppC__Small">
                                {currentUserDetails.username && currentUserDetails.username.length > 1 ? <p onClick={() => handleCopyUsername()}><span className="username__Span">{currentUserDetails.username} <FontAwesomeIcon icon={faCopy} /></span></p>  : <></>}
                                {currentUserDetails.about}
                                {/* {currentUserDetails.accountType === "coach" ? "Professional Agent" : "Talented Player"} */}
                                <br />
                                Born: {currentUserDetails.dateOfBirth}
                                <br />
                                {currentUserDetails.accountType !== 'coach' && currentUserDetails.city + ", "}{currentUserDetails.nationality}.
                                <br />
                            </div>
                        </div>
                        <ul className="ppC__ul">
                            <li className="ppC__li" style={{
                                color: pathname === `/${currentUserDetails.username}` && "#00A01E",
                                borderBottom: pathname === `/${currentUserDetails.username}` && "1.5px solid #00A01E"
                            }} onClick={()=>navigateTo(null)}>Posts</li>
                            {/* <li className="ppC__li" onClick={()=>navigateTo(null)}>About </li> */}
                            {
                                currentUserDetails.accountType === 'coach' ? 
                                <li className="ppC__li" style={{
                                    color: (pathname === "/user-profile/awards" || pathname === `/${currentUserDetails.username}/awards`) && "#00A01E",
                                    borderBottom: (pathname === "/user-profile/awards" || pathname === `/${currentUserDetails.username}/awards`) && "1.5px solid #00A01E"
                                }} onClick={() => navigate("awards", { state: { userAwards: currentUserDetails.awards } })}>{"Awards"} </li>
                                :
                                <>
                                    <li className="ppC__li" style={{
                                        color: (pathname === "/user-profile/skills" || pathname === `/${currentUserDetails.username}/skills`) && "#00A01E",
                                        borderBottom: (pathname === "/user-profile/skills" || pathname === `/${currentUserDetails.username}/skills`) && "1.5px solid #00A01E"
                                    }} onClick={()=>navigateTo("skills")}>{"Skills"} </li>
                                    <li className="ppC__li" style={{
                                        color: (pathname === "/user-profile/awards" || pathname === `/${currentUserDetails.username}/awards`) && "#00A01E",
                                        borderBottom: (pathname === "/user-profile/awards" || pathname === `/${currentUserDetails.username}/awards`) && "1.5px solid #00A01E"
                                    }} onClick={() => navigate("awards", { state: { userAwards: currentUserDetails.awards } })}>{"Awards"} </li>        
                                </>    
                            }
                            {/* {profileRoute.map((val, idx) => (
                                <li className="ppC__li" key={`ppC-${idx}`}>{val}</li>
                            ))} */}
                        </ul>
                    </div>
                    <Routes>
                        <Route path="/" element={<PostsRoute />} />
                        {
                            currentUserDetails.accountType === 'coach' ?
                            <Route path="/awards" element={<AwardsPage />} />
                            :
                            <>
                                <Route path="/awards" element={<AwardsPage />} />
                                <Route path="/skills" element={<SkillsRoute />} />
                            </>
                        }
                    </Routes>
                </div>
            </div>
        </PageLayout>
    </>
}

export default VerifyUserProfilePage;
