import React, { useState, useEffect, useContext } from "react";
import "./Messages.css";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../../components/spinners/LoadingSpinner/LoadingSpinner";
import image from "../../imagess/playerPic.png";
import PageLayout from "../../Layout/PageLayout";
import { UserContext } from "../../../../context/UserContext";

const Messages = () => {

    const messagesLists = new Array(20).fill("John Doe");
    const { messages, messagesLoading } = useContext(UserContext)

    return (
        <PageLayout>
            <div className="Messages">
                <div className="Messages__Content">
                    <div className="MC__Top">
                        <div className="MC__Big">Messages</div>
                    </div>
                    <div className="MC__Main">
                        {messagesLoading && <div className="Messages__Loader">
                            <LoadingSpinner width={"100px"} height={"100px"} />
                        </div>}
                        {!messagesLoading && <div className="MC__Main__Content">
                            {messages.length < 1 && 
                                <span className="MC__Big">No Messages</span>
                            }
                            {messages.length > 0 && 
                                <ul>
                                    {messages.map((val, idx) => (
                                        <li className="MC__Li" key={`messageLists-${idx}`}>
                                            <Link to={`/message/chat/${val._id}`} className="MC__Li__Link"
                                            style={{textDecoration: "none"}}>
                                                <img src={val.itemDisplayPicture ? val.itemDisplayPicture : image} alt="user" />
                                                <div className="MC__Li__Txt">
                                                    <span className="MC__Med">{val.itemDisplayName}</span>
                                                    <span className="MC__Small">Start Messaging</span>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default Messages;