import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import "./Message.css";
import PageLayout from "../../Layout/PageLayout";
import SVG from "../../imagess/svg";
import LoadingSpinner from "../../../../components/spinners/LoadingSpinner/LoadingSpinner";
import image from "../../imagess/playerPic.png";
import EmojiPicker from "emoji-picker-react";
import Images from "../../ImageRenders/Images";
import ImagePage from "../../ImagePage/ImagePage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import waveAnimation from "../../../../assets/wave-animation.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { getUserMessages, sendNewMessage, updateNewChatRequest } from "../../../../services/userService";
import { toast } from "react-toastify";

const Message = () => {

    const [messageItems, setMessageItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imagesData, setImagesData] = useState(null);
    const [showImages, setShowImages] = useState(false);
    const textRef = useRef(null);
    const lastRef = useCallback(node => {
        if(node) node.scrollIntoView({ smooth: true })
    });
    const [messageText, setMessageText] = useState("");
    const [cursorPosition, setCursorPosition] = useState(0);
    const [showEmoji, setShowEmoji] = useState(false);
    const [imgs, setImgs] = useState([]);
    const { messages, messagesLoading, setMessages, userTokenDetails, user } = useContext(UserContext);
    const [ currentMessageItem, setCurrentMessageItem ] = useState(null);
    const { messageItemId } = useParams();
    const navigate = useNavigate();
    const [ refreshLoading, setRefreshLoading ] = useState(false);
    const [ messagesToRetrySending, setMessagesToRetrySending ] = useState([]);

    const handleSendNewMessage = async (messageObj) => {

        if (!userTokenDetails || !currentMessageItem) return 

        const updatedMessageItem = {...currentMessageItem};
        const messageAlreadyAdded = updatedMessageItem.messages.find(message => message._id === messageObj._id);
        
        // message is trying to be resent
        if (!messageAlreadyAdded) {
            updatedMessageItem.messages.push(messageObj);
            setCurrentMessageItem(updatedMessageItem);
        }

        try {
            const response = await (await sendNewMessage(userTokenDetails, currentMessageItem?.otherUser, { text: messageObj.textContent })).data;
            console.log(response)
            
            const currentMessagesToRetrySending = messagesToRetrySending.slice();
            setMessagesToRetrySending(currentMessagesToRetrySending.filter(item => item._id !== messageObj._id))
        } catch (error) {
            console.log(error.response ? error.response.data : error.message)
            toast.info("Failed to send new message")
            
            // console.log(updatedMessageItem.messages)
            const foundMessageIndex = updatedMessageItem.messages.findIndex(message => message._id === messageObj._id);
            
            if (foundMessageIndex === -1) return
            
            updatedMessageItem.messages[foundMessageIndex].failed = true;
            updatedMessageItem.messages[foundMessageIndex].createdAt = new Date();

            setCurrentMessageItem(updatedMessageItem);

            const currentMessagesToRetrySending = messagesToRetrySending.slice();
            setMessagesToRetrySending(currentMessagesToRetrySending.filter(item => item._id !== messageObj._id))
        }
    }

    const handleSend = async () => {
        console.log(imgs)
        if(messageText === "") return;
        setMessageItems([
            ...messageItems,
            {
                img: imgs.length > 0 ? imgs : null,
                message: messageText,
                fromMe: true,
                date: new Date()
            }
        ]);
        setImgs([]);
        setMessageText("");
        const newMessageContent = {
            _id: crypto.randomUUID(),
            typeOfMessage: "sent",
            textContent: messageText,
            refToMessageItem: currentMessageItem?._id,
            createdAt: new Date(),
        }

        handleSendNewMessage(newMessageContent);
    };

    const handleRetrySend = (messageItem) => {
        const currentMessagesToRetrySending = messagesToRetrySending.slice();

        if (currentMessagesToRetrySending.find(item => item._id === messageItem._id)) return

        currentMessagesToRetrySending.push(messageItem);
        setMessagesToRetrySending(currentMessagesToRetrySending);

        handleSendNewMessage(messageItem);
    }

    const handelDelete = () => {
        setMessageItems([]);
    }

    const handleEmoji = () => {
        if(textRef.current) textRef.current.focus();
        setShowEmoji(!showEmoji)
    }

    const emojiClicked = (emojiObject) => {
        textRef.current.focus();
        const start = messageText.substring(0, textRef.current.selectionStart);
        const end = messageText.substring(textRef.current.selectionStart);
        const newTxt = start + emojiObject.emoji + end;
        setMessageText(newTxt);
        setCursorPosition(start.length + emojiObject.emoji.length);
        setShowEmoji(false);
    }

    const filterImgs = (idx) => {
        const newImgs = imgs.filter((val, index) => index !== idx);
        setImgs(newImgs);
    }
    
    useEffect(() => {
        
        if (messages.length < 1) return
        
        const foundMessageItem = messages.find(item => item._id === messageItemId);
        if (!foundMessageItem) return navigate("/message");

        setCurrentMessageItem(foundMessageItem)

    }, [messages, messageItemId]);

    const formatDate = (val) => {
        const date = String(val).split(" ");
        return `${date[2]}/${val.getMonth()}/${val.getFullYear()}  ${date[4]}`;
    }

    useEffect(() => {
        if(textRef.current) textRef.current.selectionEnd = cursorPosition;
    }, [cursorPosition]);

    const handleWaveOptionBtnClick = async (option, playerId) => {
        
        if (option === "start") {
            setCurrentMessageItem((prevVal) => { return {...prevVal, messageExchangeAllowed: true }})

            try {
                await updateNewChatRequest(userTokenDetails.token, option, playerId)                
            } catch (error) {            
                console.log(error.response ? error.response.data : error.message)
                setCurrentMessageItem((prevVal) => { return {...prevVal, messageExchangeAllowed: false }})
                toast.info("Failed to update. Please try again later.")
            }
        }
        
        if (option === "ignore") {
            setCurrentMessageItem((prevVal) => { return {...prevVal, messageExchangeRequestDenied: true }})

            try {
                await updateNewChatRequest(userTokenDetails.token, option, playerId)                
            } catch (error) {
                console.log(error.response ? error.response.data : error.message)
                setCurrentMessageItem((prevVal) => { return {...prevVal, messageExchangeRequestDenied: false }})
                toast.info("Failed to update. Please try again later.")
            }
        }
    }

    const loadMessages = async () => {

        if (!userTokenDetails) return

        setRefreshLoading(true);

        try {
            const messagesData = await (await getUserMessages(userTokenDetails.token, userTokenDetails.accountType)).data;
            const currentMessageItemIndex = messagesData.findIndex(message => message.owner === user._id && message.otherUser === currentMessageItem?.otherUser)
            
            if (currentMessageItemIndex !== -1) {
                messagesData[currentMessageItemIndex]._id = messageItemId;
                setMessages(messagesData)
            }
            // setMessages(messagesData);
            setRefreshLoading(false);
        } catch (error) {
            console.log(error)
            setRefreshLoading(false);
        }

    }

    return (
        <PageLayout>
            <div className="Message">
                <div className="Message__Content">
                    <div className="mC__Top">
                        <div className="mCT__Div">
                            <div className="mCT__Left">
                                <div style={{ marginRight: "0.8rem" }} onClick={() => navigate("/message")}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </div>
                                {/* <svg back icon></svg> */}
                                <img src={currentMessageItem?.itemDisplayPicture ? currentMessageItem.itemDisplayPicture : image} alt={"user"} />
                                <div className="mCT__Left__Txt">
                                    {
                                        currentMessageItem?.itemUsername ?
                                        <Link className="mC__Big thick" to={`/${currentMessageItem.itemUsername}`}>{currentMessageItem?.itemDisplayName}</Link> :
                                        <span className="mC__Big thick">{currentMessageItem?.itemDisplayName}</span>
                                    }
                                    <span className="mC__Small"
                                    style={{marginTop: "5px"}}>{currentMessageItem?.itemUserType === "coach" ? "Scout" : currentMessageItem?.itemUserType === "player" ? "Athlete" : ""}</span>
                                </div>
                            </div>
                            <div className="mCT__Right" onClick={() => loadMessages()}>
                                {
                                    refreshLoading ? <LoadingSpinner width={"2rem"} height={"2rem"} /> :
                                    <FontAwesomeIcon icon={faRefresh} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mC__Main">
                        {messagesLoading && <div className="Message__Loader_div">
                            <LoadingSpinner width={"100px"} height={"100px"} />
                        </div>}
                        {!messagesLoading && <ul>
                            {currentMessageItem?.messages.map((message, idx) => (
                                <li className="mC__Li" key={`messages-${idx}`}
                                ref={idx===currentMessageItem?.messages.length-1 ? lastRef : null}
                                style={{
                                    alignSelf: message.typeOfMessage === "wave" ?
                                        message.waveType === "sent" ? "flex-end" : "flex-start" :
                                        message.typeOfMessage === "sent" ?"flex-end":"flex-start"
                                }}>
                                    {!message.imageContent && !message.waveType && <div className="message__Txt__Div" style={{ 
                                        marginLeft: `${message.typeOfMessage === "sent" ? "auto" : ""}`
                                    }}>
                                        <div className="message__Txt"
                                        style={{
                                            borderRadius: `10px 10px ${message.typeOfMessage === "sent" ?"10px":"0px"} ${message.typeOfMessage === "sent" ?"0px":"10px"}`, 
                                            marginLeft: `${message.typeOfMessage === "sent" ? "auto" : ""}`
                                        }}>
                                            {message.textContent}
                                        </div>
                                        <div className="message__Details">
                                            <span>{formatDate(new Date(message.createdAt))}</span>
                                            {
                                                message.failed ? <div style={{ cursor: "pointer" }} onClick={() => handleRetrySend(message)}>
                                                    {
                                                        messagesToRetrySending.find(item => item._id === message._id) ? <LoadingSpinner width={"14px"} height={"14px"} /> : <>
                                                        <FontAwesomeIcon icon={faRefresh} />
                                                        <span>Retry</span>
                                                        </>
                                                    }
                                                </div> : <div>{SVG.check}</div>
                                            }
                                        </div>
                                    </div>}
                                    {message.imageContent && <div className="message__Div">
                                        <div className="message__Content"
                                        style={{borderRadius: `10px 10px ${message.typeOfMessage === "sent" ?"10px":"0px"} ${message.typeOfMessage === "sent" ?"0px":"10px"}`}}>
                                            <div className="mCC__Img">
                                                <Images images={message.imageContent} setImagesData={setImagesData} />
                                            </div>
                                            <div className="mCC__Txt">{message.textContent}</div>
                                        </div>
                                        <div className="message__Details">
                                            <span>{formatDate(new Date(message.createdAt))}</span>
                                            <div>{SVG.check}</div>
                                        </div>
                                    </div>}
                                    {
                                        message.typeOfMessage === "wave" ? <div className="message__Div wave__Div" style={{ paddingBottom: currentMessageItem?.messageExchangeAllowed === false ? "12%": ""}}>
                                            <div 
                                                className="message__Content"
                                                style={{borderRadius: `10px 10px ${message.waveType === "sent" ?"10px":"0px"} ${message.waveType === "sent" ?"0px":"10px"}`}}
                                            >
                                                <div className="mCC__Img__Wave">
                                                    <img src={waveAnimation} alt="wave animation" />
                                                </div>
                                                <div className="mCC__Txt">{message.textContent}</div>
                                            </div>
                                            <div className="message__Details">
                                                <span>{formatDate(new Date(message.createdAt))}</span>
                                                <div>{SVG.check}</div>
                                            </div>
                                            {
                                                currentMessageItem?.messageExchangeAllowed === false ? <>
                                                    { 
                                                        currentMessageItem?.itemUserType === "coach" ? <div className="wave__Options__Container user">
                                                            <p>
                                                                You would be able to send more messages as soon as {currentMessageItem?.itemDisplayName} grants permission
                                                            </p>
                                                        </div> :
                                                        <div className="wave__Options__Container coach">
                                                            <p>{currentMessageItem?.itemDisplayName} sent you a wave!<br/><br /> {currentMessageItem?.messageExchangeRequestDenied ? "" : "Would you like to start a new chat?"}</p>
                                                            {
                                                                !currentMessageItem?.messageExchangeRequestDenied ? <div>
                                                                    <button className="accept" onClick={() => handleWaveOptionBtnClick("start", currentMessageItem?.otherUser)}>Start chatting</button>
                                                                    <button className="reject" onClick={() => handleWaveOptionBtnClick("ignore", currentMessageItem?.otherUser)}>Ignore</button>
                                                                </div> : <></>
                                                            }
                                                        </div>
                                                    }
                                                </> : <></>
                                            }
                                        </div> : <></>
                                    }
                                </li>
                            ))}
                        </ul>}
                    </div>
                    <div className="mC__Bottom">
                        <div className="mC__Btm">
                            <div className="mC__Bottom__Container">
                                <div className="mC__Bottom__Div">
                                    <div className="mC__Bottom__Input">
                                        <div onClick={currentMessageItem?.messageExchangeAllowed === false ? () => {} : () => handleEmoji()}>{SVG.emoji}</div>
                                        <textarea placeholder="Type Message" ref={textRef}
                                        value={messageText}
                                        onChange={(e) => setMessageText(e.target.value)} disabled={currentMessageItem?.messageExchangeAllowed === false ? true : false} />
                                        {/* <div>
                                            <label htmlFor="message-input">{SVG.imgIcon}</label>
                                            <input type="file" id="message-input" 
                                            onChange={(e) => setImgs([...imgs, e.target.files[0]])} disabled={currentMessageItem?.messageExchangeAllowed === false ? true : false} />
                                        </div> */}
                                    </div>
                                    <div className="mC__Send" onClick={handleSend}>
                                        {SVG.send}
                                    </div>
                                </div>
                                {imgs.length > 0 && <div className="mC__Imgs">
                                    <div>
                                        {imgs.map((val, idx) => (
                                            <div className="mC__Img" key={`mC__Img-${idx}`}>
                                                <img src={URL.createObjectURL(val)} />
                                                <div className="mC__Img__Cancel"
                                                onClick={() => filterImgs(idx)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" 
                                                    fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" 
                                                    strokeLinejoin="round" className="x" style={{color: "black"}}>
                                                        <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                                {showEmoji && <div className="emoji__Message">
                                    <EmojiPicker onEmojiClick={emojiClicked} />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`absolute__Message__Img ${imagesData!==null}`}>
                    <ImagePage imagesData={imagesData} hide={() => setImagesData(null)} />
                </div>
            </div>
        </PageLayout>
    )
}

export default Message;