import jwtDecode from "jwt-decode";
import http from "./httpService";

export function headers(token, data) {
    if (!data) return { headers: { "auth-token": token } }
    else return {
        headers: {
            "auth-token": token,
            'Content-Type': "multipart/form-data",
        }
    }
}

export function decodeUserToken(token) {
    try {
        if (token) return { ...jwtDecode(token), token }
        else return null;
    }
    catch (ex) {
        console.log(ex)
    }
}


export const getUserProfile = async (token, accountType) => {

    const returnUrl = () => {
        switch (accountType) {
            case "new":
                return "profile";
            case "player":
                return "players/profile";
            case 'coach':
                return "coachs/profile";

        }
    }

    return await http.get(`/users/${returnUrl()}`, headers(token))
}

export const registerUser = async (data) => await http.post("/users/signup", data);

export const loginUser = async (data) => await http.post("/users/login", data)

export const registerUserProfile = async (data, token) => await http.put("/users/profile", data, headers(token));

export const verifyUser = async (token) => await http.get(`/users/verify-account/${token}`);


export const postUpdate = async (data, tokenDetails) => await http.post(`/users/posts/${tokenDetails.accountType}`, data, headers(tokenDetails.token, true))


export const getAllPosts = async (tokenDetails) => await http.get(`/users/posts/${tokenDetails.accountType}`, headers(tokenDetails.token))

export const getAllUsers = async (tokenDetails) => await http.get(`/users/all-users/${tokenDetails.accountType}`, headers(tokenDetails.token))

export const interviewPlayer = async (data, token) => await http.post(`/users/coachs/interview`, data, headers(token))

export const waveCoach = async (coach, token) => await http.post(`/users/players/wave`, {coach}, headers(token))

export const getPlayerInterviews = async (token) => await http.get(`/users/players/interviews`, headers(token))

export const makeDecisionOnCoachInterview = async (descision,token,interviewId) => await http.put(`/users/players/interviews`,{descision,interviewId},headers(token))

export const getCoachWaves = async(token) => await http.get(`/users/coachs/waves`,headers(token))

export const likePost = async(tokenDetails,data) => await http.post(`/users/like-post/${tokenDetails.accountType}`,data,headers(tokenDetails.token));

export const commentPost = async(tokenDetails,data) => await http.post(`/users/comment-post/${tokenDetails.accountType}`,data,headers(tokenDetails.token));

export const getPostComments = async (tokenDetails,postId)=> await http.get(`/users/comments/${postId}/${tokenDetails.accountType}`,headers(tokenDetails.token))

export const updateUserDetails = async (tokenDetails, data) => await http.patch(`/users/update-details/${tokenDetails.accountType}`, data, headers(tokenDetails.token));

export const getUserDetailsFromUsername = async (username) => await http.get(`/users/external-view/${username}`);

export const updateUsername = async (tokenDetails, data) => await http.put(`/users/update-username/${tokenDetails.accountType}`, data, headers(tokenDetails.token));

export const getUserPostsForExternalUser = async (username) => await http.get(`/users/external-view/posts/${username}`);

export const getUserMessages = async (token, accountType) => await http.get(`/users/messages/${accountType}`, headers(token));

export const sendNewMessage = async (tokenDetails, receivingUserId, data) => await http.post(`/users/messages/${tokenDetails.accountType}/message/${receivingUserId}`, data, headers(tokenDetails.token));

export const updateNewChatRequest = async (token, response, playerId) => await http.patch(`/users/coachs/messages/message/${response}/${playerId}`, {}, headers(token));

export const updateUserProfilePhoto = async (tokenDetails, data) => await http.put(`/users/update-user-photo/${tokenDetails.accountType}`, data, headers(tokenDetails.token));

export const getSinglePostForExternalUser = async (postId) => await http.get(`/users/external-view/posts/post/${postId}`);

export const requestPasswordReset = async (email) => await http.get(`/users/reset-password/${email}`);

export const resetUserPassword = async (token, data) => await http.put(`/users/reset-password/${token}`, data);