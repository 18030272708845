import styles from './styles.module.css';
import { TbEdit } from 'react-icons/tb';
import profilePic from "../../../../assets/playerPic.png";
import { useRef, useState } from 'react';
import { convertFileObjectToImageStr } from '../../../../utils/ImageHelper';
import AvatarComponent from '../../../../components/AvatarComponent/AvatarComponent';

const ImageComponent = ({ label, handleItemUpdate, stateName, userDisplayName }) => {
    const profileInputRef = useRef();
    const [ newImageAdded, setNewImageAdded ] = useState(null);
    const [ mouseOverImage, setMouseOverImage ] = useState(false);

    const handleUpdateImage = () => {
        profileInputRef.current.click();
    }

    const handleLoadImage = (e) => {
        handleItemUpdate(`${stateName}`, e.target.files[0]);

        convertFileObjectToImageStr(e.target.files[0]).then(res => {
            setNewImageAdded(res);
        }).catch(err => {
            console.log(err);
        })
    }

    return <div className={styles.edit__Image__Container}>
        <p>{label}</p>
        <div className={styles.edit__Image__Wrapper}>
            <div onClick={handleUpdateImage} onMouseEnter={() => setMouseOverImage(true)} onMouseLeave={() => setMouseOverImage(false)}>
                {
                    newImageAdded ? 
                    <img src={newImageAdded ? newImageAdded : profilePic} alt="user profile"  /> :
                    <AvatarComponent style={mouseOverImage ? { backgroundColor: 'rgba(57,76,96,.35)' } : {}} displayName={userDisplayName} width={"80px"} height={"80px"} />
                }
            </div>
            { 
                mouseOverImage ? 

                <TbEdit className={styles.edit__Icon} color={"#00A01E"} /> : 
                <></> 
            }
            <input style={{ display: "none" }} type={"file"} ref={profileInputRef} accept={"image/*"} onChange={(e) => handleLoadImage(e)} name={stateName}/>
        </div>
    </div>
}

export default ImageComponent;