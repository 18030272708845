import React from 'react'
import LogInComponent from "../../components/auth/login/login.component";

const LogInPage = () => {
  return (
    <div>
      <LogInComponent />
    </div>
  )
}

export default LogInPage
