import React, { useContext, useEffect, useRef, useState } from "react";
import SVGs from "../imagess/svg";
import PageLayout from "../Layout/PageLayout";
import "./ProfilePage.css";
import ball from "../imagess/coverPic.png";
import profilePic from "../imagess/playerPic.png";
import { UserContext } from "../../../context/UserContext";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PostComponent from "../Posts/PostComponent/PostComponent";
import UserSkills from "./Skills/UserSkills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCopy, faEdit } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { convertFileObjectToImageStr } from "../../../utils/ImageHelper";
import { toast } from "react-toastify";
import { updateUserDetails, updateUserProfilePhoto } from "../../../services/userService";
import AwardsPage from "./AwardsPage";
import { citiesInNigeria, countriesInTheWorld, countriesInTheWorldWIthNationalities } from "../../NewAccountSetup/utils";
import { AiOutlineLink } from "react-icons/ai";
import AddComponent from "../../NewAccountSetup/components/AddComponent/AddComponent";
import { validateURL } from "../../../utils/URLValidator";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";

const UserProfilePage = ({ userDetails }) => {

    const { search, state, pathname } = useLocation()
    const navigate = useNavigate()

    const profileRoute = ["Posts", "About", "Media", "Activities"]

    const { user, userTokenDetails, posts } = useContext(UserContext)
    const initialUserInfoObj = {
        _id: "",
        name: "",
        accountType: "",
        dateOfBirth: "",
        city: "",
        nationality: "",
        username: "",
        about: "",
        country: "",
        club: "",
        profilePhoto: "",
        social: [],
        awards: [],
        gender: "",
        lastName: "",
        firstName: "",
    }
    const [userInfo, setUserInfo] = useState(initialUserInfoObj)
    const [userPosts, setUserPosts] = useState([])
    const [editUserInfo, setEditUserInfo] = useState(initialUserInfoObj)
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ mouseOverImage, setMouseOverImage ] = useState(false);
    const [ disableSaveBtn, setDisableSaveBtn ] = useState(true);
    const [ newImageAdded, setNewImageAdded ] = useState(false);
    const [ updatingInfo, setUpdatingInfo ] = useState(false);
    const profileInputRef = useRef();
    const [ updatedAccountImage, setUpdatedAccountImage ] = useState(null);

    const userId = new URLSearchParams(search).get("userId");

   useEffect(()=>{
    if (userDetails) {
        setUserInfo(userDetails);
        setEditUserInfo(userDetails);
        return
    }
if((!userTokenDetails?.token || !user?.name) && !user?.newAccountStructure) return;
setUserInfo(user)
setEditUserInfo(user)
   },[user?.name, user?.newAccountStructure, userDetails])

    useEffect(() => {
        if (userDetails && userDetails._id && userDetails.posts) {
            setUserPosts(userDetails.posts);
            return
        }
    }, [userDetails])

    useEffect(() => {
        if (userInfo._id) {
            const user_posts = posts?.filter(p => p?.user?._id === userInfo._id);
            setUserPosts(user_posts);
        }
    }, [posts?.length, userInfo._id])

    useEffect(() => {

        if (_.isEqual(userInfo,editUserInfo)) return setDisableSaveBtn(true)

        setDisableSaveBtn(false);

    }, [editUserInfo])

    const PostsRoute = () => (
        <div className="ppC__Route">
            {userPosts.map(post => <PostComponent post={post} />)}
        </div>
    );
    const SkillsRoute = () => (
        <div className="ppC__Route">
            <UserSkills hideSkillsBtns={userDetails && !_.isEqual(userDetails, user) ? true : false} />
        </div>
    );

    const navigateTo = (link) => {
        if(!link) {
            if (userDetails) return navigate(`/${userDetails.username}`)
            return navigate("/user-profile");
        }
        if (userDetails) return navigate(`/${userDetails.username}/${link}`)
        navigate(`/user-profile/${link}`);
    }

    const handleUserInfoChange = (keyToUpdate, newVal) => {
        setEditUserInfo((prevDetails) => {
            return { ...prevDetails, [keyToUpdate]: newVal}
        })
    }

    const handleUpdateImage = () => {
        profileInputRef.current.click();
    }

    const handleLoadImage = (e) => {
        handleUserInfoChange("profilePhoto", e.target.files[0]);

        convertFileObjectToImageStr(e.target.files[0]).then(res => {
            setNewImageAdded(res);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleCancelBtnClick = () => {
        const currentUserInfo = { ...userInfo };
        setEditUserInfo(currentUserInfo);
        setShowEditModal(false);
    }

    const handleSaveBtnClick = async () => {
        if (!userTokenDetails || updatingInfo) return

        setUpdatingInfo(true);
        setDisableSaveBtn(true);

        const copyOfEditUserInfo = { ...editUserInfo }
        const keysToRetain = ["firstName", "lastName", "nationality", "dateOfBirth", "city", "about", "country", "club", "social", "awards", "gender"];

        Object.keys(copyOfEditUserInfo).forEach(key => {
            if (!keysToRetain.find(retainingKey => retainingKey === key)) delete copyOfEditUserInfo[`${key}`]
        })

        if (editUserInfo.profilePhoto !== userInfo.profilePhoto) {
            // make api call to update picture
            const newFormData = new FormData();
            newFormData.append("profilePhoto", editUserInfo.profilePhoto);

            const res = await (await updateUserProfilePhoto(userTokenDetails, newFormData)).data;
            console.log(res)

            setUpdatedAccountImage(res);
        }

        if (_.isEqual(userInfo,copyOfEditUserInfo)) return

        const invalidSocialLinks = copyOfEditUserInfo.social.map(socialLink => {
            if (!validateURL(socialLink)) return 'Invalid link'
            return null
        }).filter(link => link)
        
        if (invalidSocialLinks.length > 0) {
            toast.info("Please make sure all social links entered are valid URLs");
            return setUpdatingInfo(false);
        }

        try {
            const response = await (await updateUserDetails(userTokenDetails, copyOfEditUserInfo)).data;
            toast.success(response);
            setUserInfo(editUserInfo);
            setUpdatingInfo(false);
            setShowEditModal(false);
        } catch (err) {
            console.log(err.response ? err.response.data : err.message);
            setUpdatingInfo(false);
            setDisableSaveBtn(false);
            toast.error(err.response ? err.response.data : err.message);
        }
    }

    const handleCopyUsername = async () => {
        await navigator.clipboard.writeText(window.location.origin + "/" + userInfo.username)
        toast.success("Copied username")
    }

    return (
        <PageLayout updatedAccountImage={updatedAccountImage}>
            <div className="profilePage">
                <div className="profilePage__Content">
                    <div className="pP__Content__Top">
                        <div className="ppC__Top">
                            <img src={ball} />
                            <div className="ppC__Top__Absolute">
                                <div className="ppC__WithPic">
                                    {
                                        updatedAccountImage || userInfo.profilePhoto ?
                                        <img src={updatedAccountImage ? updatedAccountImage : userInfo.profilePhoto} alt={'user profile'} /> :
                                        <AvatarComponent style={{ backgroundColor: '#d3d3d3' }} width={'110px'} height={'110px'} displayName={userInfo.newAccountStructure ? `${userInfo?.firstName} ${userInfo?.lastName}`: userInfo.name} />
                                    }
                                    {userTokenDetails?.id !== userInfo._id && userInfo.accountType !== "player" ? 
                                    <div className="ppC__Interview"
                                     onClick={()=>navigate(`/schedule/book/${userId}`,{state:{user:userInfo}})}>
                                        {SVGs.interview}
                                        <span>Interview</span>
                                    </div>:null}
                                </div>
                                {
                                    userDetails ?
                                        _.isEqual(userDetails._id, user._id) ?
                                        <div className="ppC__EditProfile" onClick={() => setShowEditModal(true)}>Edit Profile</div> :
                                        <></> 
                                    :
                                    <div className="ppC__EditProfile" onClick={() => setShowEditModal(true)}>Edit Profile</div>
                                }
                            </div>
                        </div>
                        <div className="ppC__Mid">
                            <span className="ppC__Big">{userInfo.newAccountStructure ? `${userInfo?.firstName} ${userInfo?.lastName}`: userInfo.name}</span>
                            <div className="ppC__Small">
                                {userInfo.username && userInfo.username.length > 1 ? <p className="username__Item" onClick={() => handleCopyUsername()}><span className="username__Span">{userInfo.username} <FontAwesomeIcon icon={faCopy} /></span></p>  : <></>}
                                {userInfo.about}
                                {/* {userInfo.accountType === "coach" ? "Professional Agent" : "Talented Player"} */}
                                <br />
                                Born: {userInfo.dateOfBirth}
                                <br />
                                {
                                    userInfo?.gender && <>
                                    Gender: {userInfo.gender}
                                    <br />
                                    </>
                                }
                                {editUserInfo.accountType !== 'coach' && userInfo.city + ", "}{userInfo.nationality}
                                <br />
                                {
                                    userInfo?.social?.length > 0 ?
                                    <div className="user__Links__Social">
                                        <>
                                            {
                                                React.Children.toArray(userInfo?.social?.map(socialLink => {
                                                    return <>
                                                        <a href={socialLink} target={'_blank'} rel={'noreferrer noopener'}>
                                                            <AiOutlineLink />
                                                            <span>
                                                                {
                                                                    validateURL(socialLink) ? 
                                                                    new URL(socialLink).host.split(".").slice(0, -1).join('.') :
                                                                    ''
                                                                }
                                                            </span>
                                                        </a>
                                                    </>
                                                }))
                                            }
                                        </>
                                    </div> : <></>
                                }
                            </div>
                        </div>
                        <ul className="ppC__ul">
                            <li className="ppC__li" style={{
                                color: (pathname === "/user-profile" || pathname === `/${userDetails?.username}`) && "#00A01E",
                                borderBottom: (pathname === "/user-profile" || pathname === `/${userDetails?.username}`) && "1.5px solid #00A01E"
                            }} onClick={()=>navigateTo(null)}>Posts</li>
                            {/* <li className="ppC__li" onClick={()=>navigateTo(null)}>About </li> */}
                            {
                                userDetails?.accountType === 'coach' ? 
                                <li className="ppC__li" style={{
                                    color: (pathname === "/user-profile/awards" || pathname === `/${userDetails?.username}/awards`) && "#00A01E",
                                    borderBottom: (pathname === "/user-profile/awards" || pathname === `/${userDetails?.username}/awards`) && "1.5px solid #00A01E"
                                }} onClick={() => navigate("awards", { state: { userAwards: userDetails?.awards } })}>{"Awards"} </li>
                                :
                                <>
                                    <li className="ppC__li" style={{
                                        color: (pathname === "/user-profile/skills" || pathname === `/${userDetails?.username}/skills`) && "#00A01E",
                                        borderBottom: (pathname === "/user-profile/skills" || pathname === `/${userDetails?.username}/skills`) && "1.5px solid #00A01E"
                                    }} onClick={()=>navigateTo("skills")}>{"Skills"} </li>
                                    <li className="ppC__li" style={{
                                        color: (pathname === "/user-profile/awards" || pathname === `/${userDetails?.username}/awards`) && "#00A01E",
                                        borderBottom: (pathname === "/user-profile/awards" || pathname === `/${userDetails?.username}/awards`) && "1.5px solid #00A01E"
                                    }} onClick={() => navigate("awards", { state: { userAwards: userDetails?.awards } })}>{"Awards"} </li>        
                                </>    
                            }
                            {/* {profileRoute.map((val, idx) => (
                                <li className="ppC__li" key={`ppC-${idx}`}>{val}</li>
                            ))} */}
                        </ul>
                    </div>
                    <Routes>
                        <Route path="/" element={<PostsRoute />} />
                        {
                            userInfo?.accountType === 'coach' ?
                            <Route path="/awards" element={<AwardsPage />} /> 
                            :
                            <>
                                <Route path="/awards" element={<AwardsPage />} />
                                <Route path="/skills" element={<SkillsRoute />} />
                            </>
                        }
                    </Routes>
                </div>
            </div>
            { 
                showEditModal ?
                <div className="edit__Modal__Overlay">
                    <div className="edit__Modal__Container">
                        <div className="edit__Top__Row">
                            <h3>Profile information</h3>
                            <div className="close__Icon__Container" onClick={() => handleCancelBtnClick()}>
                                <FontAwesomeIcon icon={faClose} color={"black"} cursor={"pointer"} />
                            </div>
                        </div>
                        <div className="edit__Middle__Row">
                            <div className="edit__Image__Container">
                                <p className="title__Text">Photo</p>
                                <div className="edit__Image__Wrapper" onClick={handleUpdateImage} onMouseEnter={() => setMouseOverImage(true)} onMouseLeave={() => setMouseOverImage(false)}>
                                    {
                                        newImageAdded || editUserInfo.profilePhoto ?
                                        <img src={newImageAdded ? newImageAdded : editUserInfo.profilePhoto ? editUserInfo.profilePhoto : profilePic} alt="user profile" /> :
                                        <AvatarComponent style={mouseOverImage ? { backgroundColor: 'rgba(57,76,96,.35)' } : {}} displayName={editUserInfo.newAccountStructure ? `${editUserInfo?.firstName} ${editUserInfo?.lastName}`: editUserInfo.name} width={'80px'} height={'80px'} />
                                    }
                                    { 
                                        mouseOverImage ? 
                                        <FontAwesomeIcon icon={faEdit} className={"edit__Icon"} color={"#00A01E"} /> : 
                                        <></> 
                                    }
                                    <input style={{ display: "none" }} type={"file"} ref={profileInputRef} accept={"image/*"} onChange={updatingInfo ? () => {} : (e) => handleLoadImage(e)} />
                                </div>
                            </div>
                            <div className="edit__Name__Container">
                                <p className="title__Text">First Name*</p>
                                <input type={"text"} value={!editUserInfo.newAccountStructure ? editUserInfo.name : editUserInfo.firstName} onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("firstName", e.target.value)} />
                            </div>
                            <div className="edit__Name__Container">
                                <p className="title__Text">Last Name*</p>
                                <input type={"text"} value={editUserInfo?.lastName} onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("lastName", e.target.value)} />
                            </div>
                            <div className="edit__About__Container">
                                <p className="title__Text">About*</p>
                                <input type={"text"} value={editUserInfo.about} onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("about", e.target.value)} />
                            </div>
                            <div className="edit__Gender__Container">
                                <p className="title__Text">Gender*</p>
                                <select onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("gender", e.target.value)}>
                                    <option value={"default_"} disabled>Select gender</option>
                                    {
                                        React.Children.toArray(['Male', 'Female', 'Prefer not to say'].sort((a, b) => a.localeCompare(b)).map(gender => {
                                            return <option selected={editUserInfo.gender === gender ? true : false} value={gender}>{gender}</option>
                                        }))
                                    }
                                </select>
                            </div>
                            <div className="edit__Club__Container">
                                <p className="title__Text">Club</p>
                                <input type={"text"} value={editUserInfo.club} onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("club", e.target.value)} />
                            </div>
                            <div className="edit__DOB__Container">
                                <p className="title__Text">Date of birth*</p>
                                <input type={"date"} value={editUserInfo.dateOfBirth} onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("dateOfBirth", e.target.value)} />
                            </div>
                            {
                                editUserInfo.accountType !== 'coach' && <div className="edit__City__Container">
                                    <p className="title__Text">City*</p>
                                    <select onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("city", e.target.value)}>
                                        <option value={"default_"} disabled>Select city</option>
                                        {
                                            React.Children.toArray(citiesInNigeria.sort((a, b) => a.localeCompare(b)).map(city => {
                                                return <option selected={editUserInfo.city === city ? true : false} value={city}>{city}</option>
                                            }))
                                        }
                                    </select>
                                </div>
                            }
                            <div className="edit__Country__Container">
                                <p className="title__Text">Country*</p>
                                <select onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("country", e.target.value)}>
                                    <option value={"default_"} disabled>Select country</option>
                                    {
                                        React.Children.toArray(countriesInTheWorld.sort((a, b) => a.localeCompare(b)).map(country => {
                                            return <option selected={editUserInfo.country === country ? true : false} value={country}>{country}</option>
                                        }))
                                    }
                                </select>
                            </div>
                            <div className="edit__Nationality__Container">
                                <p className="title__Text">Nationality*</p>
                                <select onChange={updatingInfo ? () => {} : (e) => handleUserInfoChange("nationality", e.target.value)}>
                                    <option value={"default_"} disabled>Select nationality</option>
                                    {
                                        editUserInfo.accountType === 'coach' ? 
                                        
                                        React.Children.toArray(countriesInTheWorldWIthNationalities.map(country => country.nationality).sort((a, b) => a.localeCompare(b))).map(nationality => {
                                            return <option selected={editUserInfo.nationality === nationality ? true : false} value={nationality}>{nationality}</option>
                                        }) : 
                                        
                                        React.Children.toArray(['Nigerian'].map(nationality => {
                                            return <option selected={editUserInfo.nationality === nationality ? true : false} value={nationality}>{nationality}</option>
                                        }))
                                    }
                                </select>
                            </div>
                            <div className="edit__Socials__Container">
                                <AddComponent
                                    label={'Social'}
                                    stateName={'social'}
                                    handleUpdateItem={updatingInfo ? () => {}  : (stateToChange, value) => handleUserInfoChange("social", value)}
                                    placeholder={'e.g. https://ng.linkedin.com/in/your-username'}
                                    value={editUserInfo.social}
                                    editPage={true}
                                />
                            </div>
                            <div className="edit__Awards__Container">
                                <AddComponent
                                    label={'Awards'}
                                    stateName={'awards'}
                                    handleUpdateItem={updatingInfo ? () => {}  : (stateToChange, value) => handleUserInfoChange("awards", value)}
                                    placeholder={'e.g. Overall best in Sports 2020'}
                                    value={editUserInfo.awards}
                                    editPage={true}
                                />
                            </div>
                        </div>
                        <div className="edit__Bottom__Row">
                            <button className="cancel__Btn" onClick={handleCancelBtnClick}>Cancel</button>
                            <button className="save__Btn" onClick={handleSaveBtnClick} disabled={disableSaveBtn}>{updatingInfo ? "Please wait..." : "Save"}</button>
                        </div>
                    </div>
                </div> 
                :
                <></>
            }
        </PageLayout>
    )
}

export default UserProfilePage;