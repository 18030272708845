export const SettingActionTypes = {
    SET_CURRENT_SETTING: 'SET_CURRENT_SETTING',
    FETCH_SETTING_START: 'FETCH_SETTING_START',
    FETCH_SETTING_SUCCESS: 'FETCH_SETTING_SUCCESS',
    FETCH_SETTING_FAILURE: 'FETCH_SETTING_FAILURE',
    UPDATE_SETTING_START: 'UPDATE_SETTING_START',
    UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
    UPDATE_SETTING_FAILURE: 'UPDATE_SETTING_FAILURE',
    SET_SETTING_UPLOAD_PROGRESS: 'SET_SETTING_UPLOAD_PROGRESS',
    SET_ROUTE_MOUNT_STATUS: 'SET_ROUTE_MOUNT_STATUS',
}