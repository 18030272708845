import React from 'react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdOutlineAddLink } from 'react-icons/md';
import { toast } from 'react-toastify';
import styles from './style.module.css';

const ShareModal = ({ title, linkToShare, handleCloseModal }) => {

    const handleShareItem = async (type) => {
        switch (type) {
            case 'facebook':
                window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${linkToShare}`,
                    '_blank'
                )
                handleCloseModal();
                break;
            case 'twitter':
                window.open(
                    `https://twitter.com/intent/tweet?text=${linkToShare}`,
                    '_blank'
                )
                handleCloseModal();
                break;
            case 'whatsapp':
                window.open(
                    `https://api.whatsapp.com/send?&text=${linkToShare}`,
                    '_blank'
                )
                handleCloseModal();
                break;
            case 'link':
                await navigator.clipboard.writeText(linkToShare);
                toast.success('Link copied to clipboard!')
                handleCloseModal();
                break;
            default:
                console.log('Invalid action passed');
                handleCloseModal();
                break;
        }
        
    }

    return <>
        <div className={styles.share__Modal__Container}>
            <h4 className={styles.share__Modal__Title}>Share this {title}</h4>
            <ul className={styles.share__Modal__Items}>
                {
                    React.Children.toArray(shareOptions.map(option => {
                        return <li onClick={() => handleShareItem(option.type)}>
                            <>{option.icon}</>
                            <span>{option.title}</span>
                        </li>
                    }))
                }
            </ul>
        </div>
    </>
}

const shareOptions = [
    {
        title: 'Share to Facebook',
        icon: <FaFacebook />,
        type: 'facebook',
    },
    {
        title: 'Share to Twitter',
        icon: <FaTwitter />,
        type: 'twitter',
    },
    {
        title: 'Share on WhatsApp',
        icon: <IoLogoWhatsapp />,
        type: 'whatsapp',
    },
    {
        title: 'Copy link',
        icon: <MdOutlineAddLink />,
        type: 'link',
    },
]

export default ShareModal;