import { useContext, useEffect, useState } from "react";
import PageLayout from "../SportsPadi/Layout/PageLayout";
import styles from './styles.module.css';
import playerImg from '../../assets/player-choice.png';
import talentImg from '../../assets/scout-choice.png';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const AccountSelectionPage = () => {
    const [ name, setName ] = useState("");
    const navigate = useNavigate();
    const { user, userTokenDetails } = useContext(UserContext);

    useEffect(() => {
        
        if (!userTokenDetails || !user) return navigate("/login");
        if (userTokenDetails?.accountConfigured) return navigate("/posts")

        setName(user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user?.name)
    
    }, [user])

    const handleBtnClick = (selection) => {
        if (selection === 'talent') return navigate(`/setup-account/talent`, { state: { name: name} });
        if (selection === 'scout') return navigate(`/setup-account/scout`, { state: { name: name} });
    }

    return <>
        <PageLayout newAccount={true}>
            <div className={styles.new__Account__Setup__Container}>
                <h2>Dear {name}, complete your account setup...</h2>
                <div className={styles.new__Account__Setup__Choice}>
                    <div className={styles.new__Account__Image}>
                        <img src={playerImg} alt={"player choice illustration"} />
                    </div>
                    <div className={styles.info__Wrapper}>
                        <p>I am a sports talent,  I am actively seeking opportunities to play in international leagues.</p>
                        <button className={styles.green__Btn} onClick={() => handleBtnClick('talent')}>Create a Talent Profile</button>
                    </div>
                </div>
                <div className={styles.new__Account__Setup__Choice}>
                    <div className={styles.new__Account__Image}>
                        <img src={talentImg} alt={"scout choice illustration"} />
                    </div>
                    <div className={styles.info__Wrapper}>
                        <p>I am a sports talent scout, searching for verified local talents to play in international leagues.</p>
                        <button className={styles.black__Btn} onClick={() => handleBtnClick('scout')}>Create a Scout Profile</button>
                    </div>
                </div>
            </div>
        </PageLayout>    
    </>

}

export default AccountSelectionPage;
