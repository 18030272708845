import React, { useContext, useEffect, useState } from "react";
import "./PostComponent.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SVGs from "../../imagess/svg";
import image from "../../imagess/playerPic.png";
import { fromNow } from "../../../../utils/TimeFormatter";
import usePagination from "../../../../hooks/usePagination";
import PaginateButton from "../../../../components/PaginateButton";
import {UserContext} from '../../../../context/UserContext';
import { likePost } from "../../../../services/userService";
import { toast } from "react-toastify";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import ShareModal from "../../../../components/shareModal/ShareModal";

export const ReturnVidOrImg = ({ url, type }) => {
    // console.log(url, type);
    if(!type) return <img src={url} alt="" crossorigin="anonymous"/>
    if (type === "video") return <video src={url} controls muted crossorigin="anonymous" controlsList="nodownload"/>
    if(type==="image") return <img src={url} alt="" crossorigin="anonymous"/>
    // else return <image src={url} alt="" />

}

const PostComponent = ({ post, usernameToRouteTo, accountType, disableBtns }) => {
    let post_likes = post?.likes;
    const [like, setLike] = useState(false);
    const rowsPerPage = 1;
    const [page, setPage] = useState(1)

    const navigate = useNavigate()

    const {state} = useLocation()
    
//     useEffect(()=>{
// if(state?.user){
//     navigate("/posts",{state:{user:null}})
// }
//     },[])

    const [showEmoji, setShowEmoji] = useState(false);

    const { range, slice } = usePagination(post.images, page, rowsPerPage);

    const {userTokenDetails,setPosts,user, posts, likedPosts,setLikedPosts} = useContext(UserContext)

    const [loadingLike,setLoadingLike] = useState(false);
    const [updatedPostLikeCount, setUpdatedPostLikeCount] = useState(0);
    const [showShareModal, setShowShareModal] = useState(false);

    const handleButtonLike = () => {
        if(!userTokenDetails?.token || loadingLike) return;
        setLoadingLike(true)

        const copyOfLikedPosts = likedPosts.slice();
        let updatedLikedPosts = [];
        const postWasPreviouslyLiked = likedPosts.find(likedPost => likedPost.post === post._id)
        if (postWasPreviouslyLiked) {
            setUpdatedPostLikeCount(post.likes - 1);
            updatedLikedPosts = copyOfLikedPosts.filter(likedPost => likedPost.post !== post._id)
        }
        else {
            setUpdatedPostLikeCount(post.likes + 1);
            updatedLikedPosts = copyOfLikedPosts.slice();
            updatedLikedPosts.push({ user: user._id, post: post._id })
        }

        likePost(userTokenDetails,{postId:post?._id}).then(res=>{
            console.log(res)
            setLoadingLike(false)
            const copyOfPosts = posts.slice();
            const foundUpdatedPostIndex = posts.findIndex(singlePost => singlePost._id === post?._id);
            if (foundUpdatedPostIndex === -1) return
            copyOfPosts[foundUpdatedPostIndex].likes = res.data.updatedPostLikes
            setPosts(copyOfPosts)
            setLikedPosts(updatedLikedPosts)
            toast.success(res.data.msg)
        }).catch(err=>{
            console.log(err)
            setLoadingLike(false)
            setLikedPosts(updatedLikedPosts)
            toast.error(err.response.data)
        })
    }

    return (
        <div className="postComponent" key={post?._id}>
            <div className="postComponent_Content">
                <div className="pC__Top">
                    <div className="pC__Img">
                        {
                            post?.user?.profilePhoto ? 
                            <img src={post?.user?.profilePhoto ? post?.user?.profilePhoto : image} alt="user profile" />
                            :
                            <AvatarComponent height={'50px'} displayName={post?.user?.newAccountStructure ? `${post?.user?.firstName} ${post?.user?.lastName}`: post?.user?.name} />
                        }
                    </div>
                    <div className="pC__Txts">
                        <span className="pC__Name" 
                        style={{cursor:"pointer"}}
                         onClick={()=> usernameToRouteTo ? navigate(`/${usernameToRouteTo}`) : post?.user?.username ? navigate(`/${post?.user?.username}`) : navigate(userTokenDetails?.id===post?.user?._id ?
                        "/user-profile":
                        `/profile?userId=${post?.user?._id}`,
                        {state:{user:post?.user}})}>
                        {post?.user?.newAccountStructure ? `${post?.user?.firstName} ${post?.user?.lastName}`: post?.user?.name}</span>
                        <span className="pC__Role">{post?.user?.accountType === "coach" ? "Scout" : accountType && accountType === "coach" ? "Scout" : "Athlete"}</span>
                        <span className="pC__Time">{fromNow(post?.date)}</span>
                    </div>
                </div>
                <div className="pC__Main" to="#"
                    style={{ textDecoration: "none" }}>
                    <span className="pC__Message" onClick={disableBtns ? () => {} : ()=>navigate(`/post/${post?._id}`,{state:{post}})}>
                        {post?.content}
                    </span>
                    {slice.length > 0 && <div className="pC__Message__Img">
                        {slice.map((val, idx) => (
                           <ReturnVidOrImg url={val.url} type={val.type}/>
                        ))}

                    </div>}
                </div>
                {post?.images?.length > 1 && <PaginateButton
                    range={range}
                    slice={slice}
                    setPage={setPage}
                    page={page}
                />}
                <div className="pC__Actions">
                    <div className="actions__Icon">
                        <div onClick={disableBtns ? () => toast.info("You need to be logged in to like this") : () => handleButtonLike()}>
                            {!likedPosts.find(likedPost => likedPost.post === post._id) ? <AiOutlineLike color="rgb(160, 219, 170)" /> :  <AiFillLike color="#00A01E" />}
                            <span>{loadingLike ? updatedPostLikeCount : post.likes}</span>
                        </div>
                    </div>
                    <div className="actions__Icon" onClick={disableBtns ? () => toast.info("You need to be logged in to make or view comments") : ()=>navigate(`/post/${post?._id}`,{state:{post}})}>
                        <Link to="#" className="actions__Icon__Div">
                            {SVGs.comment}
                            <span>{post?.comments}</span>
                        </Link>
                    </div>
                    <div className="actions__Icon share" onClick={() => setShowShareModal(!showShareModal)}>
                        <div>
                            {SVGs.share}
                            <span>Share</span>
                        </div>
                        { 
                            showShareModal && 
                            <ShareModal 
                                title={'post'} 
                                linkToShare={`${window.location.origin}/post/${post._id}`} 
                                handleCloseModal={() => setShowShareModal(false)} 
                            /> 
                        }
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}



export default PostComponent;