import React, { useState, useRef, useEffect, useContext } from "react";
import Navbar from "../Navbar/Navbar";
// import PostMain from "../Posts/PostMain";
import SideBarComponent from "../SideComponent/SideComponent";
import SVGs from "../imagess/svg";
import image from "../imagess/playerPic.png";
import "./PageLayout.css";
import { getAllPosts, getAllUsers, updateUsername } from "../../../services/userService";
import { toast } from "react-toastify";
import { UserContext, LOGOUT_USER, USER_PROFILE } from "../../../context/UserContext";
import logo from "../imagess/logo-dark.png";
import useClickOutside from "../../../hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Nav } from "react-bootstrap";
import { IoIosLogOut, IoMdNotificationsOutline } from 'react-icons/io';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { CiLogout } from 'react-icons/ci';
import { FiUserCheck } from "react-icons/fi";
import { GrClose, GrMenu, GrUserSettings } from 'react-icons/gr';
import { MdAdminPanelSettings } from 'react-icons/md';
import { convertFileObjectToImageStr } from "../../../utils/ImageHelper";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { useMediaQuery } from "react-responsive";
import AccountVerification from "../AccountVerification/AccountVerification";

const PageLayout = ({ noCurrentUser, newAccount, newAccountImage, updatedAccountImage, children }) => {

    const [closeNav, setCloseNav] = useState(true);
    const [showPool, setShowPool] = useState(false);
    const navRef = useRef(null);
    const showRef = useRef(null);
    const profileMenuRef = useRef()
    const [showProfileMenu, setShowProfileMenu] = useState(false)
    const [showUsernameModal, setShowUsernameModal] = useState(false);
    const [newUsername, setNewUsername] = useState("");
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const [newAccountProfileImg, setNewAccountProfileImg] = useState(null);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });
    const [ showVerificationModal, setShowVerificationModal ] = useState(false);

    const handleNavToggle = (e) => {
        if (showRef.current) {
            if (showRef.current.contains(e.target)) return setCloseNav(false);
        }

        if (navRef.current) {
            if (!navRef.current.contains(e.target)) setCloseNav(true);
        }
    }

    const { userTokenDetails, userTokenDetailsDispatch, setUsersFromServer, setPosts, user, userDispatch, setLikedPosts } = useContext(UserContext);

    useEffect(() => {
        if (!userTokenDetails?.token) return
        getAllUsers(userTokenDetails).then(res => {
            console.log(res)
            setUsersFromServer(res.data)
        }).catch(err => {
            console.log(err)
            // toast.error(err.response.data)
        })

        getAllPosts(userTokenDetails).then(res => {
            setPosts(res.data.posts)
            setLikedPosts(res.data.likedPosts)
        }).catch(console.log)
    }, [])

    useClickOutside(profileMenuRef, () => setShowProfileMenu(false));

    useEffect(() => {
        if (!newAccountImage || !updatedAccountImage) return

        if (updatedAccountImage) {
            convertFileObjectToImageStr(newAccountImage).then(res => {
                setNewAccountProfileImg(res)
            }).catch(err => {
                console.log(err)
            })
            return
        }

        convertFileObjectToImageStr(newAccountImage).then(res => {
            setNewAccountProfileImg(res)
        }).catch(err => {
            console.log(err)
        })
    }, [newAccountImage, updatedAccountImage])

    const navigate = useNavigate()

    const handleUserLogout = () => {
        userTokenDetailsDispatch({ type: LOGOUT_USER })
        navigate("/login")
    }

    const handleConfigureUsername = () => {
        setShowUsernameModal(true);
        setShowProfileMenu(false);
    }

    const handleSaveBtnClick = async () => {
        if (newUsername.length < 1 || !userTokenDetails) return

        setDisableSaveBtn(true);

        try {
            const response = await (await updateUsername(userTokenDetails, { username: newUsername })).data;
            setShowUsernameModal(false);
            toast.success(`${response} \nCheck your profile page to see your new username.`)
            userDispatch({
                type: USER_PROFILE,
                payload: { ...user, username: newUsername }
            })
        } catch (error) {
            console.log(error.response ? error.response.data : error.message);
            setDisableSaveBtn(false);
            setShowUsernameModal(false);
            toast.error(error.response ? error.response.data : error.message);
        }
    }

    return (
        <>
            <div className="dashboard-navbar">
                <div className="dashboard-navbar-1">
                    <img src={logo} alt="Sportspadi Logo" onClick={() => navigate('/posts')}
                    />
                </div>

                <div className="dashboard-navbar-2" >
                    {noCurrentUser ? <>
                        {
                            isMobileScreen ? <>
                                <div onClick={() => setShowMobileNav(!showMobileNav)}>
                                    { 
                                        showMobileNav ? <GrClose /> :
                                        <GrMenu /> 
                                    }
                                </div>
                                { 
                                    showMobileNav ? <div className="external__mobile__Nav">
                                        <Nav.Link href="/signup">
                                            <Button
                                                style={{
                                                    width: "135px",
                                                    height: "35px",
                                                    fontSize: "20px",
                                                    fontWeight: 400,
                                                    borderRadius: "20px",
                                                    background: "#F3FAF6",
                                                    paddingBottom: "38px",
                                                    fontFamily: "Flutter",
                                                }}
                                                variant="light custom"
                                                onClick={() => navigate('/signup')}
                                            >
                                                Join now
                                            </Button>
                                        </Nav.Link>
                                        <Nav.Link>
                                            <Button
                                                style={{
                                                    width: "135px",
                                                    borderRadius: "30px",
                                                    fontSize: "20px",
                                                    fontWeight: 400,
                                                    fontFamily: "Flutter",
                                                }}
                                                variant="outline-success custom"
                                                onClick={() => navigate('/login')}
                                            >
                                                sign in
                                            </Button>
                                        </Nav.Link>
                                    </div> :
                                    <></>
                                }
                            </> : <>
                                <Nav className="ml-auto">
                                    <Nav.Link href="/signup">
                                        <Button
                                            style={{
                                                width: "135px",
                                                height: "35px",
                                                fontSize: "20px",
                                                fontWeight: 400,
                                                borderRadius: "20px",
                                                background: "#F3FAF6",
                                                paddingBottom: "38px",
                                                fontFamily: "Flutter",
                                            }}
                                            variant="light custom"
                                            onClick={() => navigate('/signup')}
                                        >
                                            Join now
                                        </Button>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Button
                                            style={{
                                                width: "135px",
                                                borderRadius: "30px",
                                                fontSize: "20px",
                                                fontWeight: 400,
                                                fontFamily: "Flutter",
                                            }}
                                            variant="outline-success custom"
                                            onClick={() => navigate('/login')}
                                        >
                                            sign in
                                        </Button>
                                    </Nav.Link>
                                </Nav>
                            </>
                        }
                    </> :
                        newAccount ? <>
                            {
                                newAccountProfileImg ? <>
                                    <img src={newAccountProfileImg} alt="new user profile" />
                                </> :
                                    <div className="new__Account__Profile"></div>
                            }
                        </> :
                            <>
                                <IoMdNotificationsOutline onClick={() => navigate("/notifications")} />
                                {/* <svg width="42" height="45" viewBox="0 0 42 45" 
onClick={()=>navigate("/notifications")}
style={{cursor:"pointer"}}
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" 
            d="M35.4375 25.3687C35.7562 27.2812 36.075 28.875 36.7125 30.7875L38.625 36.2062L37.0312 38.4375H25.875C25.875 40.0312 25.2375 41.625 23.9625 42.9C22.6875 44.175 21.0938 44.8125 19.5 44.8125C17.9062 44.8125 15.9937 44.175 15.0375 42.9C13.7625 41.625 13.125 40.0312 13.125 38.4375H1.96875L0.375 36.2062L2.2875 30.7875C2.925 28.2375 3.5625 25.6875 3.5625 23.1375V16.125C3.5625 13.8937 3.88125 11.6625 4.8375 9.75C5.79375 7.51875 7.06875 5.925 8.6625 4.33125C10.2562 2.7375 12.1688 1.78125 14.4 1.14375C15.9938 0.50625 17.9062 0.1875 19.5 0.1875C18.8625 1.14375 18.225 2.41875 17.5875 3.69375C16.95 3.69375 16.3125 3.69375 15.3562 4.33125C13.7625 4.65 12.1688 5.60625 10.8938 6.88125C9.61875 7.8375 8.34375 9.43125 7.70625 11.025C7.06875 12.6188 6.75 14.2125 6.75 16.125V23.1375C6.75 26.0062 6.1125 28.875 5.475 31.7437L4.2 35.25H34.8L33.525 31.7437C32.9672 30.0671 32.6516 28.1419 32.3679 26.4079L32.25 25.6875C33.525 25.6875 34.4812 25.6875 35.4375 25.3687ZM19.5 41.625C20.1375 41.625 21.0937 41.3063 21.7312 40.6688C22.3687 40.0313 22.6875 39.3938 22.6875 38.4375H16.3125C16.3125 39.3938 16.6313 40.0313 17.2688 40.6688C17.9063 41.3063 18.8625 41.625 19.5 41.625ZM41.8125 9.75C41.8125 12.2861 40.805 14.7184 39.0117 16.5117C37.2184 18.305 34.7861 19.3125 32.25 19.3125C29.7139 19.3125 27.2816 18.305 25.4883 16.5117C23.695 14.7184 22.6875 12.2861 22.6875 9.75C22.6875 7.21387 23.695 4.78161 25.4883 2.98829C27.2816 1.19497 29.7139 0.1875 32.25 0.1875C34.7861 0.1875 37.2184 1.19497 39.0117 2.98829C40.805 4.78161 41.8125 7.21387 41.8125 9.75Z" 
            fill={"black"}/>
        </svg> */}
                                <>
                                    {
                                        updatedAccountImage || user.profilePhoto ?
                                        <img src={updatedAccountImage ? updatedAccountImage : user.profilePhoto} alt="user profile" onClick={() => setShowProfileMenu(true)} /> :
                                        <AvatarComponent displayName={user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user.name} width={'40px'} height={'40px'} handleNavIconClick={() => setShowProfileMenu(true)} />
                                    }
                                </>
                            </>
                    }

                </div>
            </div>
            {showProfileMenu && <div className={`profile-menu ${(!user.username || !user.accountDetailsVerified || user.accountDetailsVerified === 'false') ? 'username__Option' : ''}`} ref={profileMenuRef}>
                <div className="profile-menu-div-1" onClick={user.username ? () => navigate(`/${user.username}`) : () => navigate("/user-profile")}>
                    <HiOutlineUserCircle style={{ fontSize: "1.3rem" }} />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{color:"black"}} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6">
            <circle cx="12" cy="7" r="4" className="sc-htoDjs dXGcZC mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6"></circle>
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" className="sc-gzVnrw iLnVEa mb-1 md:my-4 md:mr-2 text-lg w-6 h-6 md:w-6 md:h-6"></path>
        </svg> */}
                    <span>Profile</span>
                </div>

                {
                    !user.username ?
                        <div className="profile-menu-div-2" onClick={handleConfigureUsername}>
                            <FontAwesomeIcon icon={faUserEdit} />
                            <span>Configure username</span>
                        </div> : <></>
                }
                {
                    !user.accountDetailsVerified || user.accountDetailsVerified === 'false' ?
                        <div className="profile-menu-div-2" onClick={() => setShowVerificationModal(!showVerificationModal)}>
                            <MdAdminPanelSettings style={{ fontSize: "1.3rem" }} />
                            <span>Account Verification</span>
                        </div> : <></>}
                <div className="profile-menu-div-2" onClick={handleUserLogout}>
                    <IoIosLogOut style={{ fontSize: "1.3rem" }} />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" 
                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round" className="feather feather-log-out" 
                style={{color: "black"}}>
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg> */}
                    <span>Logout</span>
                </div>
            </div>}
            <div className="FullPageLayout" onClick={handleNavToggle}>
                {(noCurrentUser || newAccount) ? <></> : <Navbar closeNav={closeNav} navRef={navRef} />}
                <div className={`MainPageLayout ${(noCurrentUser || newAccount) ? 'external__View' : ''}`}>
                    {
                        (noCurrentUser || newAccount) ? <></> : <div className="showNav">
                            <div className="showNav__Svg" ref={showRef}>
                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="2" fill="#00A01E" />
                                    <rect y="8" width="20" height="2" fill="#00A01E" />
                                    <rect y="16" width="20" height="2" fill="#00A01E" />
                                </svg>
                            </div>
                            <div className="showPool"
                                // onClick={() => setShowPool(!showPool)}
                            >
                                {showPool ? SVGs.eyeHide : SVGs.eyeShow}
                            </div>
                        </div>
                    }
                    <div className="PageLayout">
                        {children}
                    </div>
                </div>
                {(noCurrentUser || newAccount) ? <></> : <SideBarComponent showPool={showPool} />}
                {
                    showUsernameModal ?
                        <div className="edit__Modal__Overlay">
                            <div className="edit__Modal__Container">
                                <div className="edit__Top__Row">
                                    <h3>Add username</h3>
                                    <div className="close__Icon__Container" onClick={() => setShowUsernameModal(false)}>
                                        <FontAwesomeIcon icon={faClose} color={"black"} cursor={"pointer"} />
                                    </div>
                                </div>
                                <div className="edit__Middle__Row">
                                    <div className="edit__Name__Container">
                                        <p className="title__Text">Username</p>
                                        <input type={"text"} value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="edit__Bottom__Row">
                                    <button className="save__Btn" onClick={handleSaveBtnClick} disabled={disableSaveBtn}>{disableSaveBtn ? "Please wait..." : "Save"}</button>
                                </div>
                            </div>
                        </div> :
                        <></>
                }
                {
                    showVerificationModal && 
                        <AccountVerification 
                            handleClose={() => setShowVerificationModal(false)}
                        />
                }
            </div>
        </>
    )
}

export default PageLayout;