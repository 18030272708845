import React, { useContext, useEffect } from "react";
import {
  Row,
  Container,
  Col,
  Image,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import player from "../../../assets/img/secondBall.png";
import logo from "../../../assets/img/logo-dark.png";
import icon1 from "../../../assets/img/facebookicon.png";
import icon3 from "../../../media/icons8-linkedin-48 (1).png";
import { useState } from "react";
import "./login.styles.css";
import {signInStart} from "../../../redux/user/user.actions";
import {createStructuredSelector} from "reselect";
import {
    selectCurrentUserLoadingStatus,
    selectCurrentUserLoginError,
    selectCurrentUserSessionError
} from "../../../redux/user/user.selectors";
import {connect} from "react-redux";
import ButtonSpinner from "../../spinners/button-spinner.component";
import {Link, useNavigate} from "react-router-dom";
import history from "../../../history";
import { UserContext,USER_TOKEN } from "../../../context/UserContext";
import { loginUser } from "../../../services/userService";
import {toast} from "react-toastify";

const LogInComponent = () => {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const { email, password } = user;

    const handleChange = ({target:{name,value}}) => setUser({...user, [name]: value });
  
    const {userTokenDetailsDispatch,userTokenDetails} = useContext(UserContext);
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()


    useEffect(()=>{
if(userTokenDetails?.accountType&&userTokenDetails?.accountConfigured) return navigate("/posts")
    },[]) 

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(!password || !email) return;
        setLoading(true)

        loginUser(user).then(res=>{
          console.log(res)
          setLoading(false)
          if(!res.data.accountVerified) return toast.error('Check your mail to verify your acount!')
          userTokenDetailsDispatch({
            type:USER_TOKEN,
            payload:res.headers["auth-token"]
          })
          if(!res.data.accountConfigured) return navigate("/select-account")
        toast.success('Logged in successfully!')
        return navigate("/posts")
        }).catch(err=>{
          console.log(err)
          setLoading(false)
toast.error(err.response.data)
        })

    }


   

    return (
    <>
      <div className="signup">
        <Container fluid>
          <Row>
            <Col md={5}>
              <div className="signup1">
                <Image
                  fluid
                  src={player}
                  style={{
                    marginLeft: "1rem",
                    marginTop: "8rem",
                  }}
                />
                <div />
                <p style={{ textAlign: "center" }}>
                AI-powered Sports Talent Pool Platform{" "}
                  <span style={{ display: "block" }}>
                
                  </span>
                </p>
              </div>
            </Col>
            <Col>
              <div className="signup2">
              <div className="dashboard-navbar-1"
                  onClick={() => navigate('/')}>
                  
                    <img src={logo} alt="Sportspadi Logo" />
                </div>
                <h2 style={{ color: "#00a01e" }}>Login to your dashboard</h2>
                <div className="">
                  <Form
                    className="form-group col-lg-8"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="validationCustom01">
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        style={{
                          backgroundColor: "#BFE7C7",
                          borderRadius: "20px",
                          marginTop: "2rem",
                          height: "3rem",
                        }}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="validationCustomPassword">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={password}
                          name="password"
                          required
                          style={{
                            backgroundColor: "#BFE7C7",
                            borderRadius: "20px",
                            marginTop: "2rem",
                            height: "3rem",
                          }}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Button
                        className="registerbtn"
                        style={{
                          width: "100%",
                          fontWeight: 400,
                          marginTop: "2rem",
                          fontSize: "20px",
                          padding: "7px 5px",
                          borderRadius: "20px",
                          color: "#fff",
                          backgroundColor: "#00A01E",
                          border: "0",
                        }}
                        variant="outline-primary custon"
                        type="submit"
                      >
                          {loading?(<ButtonSpinner />): 'Login'}
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
                <div className="foot" style={{ marginTop:"20px", textAlign:"center" }}>
                  <h4>Forgot password</h4>
                    <Link to={'/reset-password'}>Reset here</Link>
                  <div className="socialicon">

                  </div>
                  <h4
                    style={{
                      marginTop: "2rem",
                      fontFamily: "Flutter",
                    }}
                    className="signupp"
                  >
                    Don't have an account? <Link to='/signup' >
                      Sign Up
                  </Link>
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}


export default LogInComponent;
