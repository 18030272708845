export const CoachActionTypes = {
    REGISTER_COACH_START: 'REGISTER_COACH_START',
    REGISTER_COACH_SUCCESS: 'REGISTER_COACH_SUCCESS',
    REGISTER_COACH_FAILURE: 'REGISTER_COACH_FAILURE',
    FETCH_COACH_PRIMARY_PROFILE_START: 'FETCH_COACH_PRIMARY_PROFILE_START',
    FETCH_COACH_PRIMARY_PROFILE_SUCCESS: 'FETCH_COACH_PRIMARY_PROFILE_SUCCESS',
    FETCH_COACH_PRIMARY_PROFILE_FAILURE: 'FETCH_COACH_PRIMARY_PROFILE_FAILURE',
    UPDATE_COACH_PRIMARY_PROFILE_START: 'UPDATE_COACH_PRIMARY_PROFILE_START',
    UPDATE_COACH_PRIMARY_PROFILE_SUCCESS: 'UPDATE_COACH_PRIMARY_PROFILE_SUCCESS',
    UPDATE_COACH_PRIMARY_PROFILE_FAILURE: 'UPDATE_COACH_PRIMARY_PROFILE_FAILURE',
    CLEAR_COACH_PRIMARY_PROFILE: 'CLEAR_COACH_PRIMARY_PROFILE',
}