import React from 'react'
import RegisterComponent from "../../components/auth/register/register.component";

const RegisterPage = () => {
    return (
        <div>
            <RegisterComponent />
        </div>
    )
}

export default RegisterPage
