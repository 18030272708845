export const PostActionTypes = {
    WRITING_POST_CONTENT: 'WRITING_POST_CONTENT',
    CREATE_POST_START: 'CREATE_POST_START',
    CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
    CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',
    FETCH_POSTS_START: 'FETCH_POSTS_START',
    FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
    FETCH_POSTS_FAILURE: 'FETCH_POSTS_FAILURE',
    FETCH_USER_POSTS_START: 'FETCH_USER_POSTS_START',
    FETCH_USER_POSTS_SUCCESS: 'FETCH_USER_POSTS_SUCCESS',
    FETCH_USER_POSTS_FAILURE: 'FETCH_USER_POSTS_FAILURE'
}