export const UserActionTypes = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',

    SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    USER_SESSION_FAILURE: 'USER_SESSION_FAILURE',

    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    UPDATE_PROFILE_START: 'UPDATE_PROFILE_START',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    VERIFICATION_START: 'VERIFICATION_START',
    VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
    VERIFICATION_FAILURE: 'VERIFICATION_FAILURE',
}