import React, { useState, useRef, useEffect } from "react";
import "./Questions.css";


const Questions = ({
    questionNumber, selectedOption,
    handleNext, handlePrev, questions,
    setSelectedOption, setRoute,
    setQuestionNumber
}) => {

    const timeRef = useRef(60);
    const stopTimingRef = useRef(null);

    const [timer, setTimer] = useState(60);
    useEffect(() => {
        if(questionNumber <= questions.length) {
            if(stopTimingRef.current) clearInterval(stopTimingRef.current);
            timeRef.current = 60;
            setTimer(60);
            // const interval
            stopTimingRef.current = setInterval(() => {
                timeRef.current--;
                if(timeRef.current > 0) setTimer(prev => prev-1);
                else {
                    // clearInterval(interval);
                    handleNext();
                }
            }, 1000);
            // stopTimingRef.current = interval;
        }
        return () => clearInterval(stopTimingRef.current);
    }, [questionNumber]);

    // const handleNextClick = () => 

    const getTime = () => {
        if(timer >= 60) return "01:00";
        else if(timer >= 10) return `00:${timer}`;
        else return `00:0${timer}`;
    }

    return (
        <div className="Questions">
            <div className="sticky__Top">
                <span>Question {questionNumber}</span>
                <div onClick={() => setRoute("skills")}>
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5759 24.4404L24.6491 28.3117L14.5421 18.3416L4.51111 28.2357L0.562461 24.3416L10.5935 14.4453L0.485352 4.47404L4.41214 0.601562L14.5191 10.5728L24.5501 0.677627L28.4988 4.57281L18.4678 14.4691L28.5759 24.4404Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="q__Main">
                <ul>
                    <li>{questions[questionNumber-1].question}</li>
                    {questions[questionNumber-1].options.map((val, idx) => (
                        <li key={`questions-${idx}`}>
                            {selectedOption===idx && <div className="input-select true"
                            onClick={() => setSelectedOption(idx)}>
                                <div></div>
                            </div>}
                            {selectedOption!==idx && <div className="input-select"
                            onClick={() => setSelectedOption(idx)}>
                                <div></div>
                            </div>}
                            <span>{val}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="q__Bottom">
                <div className="qB__Timer">
                    <svg fill="#A5A4A4" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                    width="800px" height="800px" viewBox="0 0 220 260" xmlSpace="preserve">
                        <path d="M123,43V28h16V2H81v26h16v15C43.696,49.44,2,95.241,2,150.253C2,209.665,50.335,258,109.747,258
                            s107.747-48.335,107.747-107.747C217.494,95.241,176.304,49.44,123,43z M109.747,232C64.671,232,28,195.328,28,150.253
                            c0-45.076,36.671-81.747,81.747-81.747c45.075,0,81.747,36.671,81.747,81.747C191.494,195.328,154.822,232,109.747,232z
                            M161.082,113.062l-32.641,32.641c0.355,1.462,0.563,2.98,0.563,4.551c0,10.635-8.622,19.257-19.257,19.257
                            c-10.635,0-19.257-8.622-19.257-19.257c0-10.635,8.622-19.257,19.257-19.257c1.571,0,3.09,0.209,4.551,0.564l32.641-32.641
                            L161.082,113.062z"/>
                    </svg>
                    <span>{getTime()}</span>
                </div>
                <div>
                    {/* {questionNumber>1 && <div className="qB__Button prev"
                    onClick={handlePrev}>
                        Prev</div>} */}
                    <div className="qB__Button next" onClick={handleNext}>
                        {questionNumber<questions.length?"Next":"Submit"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Questions;