import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./ImagePage.css";
import image from "../imagess/playerPic.png";
import sender from "../imagess/interviewPic.png";

// const ImagePage = ({ images, sender, senderImg, date }) => {
const ImagePage = ({ imagesData, hide }) => {

    
    const images = imagesData?.images || [];
    const senderName = "John Doe";
    const date = String(new Date()).split(" ").slice(0, 5).join(" ");
    const slideRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    }

    useEffect(() => {
        if(slideRef.current) {
            slideRef.current.slickGoTo((imagesData?.id||0));
        }
    }, [imagesData]);

    const getImgUrl = (image) => {
        if(typeof image === "object") return URL.createObjectURL(image);
        else return image;
    };

    return (
        <div className="imagePage">
            <div className="imagesPage__Div">
                <div className="iP__Top">
                    <div>
                        <img src={sender} />
                        <span className="iP__Big">{senderName}</span>
                        <span className="iP__Small">at {date}</span>
                    </div>
                    <div className="iP__Top__Left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" 
                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                        onClick={hide} 
                        strokeLinejoin="round" className="x" style={{color: "white"}}>
                            <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>
                <div className="iP__Img">
                    <Slider ref={slideRef} {...settings}>
                        {images.map((val, idx) => (
                            <div className="iP__Li" key={`imgSlide-${idx}`}>
                                <div><img src={getImgUrl(val)} /></div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default ImagePage;