import React, { useState } from "react";
import "./Assessment.css";
import Results from "./Components/Results";
import Questions from "./Components/Questions";
import Start_Assessment from "./Components/Start_Assessment";

const Assessment = ({ setSkills, id, setRoute }) => {

    const [questionNumber, setQuestionNumber] = useState(1);
    const [assessmentRoute, setAssessmentRoute] = useState("start");
    const [score, setScore] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const questions = new Array(5).fill({
        question: "The Ball should be kept where when dribbling",
        options: [
            "Between the hands","Between the legs","Between the chairs",
            "In the Laundry Basket"
        ],
        answer: 1
    })

    const handleNext = () => {
        let scoreTmp = score;
        if(selectedOption === questions[questionNumber-1].answer) scoreTmp++;

        if(questionNumber === questions.length) {
            setAssessmentRoute("results");
            setQuestionNumber(0);
            setSkills(prev => {
                prev[id].assessment = scoreTmp;
                return prev;
            })
        } else {
            setQuestionNumber(prev => prev+1);
        }

        setScore(scoreTmp);
        setSelectedOption(null);
    }

    const handlePrev = () => {
        if(questionNumber > 1) setQuestionNumber(prev => prev-1);

        setSelectedOption(null);
    }


    return (
        <div className="Assessment">
            {assessmentRoute==="start" && 
                <Start_Assessment 
                    setRoute={setRoute}
                    setAssessmentRoute={setAssessmentRoute}
                 />
            }
            {assessmentRoute==="questions" && 
                <Questions
                    questionNumber={questionNumber}
                    setQuestionNumber={setQuestionNumber}
                    questions={questions}
                    handleNext={handleNext} 
                    handlePrev={handlePrev}
                    setRoute={setRoute}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption} 
                />
            }
            {assessmentRoute==="results" && 
                <Results score={score}
                    setRoute={setRoute} 
                    questions={questions} 
                />
            }
        </div>
    )
}

export default Assessment;