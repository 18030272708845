import React, { useEffect, useState } from "react";
import styles from './award-style.module.css';
import { BsFillAwardFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";


const AwardsPage = () => {
    const [ awards, setAwards ] = useState([]);
    const { state } = useLocation();

    useEffect(() => {
        if (!state || !state.userAwards) return
        setAwards(state.userAwards)
    }, [state])

    return <>
        <div className={styles.award__Container}>
            {
                awards.length < 1 ?
                <p>User has not added any awards yet.</p> :
                <>
                    <h3><b>Awards ({awards.length})</b></h3>
                    <ul className={styles.award__List__Wrapper}>
                        {
                            React.Children.toArray(awards).map(award => {
                                return <li>
                                    <BsFillAwardFill className={styles.award__Icon} />
                                    <span>{award}</span>
                                </li>
                            })
                        }   
                    </ul>
                </>
            }
        </div>
    </>
}

export default AwardsPage;
