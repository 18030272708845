import React, { useContext, useEffect, useState } from "react";
import "./SideComponent.css";
import imag from "../imagess/interviewPic.png";
import SVGs from "../imagess/svg";
import Player from "../../../backend/Player";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from "react-redux";
import { getAllUsers, interviewPlayer, waveCoach } from "../../../services/userService";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import WaveModalInfo from "../WaveModalInfo/WaveModalInfo";
const notify = withReactContent(Swal);

const SideBarComponent = ({ showPool }) => {

    // const [show, setShow] = useState(null);
    const interviews = new Array(10).fill({
        name: "John Agent",
        image: imag,
        title: "Professional Agent"
    });

    const { userTokenDetails, usersFromServer, messages, setMessages, user, messagesLoading } = useContext(UserContext);

    // useEffect(()=>{
    //     if(!userTokenDetails?.token) return 
    //     getAllUsers(userTokenDetails).then(res=>{
    //         console.log(res)
    //         setUsersFromServer(res.data)
    //     }).catch(err=>{
    //         console.log(err)
    //         toast.error(err.response.data)
    //     })
    // },[])


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [ loadingItems, setLoadingItems ] = useState([])
    const [ showWaveInfoItems, setShowWaveInfoItems ] = useState([])
    const [ showWaveModal, setShowWaveModal ] = useState(false);

    useEffect(() => {

        setLoadingItems(usersFromServer.map(user => {
            return { userId: user._id, loading: false }
        }))

    }, [usersFromServer])
    const handleButtonClick = (passedUser) => {
        if (!passedUser?._id || !userTokenDetails?.token ) return;
        if (passedUser.accountType === "player") return navigate(`/schedule/book/${passedUser._id}`, { state: { passedUser } })

        function updateLoadingStatus(valueToUpdateTo) {
            const currentLoadingItems = loadingItems.slice();

            const foundUserItemIndex = currentLoadingItems.findIndex(item => item.userId === passedUser._id)
            if (foundUserItemIndex !== -1) currentLoadingItems[foundUserItemIndex].loading = valueToUpdateTo
            setLoadingItems(currentLoadingItems);
        }
        
        if (passedUser.accountType === "coach") {
            updateLoadingStatus(true)
            // setLoading(true)
            waveCoach(passedUser._id,userTokenDetails.token).then(res=>{
                console.log(res)
                // setLoading(false)
                updateLoadingStatus(false)
                
                setMessages((prevMessages) => { 
                    return [
                        {
                            _id: crypto.randomUUID(),
                            owner: user._id,
                            otherUser: passedUser._id,
                            itemDisplayName: passedUser.name,
                            itemUserType: "coach",
                            messages: [
                                {
                                    typeOfMessage: "wave",
                                    textContent: "Hi, I would love if you could consider my profile!",
                                    waveType: "sent",
                                    createdAt: new Date(),
                                }
                            ]
                        },
                        ...prevMessages
                    ] 
                })
                toast.success('Successfully waved Scout!')
            }).catch(err=>{
                console.log(err)
                // setLoading(false)
                updateLoadingStatus(false)
                toast.error(err.response.data)
            })
        }

    }

    const updateWaveInfoForUser = (userId, value) => {
        const currentItems = showWaveInfoItems.slice();
        const existingItem = currentItems.find(item => item.userId === userId);

        if (existingItem) {
            existingItem.show = value;
        } else {
            currentItems.push({
                userId: userId,
                show: value,
            })
        }
        
        setShowWaveInfoItems(currentItems);
    }

    const handleCloseWaveModal = () => {
        localStorage.setItem('waveInfoShown', true);
        setShowWaveModal(false)
    }



    return (
        <div className={`sideBarComponent_div ${showPool}`}>
            <div className="sideBarComponent__Wrapper">
                <div className="sC__Wrapper">
                    <div className="sC__Wrapper__Top">
                        <div>
                            {SVGs.search}
                            <input placeholder="Search" />
                        </div>
                    </div>
                    <div className="sideBarComponent">
                        {messagesLoading ? <></> :<ul className="sC__ul">
                            {/* <li className="sC__li">
                                <div className="sC__Top">
                                    <img src={imag} />
                                    <div className="sC__Toggler__Top">Interview</div>
                                </div>
                                <div className="sC__Txts">
                                    <span className="sC__Big">John Doe</span>
                                    <span className="sC__Small">Talented Footballer</span>
                                    
                                </div>
                                <div className="sC__Toggler">Interview</div>
                            </li> */}
                            {
                                usersFromServer.map((user, idx) => {
                                if (messages.find(message => message.otherUser === user._id)) return <></>

                                return <li 
                                    className="sC__li" key={`sC-${user._id}`} 
                                    onMouseLeave={
                                        userTokenDetails?.accountType === "player" ? 
                                            () => updateWaveInfoForUser(user._id, false)
                                        :
                                        () => {}    
                                    } 
                                >
                                    <div className="sC__Top">
                                        {
                                            user.profilePhoto ?
                                            <img src={user.profilePhoto} alt={'user profile'} onClick={user.username ? () => navigate(`/${user.username}`) : () => {}} /> :
                                            <AvatarComponent displayName={user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user?.name} width={'50px'} height={'50px'} handleNavIconClick={user.username ? () => navigate(`/${user.username}`) : () => {}} />
                                        }
                                        <div className="sC__Toggler__Top">Connect</div>
                                    </div>
                                    <div className="sC__Txts">
                                        <span className="sC__Big">{user.newAccountStructure ? `${user?.firstName} ${user?.lastName}`: user.name}</span>
                                        <span className="sC__Small">{user?.accountType === "player" ? "Athlete" : "Scout"}</span>

                                    </div>
                                    <div 
                                        className="sC__Toggler" 
                                        onMouseEnter={
                                            userTokenDetails?.accountType === "player" ? 
                                                localStorage.getItem('waveInfoShown') ? 
                                                    () => {}
                                                :
                                                () => updateWaveInfoForUser(user._id, true)
                                            :
                                                () => {}
                                            } 
                                        onClick={
                                            () => handleButtonClick(user)
                                        }
                                    >
                                        {
                                            userTokenDetails?.accountType === "coach" ? 
                                                "Interview" 
                                            : 
                                                loadingItems.find(item => item.userId === user._id)?.loading === true ? 
                                                    "Waving..." 
                                                : 
                                                "Wave"
                                        }
                                    </div>
                                    { 
                                        showWaveInfoItems.find(item => item.userId === user._id)?.show && 
                                        <p 
                                            className='wave__Info__Item'
                                            onClick={showWaveModal ? () => {} : () => setShowWaveModal(true)}
                                        >
                                            Info
                                        </p> 
                                    }
                                </li>
                            })}
                        </ul>}
                    </div>
                </div>
            </div>
            {
                showWaveModal && <WaveModalInfo closeModal={handleCloseWaveModal} />
            }
        </div>
    )
}

export default SideBarComponent;
