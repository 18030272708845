import React, { useContext, useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import { DatePicker } from "antd";
import moment from "moment";
import "./BookInterview.css";
import imageBook from "../../imagess/interviewPic.png";
import PageLayout from "../../Layout/PageLayout";
import { useLocation, useParams } from "react-router-dom";
import { interviewPlayer } from "../../../../services/userService";
import { UserContext } from "../../../../context/UserContext";
import { toast } from "react-toastify";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";

const BookInterview = () => {

    const {userId} = useParams()
    const {state}  = useLocation()
    const [currentUser,setCurrentUser] = useState({
        name:"",
        _id:"",
        username: "",
        profilePhoto: "",
    })
    const {userTokenDetails,usersFromServer,user,setMessages} = useContext(UserContext);
    const [interview, setInterview] = useState({message:"",date:""});
    const [ usersJustSentInterviewTo, setUsersJustSentInterviewTo ] = useState([]);

useEffect(()=>{
    if(state?.user){
        setCurrentUser(state.user)
    }else{
        //bro, call api to get the user 
        const data = usersFromServer?.find(x=>x?._id===userId)
        setCurrentUser(data?data:{})
    }
},[userId, usersFromServer?.length])


const [loading,setLoading] = useState(false)

const handleFormSubmit = (e) => {
    e.preventDefault();
    if(!userTokenDetails?.token || interview.date.length < 1) return;

    setLoading(true)
    interviewPlayer({...interview,player:currentUser._id},userTokenDetails.token).then(res=>{
        console.log(res)
        setLoading(false)
        setMessages((prevMessages) => { 
            return [
                {
                    _id: crypto.randomUUID(),
                    owner: user._id,
                    otherUser: currentUser._id,
                    itemDisplayName: currentUser.newAccountStructure ? `${currentUser?.firstName} ${currentUser?.lastName}`: currentUser.name,
                    itemUserType: "player",
                    messages: [
                        {
                            typeOfMessage: "sent",
                            textContent: interview.message,
                            createdAt: new Date(),
                        }
                    ],
                    messageExchangeAllowed: true,
                    itemUsername: currentUser.username,
                },
                ...prevMessages
            ] 
        })
        setInterview({
            message:"",
            date: "",
        })
        setUsersJustSentInterviewTo((prevVal) => { return [...prevVal, currentUser._id]})
        toast.success("Interview has been set!")
    }).catch(err=>{
        console.log(err)
        setLoading(false)
        toast.error(err.response.data)
    })
}

    


    return (
        <PageLayout>
            <div className="bookInterview">
            <div className="bookInterview__Wrapper">
                <div className="bI__Wrapper">
                    <h1>{usersJustSentInterviewTo.find(user => user === currentUser._id) ? "" : "Book Talent Now"}</h1>
                    <div className="bookTalent">
                        <div className="bT__Contents">
                            <div className="bT__Img">
                                {
                                    currentUser?.profilePhoto ? 
                                    <img src={currentUser.profilePhoto} alt="user" /> :
                                    <AvatarComponent displayName={currentUser.newAccountStructure ? `${currentUser?.firstName} ${currentUser?.lastName}`: currentUser.name} width={'100px'} height={'100px'} />
                                }
                            </div>
                            <div className="bT__Main">
                                <span className="bT__Big">
                                    <>
                                        {usersJustSentInterviewTo.find(user => user === currentUser._id) ? 'Interview invite sent to ' : 'Interview '}
                                    </> 
                                    <>
                                        {currentUser.newAccountStructure ? `${currentUser?.firstName} ${currentUser?.lastName}`: currentUser.name}
                                    </>
                                </span>
                                <span className="bT__Small">
                                    {
                                        usersJustSentInterviewTo.find(user => user === currentUser._id) ? `You have taken the first step in your selection process by sending ${currentUser.newAccountStructure ? `${currentUser?.firstName} ${currentUser?.lastName}`: currentUser.name} an interview invite` :
                                        "Shoot an invite to your talent and begin your selection process"
                                    }
                                </span>
                                {usersJustSentInterviewTo.find(user => user === currentUser._id) ? <></> :
                                <form onSubmit={handleFormSubmit}>
                                    <textarea value={interview.message} placeholder="Your Message" onChange={(e)=>setInterview({...interview,message:e.target.value})} />
                                    {/* <input placeholder="Enter Date/Time" type="text" /> */}
                                    <DatePicker className="date_plain_picker"
                                        disabledDate={(current) => { return current < moment().startOf('day') }}
                                        onChange={(value) => {
                                            const val = moment(value).format("DD-MM-YYYY");
                                            setInterview({...interview, date: val});
                                        }}
                                        value={interview.date}
                                    />
                                    <input type="submit" value={loading?"Please wait...":"Book Now"} className="bT__Button" />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default BookInterview;