import React from "react";
import chatlogo from "../../../assets/img/chitchaticon.png";
import notification from "../../../assets/img/notificationicon.png";
import coachimg from "../../../assets/img/coachimg.png";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../../redux/user/user.selectors";
import { connect } from "react-redux";
import history from "../../../history";
import System from "../../../backend/System";
import { useLocation } from 'react-router-dom';


function CoachFeedsTopRightNavBar({ currentUser }) {
  const location = useLocation()
  return (
    <div className="navbaritems2">
      <img src={chatlogo} alt="" className="chatlogo" />
        <h4
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/talent-pool")}
        >
          Talents
        </h4>

      {/*Notification for accepted or rejected interview requests*/}
      <a href="">
        <img src={notification} alt="notifications" className="notif-icon" />
      </a>
      <img
        onClick={() => history.push("/coach-profile")}
        // src={System.userImagePath(currentUser.user.image)
  
        alt="User photo"
        className="profile-photo"
      />
    </div>
  );
}

export default CoachFeedsTopRightNavBar;