import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../SportsPadi/Layout/PageLayout';
import styles from './styles.module.css';
import { citiesInNigeria, countriesInTheWorld, countriesInTheWorldWIthNationalities } from './utils';
import { validateURL } from '../../utils/URLValidator';
import ImageComponent from './components/ImageComponent/ImageComponent';
import TextInputComponent from './components/TextInputComponent/TextInputComponent';
import SelectComponent from './components/SelectComponent/SelectComponent';
import AddComponent from './components/AddComponent/AddComponent';
import { toast } from 'react-toastify';
import { UserContext, USER_TOKEN } from '../../context/UserContext';
import { registerUserProfile } from '../../services/userService';
import DateComponent from './components/DateInputComponent/DateComponent';

const NewAccountSetupPage = () => {
    const { accountType } = useParams();
    const { state } = useLocation();
    const [ currentAccountType, setCurrentAccountType ] = useState(null);
    const [ currentFormPage, setCurrentFormPage ] = useState(1);
    const [ userInfo, setUserInfo ] = useState({});
    const [ btnDisabled, setBtnDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const { user, userTokenDetails, userTokenDetailsDispatch }  = useContext(UserContext);

    useEffect(() => {

        if (!acceptedAccountTypes.includes(accountType)) return navigate("/");
        
        if (!userTokenDetails || !user) return navigate("/login");    
        if (userTokenDetails?.accountConfigured) return navigate("/posts")

        if (!state || !state.name) {
            handleUserInfoChange('name', user?.name);
        } else {
            handleUserInfoChange('name', state.name);
        }

        setCurrentAccountType(accountType);

    }, [])

    const handleUserInfoChange = (stateToChange, value) => {
        setUserInfo((prevValue) => {
            return {
                ...prevValue,
                [stateToChange]: value
            }
        })
    }

    const handleUpdateFormPage = (actionType) => {
        const copyOfCurrentFormPage = currentFormPage;

        if (actionType === 'increase') {
            const currentItemsRequiredForPage = pageElements.find(element => element.accountType === currentAccountType)?.elements.find(element => element.pageNumber === currentFormPage)?.formElements.map((item) => {
                if (item.type === 'parent') {
                    return item.children.map(child => {
                        if (child.required && !child.optionalRequired) return child.stateName
                        return null
                    }).filter(mappedItem => mappedItem)
                }
                if (item.required) return item.stateName
                return null
            }).filter(mappedItem => mappedItem).flat()

            const missingItem = currentItemsRequiredForPage.map(item => {
                if (!userInfo[item] || userInfo[item].length < 1 || userInfo[item] === 'default') return item
                return null
            }).filter(item => item)
            // console.log(userInfo)

            // validating that all required items have been filled
            if (missingItem[0]) return toast.info(errorMessages[missingItem[0]]);

            // validating that numbers were entered in numeric fields
            if (userInfo.height && isNaN(userInfo.height)) return toast.info("Please make sure the height entered contains only digits");
            if (userInfo.weight && isNaN(userInfo.weight)) return toast.info("Please make sure the weight entered contains only digits");

            // validating that the user enters a sensible height
            if (userInfo.height && Number(userInfo.height) > 3) return toast.info("Please enter your actual height");

            // for the final page, checking that talent users that are not goalkeepers entered a position
            if ((copyOfCurrentFormPage === 2) && (currentAccountType === 'talent' && userInfo.position !== 'Goalkeeper') && (!userInfo.preferredFoot || userInfo.preferredFoot === 'default')) return toast.info(errorMessages['preferredFoot']);

            if (copyOfCurrentFormPage === 2) return finishAccountSetup();

            setCurrentFormPage(copyOfCurrentFormPage + 1)
        }

        if (actionType === 'decrease') {
            if (copyOfCurrentFormPage === 1) return navigate(-1);
            setCurrentFormPage(copyOfCurrentFormPage - 1)
        }
    }

    const finishAccountSetup = async () => {
        if (userInfo.social) {
            const invalidValidSocialLinks = userInfo.social.map((item) => {
                console.log(item, validateURL(item));
                if (!validateURL(item)) return "INVALID LINK"
                return null
            }).filter(mappedItem => mappedItem);
            if (invalidValidSocialLinks.length > 0) return toast.info('Please check that all social links entered are valid links');
        }
        
        if (userInfo.undefined) delete userInfo.undefined;

        setLoading(true);
        setBtnDisabled(true);

        if (currentAccountType === 'talent' || currentAccountType === 'scout') {
            delete userInfo['name'];
            
            try {
                let res;

                if (userInfo.profilePhoto) {
                    const newFormData = new FormData();

                    newFormData.append("profilePhoto", userInfo.profilePhoto);
                    Object.keys(userInfo).forEach(key => {
                        if (key === 'profilePhoto') return
                        if (key === 'awards' || key === 'social') {
                            userInfo[key].forEach(item => newFormData.append(`${key}[]`, item))
                            return
                        }
                        newFormData.append(`${key}`, userInfo[key])
                    });
                    newFormData.append('accountType', currentAccountType === "talent" ? "player" : "coach");

                    res = await registerUserProfile(newFormData, userTokenDetails.token);
                } else {
                    res = await registerUserProfile({...userInfo, accountType: currentAccountType === "talent" ? "player" : "coach"}, userTokenDetails.token)
                }

                console.log(res)
                
                setLoading(false);
                setBtnDisabled(false);
                userTokenDetailsDispatch({
                    type:USER_TOKEN,
                    payload:res.headers["auth-token"]
                })
                toast.success("Account configured successfully!")
                navigate("/posts")
            } catch (err) {
                console.log(err)
                setLoading(false);
                setBtnDisabled(false);
                toast.error(err.response.data ? err.response.data : err.message)
            }
            return
        }

        setLoading(false);
        setBtnDisabled(false);
    }


    return <PageLayout newAccount={true} newAccountImage={userInfo?.profilePhoto}>
        {
            !currentAccountType ? <></> :
            <div className={styles.new__Configuration__Container}>
                <h3>
                    {
                        currentFormPage === 1 ? "Profile Information" : 
                        currentFormPage === 2 ? "Professional Information" :
                        <></>
                    }
                </h3>
                {
                    React.Children.toArray(pageElements.find(element => element.accountType === currentAccountType)?.elements.find(element => element.pageNumber === currentFormPage)?.formElements.map((item) => {
                        if (item.type === 'image') return <div className={styles.form__Item}>
                            <ImageComponent 
                                label={item.label} 
                                handleItemUpdate={handleUserInfoChange} 
                                stateName={item.stateName}
                                userDisplayName={userInfo?.name}
                            />
                        </div>
                        if (item.type === 'text') return <div className={styles.form__Item}>
                            <TextInputComponent 
                                labelPresent={item.labelPresent}
                                label={item.label} 
                                placeholder={item.placeholder} 
                                stateName={item.stateName} 
                                inputValue={userInfo[`${item.stateName}`]} 
                                required={item.required} 
                                handleUpdateItem={handleUserInfoChange} 
                                readOnly={item.readOnly}
                            />
                        </div>
                        if (item.type === 'date') return <div className={styles.form__Item}>
                            <DateComponent 
                                labelPresent={item.labelPresent}
                                label={item.label} 
                                placeholder={item.placeholder} 
                                stateName={item.stateName} 
                                inputValue={userInfo[`${item.stateName}`]} 
                                required={item.required} 
                                handleUpdateItem={handleUserInfoChange} 
                            />
                        </div>
                        if (item.type === 'dropdown') return <div className={styles.form__Item}>
                            <SelectComponent
                                placeholder={item.label}
                                stateName={item.stateName}
                                required={item.required}
                                options={item.options}
                                handleItemUpdate={handleUserInfoChange}
                                value={userInfo[`${item.stateName}`]}
                            />
                        </div>
                        if (item.type === 'parent') return <div className={`${styles.form__Item} ${styles.flex__Form__Item}`}>
                            <>
                            {
                                React.Children.toArray(item.children.map(child => {
                                    if (child.type === 'image') return <div>
                                        <ImageComponent 
                                            label={child.label} 
                                            handleItemUpdate={handleUserInfoChange} 
                                        />
                                    </div>
                                    if (child.type === 'text') return <div>
                                        <TextInputComponent 
                                            placeholder={child.placeholder} 
                                            stateName={child.stateName} 
                                            inputValue={userInfo[`${child.stateName}`]} 
                                            handleUpdateItem={handleUserInfoChange} 
                                            required={child.required} 
                                            readOnly={child.readOnly}
                                        />
                                    </div>
                                    if (child.type === 'dropdown') return <>
                                        {
                                            child.stateName === 'preferredFoot' ? <>
                                                {
                                                    !userInfo.position || userInfo?.position === 'Goalkeeper' || userInfo?.position === 'default' ? <></> : 
                                                    <div>
                                                        <SelectComponent
                                                            placeholder={child.label}
                                                            stateName={child.stateName}
                                                            required={child.required}
                                                            options={child.options}
                                                            handleItemUpdate={handleUserInfoChange}
                                                            value={userInfo[`${child.stateName}`]}
                                                        />
                                                    </div>
                                                }
                                            </> :
                                            <div>
                                                <SelectComponent
                                                    placeholder={child.label}
                                                    stateName={child.stateName}
                                                    required={child.required}
                                                    options={child.options}
                                                    handleItemUpdate={handleUserInfoChange}
                                                    value={userInfo[`${child.stateName}`]}
                                                />
                                            </div>
                                        }
                                    </>
                                    return <></>
                                }))
                            }
                            </>
                        </div>
                        if (item.type === 'add') return <div className={styles.form__Item}>
                            <AddComponent 
                                label={item.label}
                                stateName={item.stateName}
                                handleUpdateItem={handleUserInfoChange}
                                placeholder={item.placeholder}
                                value={userInfo[`${item.stateName}`]}
                            />
                        </div>
                        return <></>
                    }))
                }
                <div className={styles.new__Configuration__Btns__Container}>
                    <button disabled={btnDisabled} className={styles.outline__Btn} onClick={() => handleUpdateFormPage('decrease')}>Back</button>
                    <button disabled={btnDisabled} className={styles.green__Btn} onClick={() => handleUpdateFormPage('increase')}>
                        {
                            currentFormPage === 2 ? loading ? 'Please wait...' : 'Complete' 
                            : 
                            loading ? 'Please wait...' :'Next'
                        }
                    </button>
                </div>
            </div>
        }
    </PageLayout>
}

export default NewAccountSetupPage;

const acceptedAccountTypes = ['talent', 'scout'];

const errorMessages = {
    age: "Please select a age category",
    nationality: "Please select a nationality",
    city: "Please select a city",
    height: "Please enter a height",
    weight: "Please enter a weight",
    country: "Please select a country",
    userSport: "Please select a sport",
    position: "Please select a position",
    preferredFoot: "Please select a preferred foot",
    club: "Please enter a club/academy",
    about: "Please enter a short summary of who you are",
    dateOfBirth: "Please enter your date of birth",
}

const pageElements = [
    {
        accountType: 'talent',
        elements: [
            {
                pageNumber: 1,
                formElements: [
                    {
                        type: 'image',
                        label: 'Photo',
                        stateName: 'profilePhoto',
                    },
                    {
                        type: 'text',
                        placeholder: 'Full name',
                        // stateName: 'name',
                        readOnly: true,
                    },
                    {
                        type: 'dropdown',
                        label: 'Gender',
                        required: true,
                        stateName: 'gender',
                        options: ['Male', 'Female', 'Prefer not to say']
                    },
                    {
                        type: 'dropdown',
                        label: 'Age',
                        required: true,
                        stateName: 'age',
                        options: ['9-12', '12-17', '18-26']
                    },
                    {
                        type: 'date',
                        label: 'Date of Birth',
                        required: true,
                        stateName: 'dateOfBirth',
                        labelPresent: true,
                    },
                    {
                        type: 'dropdown',
                        label: 'Nationality',
                        required: true,
                        stateName: 'nationality',
                        options: ['Nigerian']
                    },
                    {
                        type: 'dropdown',
                        label: 'City of residence',
                        required: true,
                        stateName: 'city',
                        options: citiesInNigeria.sort((a, b) => a.localeCompare(b)),
                    },
                    {
                        type: 'parent',
                        children: [
                            {
                                type: 'text',
                                placeholder: 'Height (in m)',
                                required: true,
                                stateName: 'height',
                            },
                            {
                                type: 'text',
                                placeholder: 'Weight (in kg)',
                                required: true,
                                stateName: 'weight',
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Country of residence',
                        required: true,
                        stateName: 'country',
                        options: countriesInTheWorld.sort((a, b) => a.localeCompare(b)),
                    },
                ]
            },
            {
                pageNumber: 2,
                formElements: [
                    {
                        type: 'dropdown',
                        label: 'Sport',
                        required: true,
                        stateName: 'userSport',
                        options: ['Football']
                    },
                    {
                        type: 'parent',
                        children: [
                            {
                                type: 'dropdown',
                                label: 'Position',
                                required: true,
                                stateName: 'position',
                                options: ['Goalkeeper', 'Defender', 'Midfielder', 'Striker']
                            },
                            {
                                type: 'dropdown',
                                label: 'Preferred foot',
                                required: true,
                                optionalRequired: true,
                                stateName: 'preferredFoot',
                                options: ['Left', 'Right']
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Style of play',
                        stateName: 'styleOfPlay',
                        options: ['Conservative', 'Defensive', 'Attacking']
                    },
                    {
                        type: 'text',
                        placeholder: 'Academy/Club',
                        stateName: 'club'
                    },
                    {
                        type: 'add',
                        label: 'Awards/Achievements',
                        stateName: 'awards',
                        placeholder: 'e.g. Overall best in Sports 2020',
                    },
                    {
                        type: 'add',
                        label: 'Social',
                        stateName: 'social',
                        placeholder: 'e.g. https://ng.linkedin.com/in/your-username',
                    },
                    {
                        type: 'text',
                        label: 'About you',
                        required: true,
                        stateName: 'about',
                        labelPresent: true,
                        placeholder: "e.g, I'm a young talented midfielder with fast and energetic performance"
                    },
                ]
            }
        ]
    },
    {
        accountType: 'scout',
        elements: [
            {
                pageNumber: 1,
                formElements: [
                    {
                        type: 'image',
                        label: 'Photo',
                    },
                    {
                        type: 'text',
                        placeholder: 'Full name',
                        // stateName: 'name',
                        readOnly: true,
                    },
                    {
                        type: 'dropdown',
                        label: 'Gender',
                        required: true,
                        stateName: 'gender',
                        options: ['Male', 'Female', 'Prefer not to say']
                    },
                    {
                        type: 'dropdown',
                        label: 'Age',
                        required: true,
                        stateName: 'age',
                        options: ['18-24','25-40','40-60','60-80']
                    },
                    {
                        type: 'date',
                        label: 'Date of Birth',
                        required: true,
                        stateName: 'dateOfBirth',
                        labelPresent: true,
                    },  
                    {
                        type: 'dropdown',
                        label: 'Country of residence',
                        required: true,
                        stateName: 'country',
                        options: countriesInTheWorld.sort((a, b) => a.localeCompare(b)),
                    },
                    {
                        type: 'dropdown',
                        label: 'Nationality',
                        required: true,
                        stateName: 'nationality',
                        options: countriesInTheWorldWIthNationalities.map(country => country.nationality).sort((a, b) => a.localeCompare(b))
                    },
                ]
            },
            {
                pageNumber: 2,
                formElements: [
                    {
                        type: 'dropdown',
                        label: 'Sport',
                        required: true,
                        stateName: 'userSport',
                        options: ['Football']
                    },
                    {
                        type: 'text',
                        placeholder: 'Club',
                        stateName: 'club',
                        required: true,
                    },
                    {
                        type: 'dropdown',
                        label: 'Talents you are looking for?',
                        stateName: 'talentsWatch',
                        options: ['Goalkeeper', 'Defender', 'Midfielder', 'Striker']
                    },
                    {
                        type: 'add',
                        label: 'Social',
                        stateName: 'social',
                        placeholder: 'e.g. https://ng.linkedin.com/in/your-username',
                    },
                    {
                        type: 'text',
                        label: 'About you',
                        required: true,
                        stateName: 'about',
                        labelPresent: true,
                        placeholder: "e.g, I'm an award-winning scout looking for talented players"
                    },
                ]
            },
        ]
    },
]
