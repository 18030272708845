import React from 'react'
import ResetPasswordComponent from "../../components/auth/reset-password/reset-password.component";

const ResetPasswordPage = () => {
    return (
        <div>
            <ResetPasswordComponent />
        </div>
    )
}

export default ResetPasswordPage
