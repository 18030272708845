import { IoMdClose } from 'react-icons/io';
import styles from './style.module.css';
import { MdOutlineWavingHand } from 'react-icons/md';


const WaveModalInfo = ({ closeModal }) => {
    return <div className={styles.modal__Overlay}>
        <div className={styles.modal__Container}>
            <div className={styles.close__Container} onClick={closeModal}>
                <IoMdClose />
            </div>
            <div className={styles.wave__Info__Wrapper}>
                <div className={styles.wave__Icon__Container}>
                    <MdOutlineWavingHand className={styles.wave__Icon} />
                </div>
                <div className={styles.content}>
                    <h4><b>Send a 'Wave'</b></h4>
                    <span>
                        <b>Wave</b> is a feature that lets you start a conversation with a scout or agent. Once you click "Wave", the scout or agent receives a message to either accept or decline your invitation.
                    </span>
                    <button className={styles.btn} onClick={() => closeModal()}>
                        <span>Send wave</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default WaveModalInfo;
