import React, { useState, useEffect, useRef, useContext } from "react";
import "./PostForm.css";
import EmojiPicker from "emoji-picker-react";
import SVGs from "../imagess/svg";
import { postUpdate } from "../../../services/userService";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-toastify";

const PostForm = () => {

    const [grow, setGrow] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [inputStr, setInputStr] = useState("");
    const textRef = useRef(null);
    const emojiRef = useRef(null);
    const emojiSvg = useRef(null);
    const [images, setImages] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(null);

    const [postContent,setPostContent] = useState("")
    const [postFiles,setPostFiles] = useState([])

    const emojiClicked = (emojiObject) => {
        textRef.current.focus();
        const start = postContent.substring(0, textRef.current.selectionStart);
        const end = postContent.substring(textRef.current.selectionStart);
        const newTxt = start + emojiObject.emoji + end;
        // setInputStr(newTxt);
        setPostContent( newTxt)
        setCursorPosition(start.length + emojiObject.emoji.length);
        setShowPicker(false);
    }

    const handleEmoji = (e) => {
        if (textRef.current && showPicker) textRef.current.focus();
        else textRef.current.blur();
        // if(emojiRef.current && emojiSvg.current) {
        //     if(!emojiRef.current.contains(e.target) && !emojiSvg.current.contains(e.target)) {
        //         return setShowPicker(false);
        //     }
        // }
        setShowPicker(!showPicker);
    }

    const handleChange = (e) => setPostContent(e.target.value);


    const filterImages = (idx) => {
        const newFiles = postFiles.filter((val, index) => index !== idx);
        setPostFiles(newFiles)
    }

    useEffect(() => {
        if (textRef.current) textRef.current.selectionEnd = cursorPosition
    }, [cursorPosition]);

    const { userTokenDetails, posts, setPosts, user } = useContext(UserContext);
    const [loading, setLoading] = useState(false)

    const handleFileUpload = (e) => {
       const file= e.target.files[0];
    //  if((!file.type.startsWith('video')) || (!file.type.startsWith('image'))){
    //     file=null
    //      return toast.error('Upload only videos or images!')}
        const fileSize = file.size / 2024 / 2024
        if (postFiles.length === 3) return toast.error('You can only upload 3 files!')
        if (fileSize > 20) return toast.error('File should not be more than 20mb!')
            setPostFiles([
                ...postFiles, {
                    type: file.type, file
                }
            ])
    }

    const handlePostUpdate = () => {
        if (!postContent || !userTokenDetails?.token || loading ) return;

        setLoading(true)
        const form = new FormData()
        form.append("content", postContent)
        if (postFiles.length > 0) {
            postFiles.map(value => {
                form.append("images", value.file)
            })
        }
        postUpdate(form, userTokenDetails).then(res => {
            console.log(res)
            setLoading(false)
            setPostContent("")
            setPostFiles([])
            setPosts([{ ...res.data, user },
            ...posts])
            toast.success("Posted an update!")
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast.err(err.response.data)
        })
    }
    return (
        <div className="postForm">
        {loading&&
        <>
        <div className="spinner-border text-success" role="status">
<span className="src-only"></span>
        </div>
<br/></>}
            <span className="pF__Big">Post an Update</span>
            <span className="pF__Small">
                Sharing an update is a quick way to get your profile trending
            </span>
            <div className="pF__Main">
                <div className="pF__Input">
                    <textarea placeholder="Enter Post"
                        disabled={loading}
                        // style={{height: grow ? "150px" : "35px"}}
                        onFocus={() => setGrow(true)}
                        onBlur={() => setGrow(false)}
                        value={postContent} ref={textRef}
                        onChange={handleChange} />
                    <div className="pF__Icons">
                        <span>Aa</span>
                        <div>
                            <label htmlFor="img-input">{SVGs.imgIcon}</label>
                            <input id="img-input" type="file"
                                onChange={handleFileUpload} />
                        </div>
                        <div onClick={handleEmoji} ref={emojiSvg}>{SVGs.emoji}</div>
                        <div onClick={handlePostUpdate}>{SVGs.send}</div>
                    </div>
                </div>
                {postFiles.length > 0 && <div className="pF__Imgs">
                    <div>
                        {postFiles.map((val, idx) => (
                            <div className="pF__Img__Div" key={`pF__Img-${idx}`}>
                            {val.type.includes('video') ? <video muted src={URL.createObjectURL(val.file)}/>: <img src={URL.createObjectURL(val.file)} />}
                                <div className="pF__Img__Cancel"
                                    onClick={() => filterImages(idx)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="x" style={{ color: "black" }}>
                                        <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {showPicker && <div className="emoji__Div">
                    <EmojiPicker onEmojiClick={emojiClicked} />
                </div>}
            </div>
        </div>
    )
}

export default PostForm;
