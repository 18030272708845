import React,{createContext,useEffect,useReducer,useState} from 'react';
import { toast } from 'react-toastify';
import {decodeUserToken, getUserMessages, getUserProfile} from "../services/userService";
export const UserContext  = createContext();


export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE="USER_PROFILE"
export const sportspadiUserToken = "sportspadiUserToken";
export const LOGOUT_USER = "LOGOUT_USER";

function userReducer(state,action){
switch(action.type){
    case USER_TOKEN:
        localStorage.setItem(sportspadiUserToken,action.payload)
    return decodeUserToken(action.payload);
    case USER_PROFILE:
    return action.payload;
    case LOGOUT_USER:
        localStorage.removeItem(sportspadiUserToken);
        return {};
}
}

function UserContextProvider({children}) {

    //local user info
    const [userTokenDetails,userTokenDetailsDispatch] = useReducer(userReducer,{},function(){
        const token = localStorage.getItem(sportspadiUserToken)
        if(token) return decodeUserToken(token);
        else return null;
    })

    //live user info
    const [user,userDispatch] = useReducer(userReducer,{})
    
  
    const [posts,setPosts] = useState([])
    const [likedPosts,setLikedPosts] = useState([]);


    const [usersFromServer,setUsersFromServer] = useState([])
    const [messages, setMessages] = useState([])
    const [messagesLoading, setMessagesLoading] = useState(true)

    useEffect(()=>{
const token = userTokenDetails?.token;
if(token){
    console.log(token)
    getUserProfile(token,userTokenDetails.accountType).then(res=>{
        console.log(res)
        userDispatch({
            type:USER_PROFILE,
            payload:res.data
        })
    }).catch(console.log)

    getUserMessages(token, userTokenDetails.accountType).then(messageRes => {
        setMessages(messageRes.data)
        setMessagesLoading(false)
    }).catch(err => {
        // console.log(err)
        console.log("Failed to fetch messages")
        setMessagesLoading(false)
    })
}
else{
    console.log("No token!")
}
},[userTokenDetails?.token&&userTokenDetails?.token])

 

    return (
       <UserContext.Provider value={{userTokenDetails,
       userTokenDetailsDispatch,
       user,userDispatch,
       posts,setPosts,
       usersFromServer,setUsersFromServer,
       messages, setMessages,
       messagesLoading,
       likedPosts,setLikedPosts,
       }}>
{children}
       </UserContext.Provider>
    );
}

export default UserContextProvider;