import React, { useContext, useEffect, useState } from "react";
import "./RequestInterview.css";
import { Link } from "react-router-dom";
import imag from "../../imagess/interviewPic.png";
import PageLayout from "../../Layout/PageLayout";
import { UserContext } from "../../../../context/UserContext";
import { getPlayerInterviews, makeDecisionOnCoachInterview } from "../../../../services/userService";
import { toast } from "react-toastify";
import { fromNow } from "../../../../utils/TimeFormatter";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";

const RequestInterview = () => {

    const [show, setShow] = useState(null);
    // const navigate = useNavigate();
    // const interviews = new Array(20).fill({
    //     title: "Interview for John Doe",
    //     image: imag,
    //     desc: "img elements must have an alt prop, either with meaningful text, or an empty string for decorative"
    // });

    const { userTokenDetails } = useContext(UserContext);
    const [interviews, setInterviews] = useState([])


    useEffect(() => {
        if (!userTokenDetails?.token || userTokenDetails?.accountType==="coach") return;
        getPlayerInterviews(userTokenDetails.token).then(res => {
            console.log(res)
            setInterviews(res.data)
        }).catch(err => {
            console.log(err)
            toast.error(err.response.data)
        })
    }, [])

    const [loading, setLoading] = useState({
        one: false,
        two: false
    })

    const handleBtnclick = (accept, interviewId) => {
        if (!userTokenDetails?.token) return;

        setLoading(accept ? { one: true, two: false } : { one: false, two: true })
        makeDecisionOnCoachInterview(accept, userTokenDetails.token, interviewId).then(res => {
            console.log(res)
            setLoading({ one: false, two: false })
            setInterviews(res.data)
            toast.success(accept ? "Accepted interview!" : "Declined interview!")
        }).catch(err => {
            console.log(err)
            setLoading({ one: false, two: false })
            toast.error(err.response.data)
        })
    }
    return (
        <PageLayout>
            <div className="requestInterview">
                <div className="requestInterview__Wrapper">
                    <div className="rI__Wrapper">
                        <h1>{interviews.length === 0 ? "No Interview Requests!" : "Interview Request"}</h1>
                        <div className="requestTalent">
                            <ul className="rT__ul">
                                {interviews.map((interview, idx) => (
                                    <li className="rT__li" key={`rT-${idx}`}>
                                        <div className="rT__Top">
                                            {
                                               interview?.coach?.profilePhoto ?
                                                <img src={interview?.coach?.profilePhoto} alt="user profile" /> :
                                                <AvatarComponent displayName={interview?.coach?.newAccountStructure ? `${interview?.coach?.firstName} ${interview?.coach?.lastName}`: interview?.coach?.name} width={'100px'} height={'100px'} style={{ cursor: 'default' }} />
                                            }
                                            <div className="rT__Toggler__Top"
                                                onClick={() => setShow(idx)}>View</div>
                                        </div>
                                        <div className="rT__Txts">
                                            <span className="rT__Big">{`Interview with ${interview?.coach?.newAccountStructure ? `${interview?.coach?.firstName} ${interview?.coach?.lastName}`: interview?.coach?.name}`}</span>
                                            <div className="rT__Small">{interview?.message}</div>
                                            <span className="rT__Small">When:{interview?.date}</span>
                                            {show === idx && <div className="rt__Bottom">
                                                <Link to="#" className="rT__Accept"
                                                    style={{ textDecoration: "none" }} onClick={interview?.playerAccepted ? () => { } : () => handleBtnclick(true, interview?._id)}>
                                                    {loading.one ? "Please wait..." : "Accept"}
                                                </Link>
                                                <div className="rT__Decline" onClick={() => handleBtnclick(false, interview?._id)}>
                                                    {loading.two ? "Please wait..." : "Decline"}
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="rT__Toggler"
                                            onClick={() => setShow(idx)}>View</div>
                                    </li>
                                ))}
                            </ul>
                            {/* 
                            <ul className="rT__ul">
                                {interviews.map((interview,idx)=>(
                                    <li className="rT__li" key={`rT-${idx}`}>
                                        <div className="rT__Top">
                                            <img src={imag} />
                                            <div className="rT__Toggler__Top"
                                                onClick={() => setShow(idx)}>View</div>
                                        </div>
                                        <div className="rT__Txts">
                                            <span className="rT__Big">{interview.title}</span>
                                            <div className="rT__Small">{interview.desc}</div>
                                            {show === idx && <div className="rt__Bottom">
                                                <Link to="/test/schedule/book" className="rT__Accept"
                                                    style={{ textDecoration: "none" }}>
                                                    Accept
                                                </Link>
                                                <div className="rT__Decline">Decline</div>
                                            </div>}
                                        </div>
                                        <div className="rT__Toggler"
                                            onClick={() => setShow(idx)}>View</div>
                                    </li>
                                )
                                )}
                            </ul> */}

                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default RequestInterview;