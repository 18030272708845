import React from "react";
import "./Start_Assessment.css";


const Start_Assessment = ({ setAssessmentRoute, setRoute }) => {

    return (
        <div className="Intro">
            <div className="sticky__Top">
                <div onClick={() => setRoute("skills")}>
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5759 24.4404L24.6491 28.3117L14.5421 18.3416L4.51111 28.2357L0.562461 24.3416L10.5935 14.4453L0.485352 4.47404L4.41214 0.601562L14.5191 10.5728L24.5501 0.677627L28.4988 4.57281L18.4678 14.4691L28.5759 24.4404Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <h1>Dribbling Assessment</h1>
            <div className="Intro__Dets">
                <div>
                    {/* <svg></svg> */}
                        <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1" 
                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{color: "#A5A4A4"}} fill="#A5A4A4">
                            <title>list_check_fill</title>
                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                            style={{color: "#A5A4A4"}}>
                                <g id="Editor" transform="translate(-720.000000, -48.000000)" fillRule="nonzero"
                                style={{color: "#A5A4A4"}}>
                                    <g id="list_check_fill" transform="translate(720.000000, 48.000000)"
                                    style={{color: "#A5A4A4"}}>
                                        <path style={{color: "#A5A4A4"}}
                                         d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z">

                                        </path>
                                        <path style={{color: "#A5A4A4"}} d="M20,17.5 C20.8284,17.5 21.5,18.1716 21.5,19 C21.5,19.8284 20.8284,20.5 20,20.5 L9,20.5 C8.17157,20.5 7.5,19.8284 7.5,19 C7.5,18.1716 8.17157,17.5 9,17.5 L20,17.5 Z M4.5,17.5 C5.32843,17.5 6,18.1716 6,19 C6,19.8284 5.32843,20.5 4.5,20.5 C3.67157,20.5 3,19.8284 3,19 C3,18.1716 3.67157,17.5 4.5,17.5 Z M20,10.5 C20.8284,10.5 21.5,11.1716 21.5,12 C21.5,12.7796706 20.9050879,13.4204457 20.1444558,13.4931332 L20,13.5 L9,13.5 C8.17157,13.5 7.5,12.8284 7.5,12 C7.5,11.2203294 8.09488554,10.5795543 8.85553954,10.5068668 L9,10.5 L20,10.5 Z M4.5,10.5 C5.32843,10.5 6,11.1716 6,12 C6,12.8284 5.32843,13.5 4.5,13.5 C3.67157,13.5 3,12.8284 3,12 C3,11.1716 3.67157,10.5 4.5,10.5 Z M4.5,3.5 C5.32843,3.5 6,4.17157 6,5 C6,5.82843 5.32843,6.5 4.5,6.5 C3.67157,6.5 3,5.82843 3,5 C3,4.17157 3.67157,3.5 4.5,3.5 Z M20,3.5 C20.8284,3.5 21.5,4.17157 21.5,5 C21.5,5.77969882 20.9050879,6.420449 20.1444558,6.49313345 L20,6.5 L9,6.5 C8.17157,6.5 7.5,5.82843 7.5,5 C7.5,4.22030118 8.09488554,3.579551 8.85553954,3.50686655 L9,3.5 L20,3.5 Z" id="形状" fill="#A5A4A4">

                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    <div className="Intro__Txt">
                        <span>10</span> multiple choice questions
                    </div>
                </div>
                <div>
                    {/* <svg></svg> */}
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                    width="800px" height="800px" viewBox="0 0 220 260" xmlSpace="preserve"
                    style={{color: "#A5A4A4"}} fill="#A5A4A4">
                        <path d="M123,43V28h16V2H81v26h16v15C43.696,49.44,2,95.241,2,150.253C2,209.665,50.335,258,109.747,258
                            s107.747-48.335,107.747-107.747C217.494,95.241,176.304,49.44,123,43z M109.747,232C64.671,232,28,195.328,28,150.253
                            c0-45.076,36.671-81.747,81.747-81.747c45.075,0,81.747,36.671,81.747,81.747C191.494,195.328,154.822,232,109.747,232z
                            M161.082,113.062l-32.641,32.641c0.355,1.462,0.563,2.98,0.563,4.551c0,10.635-8.622,19.257-19.257,19.257
                            c-10.635,0-19.257-8.622-19.257-19.257c0-10.635,8.622-19.257,19.257-19.257c1.571,0,3.09,0.209,4.551,0.564l32.641-32.641
                            L161.082,113.062z"/>
                    </svg>
                    <div className="Intro__Txt">
                        <span>1.5</span> minutes per question
                    </div>
                </div>
                <div>
                    {/* <svg></svg> */}
                    <svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" style={{color: "#A5A4A4"}} fill="#A5A4A4">
                        <title>clipboard-check</title>
                        <path d="M26 4.75h-2c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0h0.75v21.5h-17.5v-21.5h0.75c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-2c-0.69 0-1.25 0.56-1.25 1.25v0 24c0 0.69 0.56 1.25 1.25 1.25h20c0.69-0.001 1.249-0.56 1.25-1.25v-24c-0-0.69-0.56-1.25-1.25-1.25h-0zM11 9.249h10c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-1.137c0.242-0.513 0.385-1.114 0.387-1.748v-0.001c0-2.347-1.903-4.25-4.25-4.25s-4.25 1.903-4.25 4.25v0c0.002 0.635 0.145 1.236 0.398 1.775l-0.011-0.026h-1.137c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0zM14.25 5c0-0 0-0.001 0-0.001 0-0.966 0.784-1.75 1.75-1.75s1.75 0.784 1.75 1.75c0 0.966-0.784 1.75-1.75 1.75v0c-0.966-0.001-1.748-0.783-1.75-1.749v-0zM19.957 13.156l-6.44 7.039-1.516-1.506c-0.226-0.223-0.536-0.361-0.878-0.361-0.69 0-1.25 0.56-1.25 1.25 0 0.345 0.14 0.658 0.366 0.884v0l2.44 2.424 0.022 0.015 0.015 0.021c0.074 0.061 0.159 0.114 0.25 0.156l0.007 0.003c0.037 0.026 0.079 0.053 0.123 0.077l0.007 0.003c0.135 0.056 0.292 0.089 0.457 0.089 0.175 0 0.341-0.037 0.491-0.103l-0.008 0.003c0.053-0.031 0.098-0.061 0.14-0.094l-0.003 0.002c0.102-0.050 0.189-0.11 0.268-0.179l-0.001 0.001 0.015-0.023 0.020-0.014 7.318-8c0.203-0.222 0.328-0.518 0.328-0.844 0-0.69-0.559-1.25-1.25-1.25-0.365 0-0.693 0.156-0.921 0.405l-0.001 0.001z"></path>
                    </svg>
                    <div className="Intro__Txt">
                        Score <span>70%</span> to earn a badge
                    </div>
                </div>
            </div>
            <div className="Intro__Info">
                <h3>Before you start</h3>
                <ul>
                    <li>You must complete this assessment in one sitting</li>
                    <li>Make sure your internet is reliable</li>
                    <li>Your result is visible to anyone one who views your profile</li>
                    <li>Be sure you are ready as you cannot retake this assessment</li>
                </ul>
            </div>
            <div className="Intro__Button">
                <div onClick={() => setAssessmentRoute("questions")}>Start Now</div>
            </div>
        </div>
    )
}

export default Start_Assessment;