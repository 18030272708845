import React from "react";
import "./Results.css";
import image from "../svgs/result-img2.jpg";

const Results = ({ questions, score, setRoute }) => {

    return (
        <div className="Results">
            <div>
                <img src={image} />
                <span className="span">Your Score For Dribbling Assessment is :</span>
                <span className="span score"> <span>{score}</span> / {questions.length}</span>
            </div>
            <div>
                <span className="span">Congrats</span>
                <div className="result__Buttons"
                onClick={() => setRoute("skills")}>Continue</div>
            </div>
            {/* <div>
                <span className="span"></span>
                <div className="result__Buttons"
                onClick={() => setResult(false)}>Retake</div>
            </div> */}
        </div>
    )
}

export default Results