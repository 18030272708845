import React, { useState } from "react";
import "./TalentPool.css";
import PageLayout from "../Layout/PageLayout";
import SideComponent from "../SideComponent/SideComponent";
import imag from "../imagess/interviewPic.png";
import SVGs from "../imagess/svg";

const TalentPool = () => {

    // const [show, setShow] = useState(null);
    const interviews = new Array(20).fill({
        title: "John Doe",
        image: imag,
        desc: "Talented Football Player also known as Becks Lagos Nigeria"
    });
    return (
        <PageLayout>
            <div className="talentPool">
                <SideComponent />
            </div>
        </PageLayout>
    )
}

export default TalentPool;