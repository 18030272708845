import React from "react";
import "./Images.css";

const Images = ({ images, setImagesData }) => {
    console.log("images=>",images[0], typeof images[0]);

    const getImgUrl = (image) => {
        if(typeof image === "object") return URL.createObjectURL(image);
        else return image;
    };

    if(images.length === 1) {
        return (
            <div className="Images One">
                <div className="One">
                    <img src={getImgUrl(images[0])} 
                    onClick={() => setImagesData({id: 0, images})} />
                </div>
            </div>
        )
    } else if(images.length === 2) {
        return (
            <div className="Images notOne">
            <div className="plain">
                <img src={getImgUrl(images[0])}
                onClick={() => setImagesData({id: 0, images})} />
            </div>
            <div className="plain">
                <img src={getImgUrl(images[1])} 
                onClick={() => setImagesData({id: 1, images})} />
            </div>
            </div>
        )
    } else if(images.length === 3) {
        return (
            <div className="Images notOne">
                <div className="plain">
                    <img src={getImgUrl(images[0])} 
                    onClick={() => setImagesData({id: 0, images})} />
                </div>
                <div className="columnFlex">
                    <img src={getImgUrl(images[1])} 
                    onClick={() => setImagesData({id: 1, images})} />
                    <img src={getImgUrl(images[2])} 
                    onClick={() => setImagesData({id: 2, images})} />
                </div>
            </div>
        )
    } else if(images.length === 4) {
        return (
            <div className="Images notOne">
                <div className="columnFlex">
                    <img src={getImgUrl(images[0])} 
                    onClick={() => setImagesData({id: 0, images})} />
                    <img src={getImgUrl(images[1])} 
                    onClick={() => setImagesData({id: 1, images})} />
                </div>
                <div className="columnFlex">
                    <img src={getImgUrl(images[2])} 
                    onClick={() => setImagesData({id: 2, images})} />
                    <img src={getImgUrl(images[3])} 
                    onClick={() => setImagesData({id: 3, images})} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="Images notOne">
                <div className="columnFlex">
                    <img src={getImgUrl(images[0])} 
                    onClick={() => setImagesData({id: 0, images})} />
                    <img src={getImgUrl(images[1])} 
                    onClick={() => setImagesData({id: 1, images})} />
                </div>
                <div className="columnFlex">
                    <img src={getImgUrl(images[2])} 
                    onClick={() => setImagesData({id: 2, images})} />
                    <div className="lst__Img">
                        <img src={getImgUrl(images[3])} 
                        onClick={() => setImagesData({id: 3, images})} />
                        <div className="absoluteDiv">+{images.length-4}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Images;