import React, { useState, useContext,useEffect } from "react";
import './register.styles.css'
import {
  Row,
  Container,
  Col,
  Image,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import player from "../../../media/secondBall.png";
import logo from "../../../media/sportspadi-logo.png";
import icon1 from "../../../media/facebookicon.png";
import icon2 from "../../../media/instagramicon.png";
import icon3 from "../../../media/icons8-linkedin-48 (1).png";
import {createStructuredSelector} from "reselect";
import {selectCurrentUserLoadingStatus} from "../../../redux/user/user.selectors";
import {signUpStart} from "../../../redux/user/user.actions";
import {connect} from "react-redux";
import ButtonSpinner from "../../spinners/button-spinner.component";
import history from "../../../history";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import { registerUser } from "../../../services/userService";
import { UserContext } from "../../../context/UserContext";

const RegisterComponent = () => {
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
    });

    const navigate = useNavigate()
    

    const { firstName, lastName, email, phone, password, confirmPassword } = user;
    const [loading,setLoading] = useState(false);
    const {userTokenDetails} = useContext(UserContext);

    const handleChange = ({target:{name:inputName,value:inputValue}}) => setUser({ ...user, [inputName]: inputValue });

    useEffect(()=>{
      if(userTokenDetails?.accountType&&userTokenDetails?.accountConfigured) return navigate("/posts")
          },[]) 

    const handleSubmit = async event => {
        event.preventDefault();
setLoading(true)

       if(password !== confirmPassword){
        setLoading(false)
        return toast.error("Passwords don't match!");
       }

       registerUser({firstName,lastName,email,password,phone}).then(res=>{
        console.log(res)
        setLoading(false)
        setUser({ firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''})
        toast.success('Account created successfully!, check your mail to activate your account!')
       }).catch(err=>{
        console.log(err)
        setLoading(false)
        toast.error(err.response.data)
       })
    };

  



  return (
    <>
      <div className="signup">
        <Container fluid>
          <Row>
            <Col md={5}>
              <div className="signup1">
                <Image
                  fluid
                  src={player}
                  style={{
                    marginLeft: "1rem",
                    marginTop: "8rem",
                  }}
                />
                <div />
                <h5 style={{ textAlign: "center", fontFamily: "Flutter" }}>
                  AI-powered Sports Talent Pool Platform{" "}
                  <span style={{ display: "block" }}>
                  
                  </span>
                </h5>
              </div>
            </Col>
            <Col>
              <div className="signup2">
      
                <div className="dashboard-navbar-1"
                  onClick={() => navigate('/')}>
                  
                    <img src={logo} alt="Sportspadi Logo" />
                </div>
                      
                <h2 style={{ color: "#00a01e", fontFamily: "Flutter" }}>
                  Create Account
                </h2>
                <Form
                  className="form-group col-lg-8"
                  noValidate
                  style={{ fontFamily: "Flutter" }}
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1.5rem",
                        height: "3rem",
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1.5rem",
                        height: "3rem",
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="validationEmail"
                    novalidate
                  >
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1.5rem",
                        height: "3rem",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="validationEmail"
                    novalidate
                  >
                    <Form.Control
                      required
                      type="tel"
                      placeholder="Phone"
                      name="phone"
                      value={phone}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1.5rem",
                        height: "3rem",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="validationCustomPassword"
                  >
                    <Form.Control
                      required
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1rem",
                        height: "3rem",
                      }}
                    />
                    <Form.Control
                      required
                      type="password"
                      placeholder="Verify Password"
                      value={confirmPassword}
                      name="confirmPassword"
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#BFE7C7",
                        borderRadius: "20px",
                        marginTop: "1.5rem",
                        height: "3rem",
                      }}
                    />

                    <Button
                      className="registerbtn"
                      style={{
                        width: "100%",
                        fontWeight: 400,
                        marginTop: "1.5rem",
                        fontSize: "20px",
                        padding: "7px 5px",
                        borderRadius: "20px",
                        color: "#fff",
                        backgroundColor: "#00A01E",
                        border: "0",
                      }}
                      variant="outline-primary custon"
                      type="submit"
                    >
                        {loading?(<ButtonSpinner />): 'Register'}
                    </Button>
                  </Form.Group>
                </Form>
                {/* <h4>or Sign Up Using</h4> */}
                {/* <div className="socialicon">
                  <a href="/#" style={{ marginRight: "0.5rem" }}>
                    <Image src={icon1} />
                  </a>
                  <a href="/#" style={{ marginLeft: "0.5rem" }}>
                    <Image src={icon3} />
                  </a>
                </div> */}
                <h4
                  style={{
                    marginTop: "1rem",
                    fontFamily: "Flutter",
                  }}
                >
                  Already have an account? <Link to='/login' >
                    Login
                </Link>{" "}
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RegisterComponent;
