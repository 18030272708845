import React, { useState } from "react";
import "./Skills.css";
import Assessment from "./Assessment";
import trash from "./svgs/trash.svg";

const UserSkills = ({ hideSkillsBtns }) => {

    const dummy = [
        {name: "Heading", assessment: 4},
        {
            name: "Dribbling"
        }
    ];
    
    const initial = new Array(1).fill("Dribblng");
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [skillsSet, setSkillsSet] = useState(initial);
    const [skills, setSkills] = useState(dummy);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [assessmentId, setAssessmentId] = useState(null);
    const [route, setRoute] = useState("skills");

    const handleDelete = (val, id) => {
        setDeleteLoading(true);
        const newSkills = skills.filter((skill, idx) => idx!==id);
        //send to backend
        setSkills(newSkills);
        setDeleteLoading(false);
    }

    const filterSkills = (val, id) => {
        setSelectedSkills(prev => prev.filter((skill, idx) => idx!==id));
        setSkillsSet(prev => [...prev, val]);
    }
    const handleSelect = (val, id) => {
        setSelectedSkills([...selectedSkills, val]);
        setSkillsSet(prev => prev.filter((skillset, idx) => idx!==id));
    }
    const handleSave = () => {
        if(selectedSkills.length === 0) return;
        //backend
        let addSkills = [];
        for(var i=0;i<selectedSkills.length;i++) {
            addSkills.push({
                name: selectedSkills[i],
                assessment: null
            });
        }
        setSkills([...skills,...addSkills]);
        setRoute("skills");
    }
    const handleAssessment = (id) => {
        setAssessmentId(id);
        setRoute("assessment");
    }

    const SkillsMain = () => (
        <div className="Skills__Wrapper">
            <div className="Skills__Top">
                <h3>{`Skills (${skills.length})`}</h3>
                <div onClick={() => setRoute("choose_skill")}>
                    { hideSkillsBtns ? <></> :
                    <svg width="800px" height="800px" viewBox="0 0 24 24" 
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5V19M5 12H19" stroke="#000000" strokeWidth="2" 
                        strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                </div>
            </div>
            <div className="Skills__Main">
                <ul>
                    {skills.map((val, idx) => (
                        <li key={`skills-${idx}`} className="Skills__List">
                            <div className="sL__Top">
                                <h3>{val.name}</h3>
                                { !hideSkillsBtns ? <img src={trash} /> : <></> }
                            </div>
                            {val.assessment && <div className="sL__Main">
                            <svg fill="#A5A4A4" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            style={{color: "#A5A4A4"}}>
                                <title>clipboard-check</title>
                                <path d="M26 4.75h-2c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0h0.75v21.5h-17.5v-21.5h0.75c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-2c-0.69 0-1.25 0.56-1.25 1.25v0 24c0 0.69 0.56 1.25 1.25 1.25h20c0.69-0.001 1.249-0.56 1.25-1.25v-24c-0-0.69-0.56-1.25-1.25-1.25h-0zM11 9.249h10c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-1.137c0.242-0.513 0.385-1.114 0.387-1.748v-0.001c0-2.347-1.903-4.25-4.25-4.25s-4.25 1.903-4.25 4.25v0c0.002 0.635 0.145 1.236 0.398 1.775l-0.011-0.026h-1.137c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0zM14.25 5c0-0 0-0.001 0-0.001 0-0.966 0.784-1.75 1.75-1.75s1.75 0.784 1.75 1.75c0 0.966-0.784 1.75-1.75 1.75v0c-0.966-0.001-1.748-0.783-1.75-1.749v-0zM19.957 13.156l-6.44 7.039-1.516-1.506c-0.226-0.223-0.536-0.361-0.878-0.361-0.69 0-1.25 0.56-1.25 1.25 0 0.345 0.14 0.658 0.366 0.884v0l2.44 2.424 0.022 0.015 0.015 0.021c0.074 0.061 0.159 0.114 0.25 0.156l0.007 0.003c0.037 0.026 0.079 0.053 0.123 0.077l0.007 0.003c0.135 0.056 0.292 0.089 0.457 0.089 0.175 0 0.341-0.037 0.491-0.103l-0.008 0.003c0.053-0.031 0.098-0.061 0.14-0.094l-0.003 0.002c0.102-0.050 0.189-0.11 0.268-0.179l-0.001 0.001 0.015-0.023 0.020-0.014 7.318-8c0.203-0.222 0.328-0.518 0.328-0.844 0-0.69-0.559-1.25-1.25-1.25-0.365 0-0.693 0.156-0.921 0.405l-0.001 0.001z"></path>
                            </svg>
                                <span>
                                    Assessment Score : 
                                    <span style={{color: val.assessment>=3?"#00A01E":"red"}}>
                                        {val.assessment}
                                    </span> / 5
                                </span>
                            </div>}
                            {!val.assessment && !hideSkillsBtns && <div className="sL__Button"
                            onClick={() => handleAssessment(idx)}>
                                Take Assessment Now
                            </div>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )

    const ChooseSkill = () => (
        <div className="Skills__Wrapper">
            <div className="Choose__Top">
                <h3>Choose Your Skills</h3>
                <div className="cT__Close"
                onClick={() => setRoute("skills")}>
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5759 24.4404L24.6491 28.3117L14.5421 18.3416L4.51111 28.2357L0.562461 24.3416L10.5935 14.4453L0.485352 4.47404L4.41214 0.601562L14.5191 10.5728L24.5501 0.677627L28.4988 4.57281L18.4678 14.4691L28.5759 24.4404Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="Skills__Selected">
                {selectedSkills.map((val, idx) => (
                    <div key={`sS-${idx}`}>
                        <span>{val}</span>
                        {/* x-icon */}
                        <div className="sS__Cancel"
                        onClick={() => filterSkills(val, idx)}>
                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.5759 24.4404L24.6491 28.3117L14.5421 18.3416L4.51111 28.2357L0.562461 24.3416L10.5935 14.4453L0.485352 4.47404L4.41214 0.601562L14.5191 10.5728L24.5501 0.677627L28.4988 4.57281L18.4678 14.4691L28.5759 24.4404Z" 
                                fill="#A5A4A4"/>
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
            <ul className="Skills__Select">
                {skillsSet.map((val, idx) => (
                    <li key={`skillSet-${idx}`}
                    onClick={() => handleSelect(val, idx)}>{val}</li>
                ))}
            </ul>
            <div className="Skills__Bottom">
                <div className="Skills__Save"
                style={{
                    backgroundColor: selectedSkills.length===0?"#A5A4A4":"#00A01E",
                    cursor: selectedSkills.length>0 && "pointer"
                }}
                onClick={handleSave}>Save</div>
            </div>
        </div>
    )

    return (
        <div className="Skills">
            {route==="skills" && <SkillsMain />}
            {route==="choose_skill" && <ChooseSkill />}
            {route==="assessment" && 
                <Assessment setSkills={setSkills}
                id={assessmentId} setRoute={setRoute} />
            }
        </div>
    )
}

export default UserSkills;