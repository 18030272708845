import React, { useState } from "react";
import "./Skills.css";
import Assessment from "./Assessment";
import trash from "./svgs/trash.svg";

const Skills = () => {

    const skills = [
        {name: "Heading", assessment: 4},
        {
            name: "Dribbling"
        }
    ];
    


    return (
        <div className="Skills">
            <div className="Skills__Wrapper">
                <div className="Skills__Top">
                    <h3>{`Skills (${skills.length})`}</h3>
                    {/* <div onClick={() => setRoute("choose_skill")}>
                        <svg width="800px" height="800px" viewBox="0 0 24 24" 
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5V19M5 12H19" stroke="#000000" strokeWidth="2" 
                            strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div> */}
                </div>
                <div className="Skills__Main">
                    <ul>
                        {skills.map((val, idx) => (
                            <li key={`skills-${idx}`} className="Skills__List">
                                <div className="sL__Top">
                                    <h3>{val.name}</h3>
                                    {/* <img src={trash} /> */}
                                </div>
                                {val.assessment && <div className="sL__Main">
                                    <svg fill="#A5A4A4" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    style={{color: "#A5A4A4"}}>
                                        <title>clipboard-check</title>
                                        <path d="M26 4.75h-2c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0h0.75v21.5h-17.5v-21.5h0.75c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-2c-0.69 0-1.25 0.56-1.25 1.25v0 24c0 0.69 0.56 1.25 1.25 1.25h20c0.69-0.001 1.249-0.56 1.25-1.25v-24c-0-0.69-0.56-1.25-1.25-1.25h-0zM11 9.249h10c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0h-1.137c0.242-0.513 0.385-1.114 0.387-1.748v-0.001c0-2.347-1.903-4.25-4.25-4.25s-4.25 1.903-4.25 4.25v0c0.002 0.635 0.145 1.236 0.398 1.775l-0.011-0.026h-1.137c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0zM14.25 5c0-0 0-0.001 0-0.001 0-0.966 0.784-1.75 1.75-1.75s1.75 0.784 1.75 1.75c0 0.966-0.784 1.75-1.75 1.75v0c-0.966-0.001-1.748-0.783-1.75-1.749v-0zM19.957 13.156l-6.44 7.039-1.516-1.506c-0.226-0.223-0.536-0.361-0.878-0.361-0.69 0-1.25 0.56-1.25 1.25 0 0.345 0.14 0.658 0.366 0.884v0l2.44 2.424 0.022 0.015 0.015 0.021c0.074 0.061 0.159 0.114 0.25 0.156l0.007 0.003c0.037 0.026 0.079 0.053 0.123 0.077l0.007 0.003c0.135 0.056 0.292 0.089 0.457 0.089 0.175 0 0.341-0.037 0.491-0.103l-0.008 0.003c0.053-0.031 0.098-0.061 0.14-0.094l-0.003 0.002c0.102-0.050 0.189-0.11 0.268-0.179l-0.001 0.001 0.015-0.023 0.020-0.014 7.318-8c0.203-0.222 0.328-0.518 0.328-0.844 0-0.69-0.559-1.25-1.25-1.25-0.365 0-0.693 0.156-0.921 0.405l-0.001 0.001z"></path>
                                    </svg>
                                    <span>
                                        Assessment Score : 
                                        <span style={{color: val.assessment>=3?"#00A01E":"red"}}>
                                            {val.assessment}
                                        </span> / 5
                                    </span>
                                </div>}
                                {!val.assessment && <div className="sL__Main"
                                style={{marginTop:"5px"}}>
                                    <svg width="800px" height="800px" style={{color: "#A5A4A4"}}
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#A5A4A4" d="M8.827 6.956c2.265.662 5.109-.295 8.172-1.867l.001.079c0 1.3-1.642 2.897-3.248 4.288a3.818 3.818 0 0 0-.752.898v6.726c1.321.372 2.815 2.089 3.827 3.655-.027.088-.043.179-.077.265h-8.5c-.034-.087-.057-.17-.082-.254 1.01-1.57 2.509-3.293 3.833-3.666v-6.729a3.819 3.819 0 0 0-.73-.88 17.898 17.898 0 0 1-2.443-2.515zM17.922 2H20v1h-1.516A5.594 5.594 0 0 1 19 5.319c0 2.15-1.479 4.294-3.545 6.092a1.544 1.544 0 0 0-.62 1.089 1.544 1.544 0 0 0 .62 1.089C17.521 15.387 19 17.53 19 19.68a5.595 5.595 0 0 1-.516 2.32H20v1H5v-1h1.5a5.666 5.666 0 0 1-.5-2.319c0-2.15 1.479-4.294 3.545-6.092a1.544 1.544 0 0 0 .62-1.089 1.544 1.544 0 0 0-.62-1.089C7.479 9.613 6 7.47 6 5.32A5.666 5.666 0 0 1 6.5 3H5V2zm-.545 1H7.624A4.68 4.68 0 0 0 7 5.32c0 1.645 1.137 3.54 3.2 5.336a2.435 2.435 0 0 1 .966 1.844 2.432 2.432 0 0 1-.965 1.843c-2.064 1.797-3.2 3.692-3.2 5.338A4.68 4.68 0 0 0 7.623 22h9.753A4.646 4.646 0 0 0 18 19.68c0-1.645-1.137-3.54-3.2-5.336a2.435 2.435 0 0 1-.966-1.844 2.432 2.432 0 0 1 .965-1.843c2.064-1.797 3.2-3.692 3.2-5.338A4.646 4.646 0 0 0 17.378 3z"/>
                                        <path fill="none" d="M0 0h24v24H0z" style={{color: "#A5A4A4"}} />
                                    </svg>
                                    <span>Pending Assessment</span>
                                </div>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Skills;