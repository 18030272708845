import styles from './style.module.css';


const AvatarComponent = ({ displayName, handleNavIconClick, width, height, style }) => {
    return <div 
        className={styles.nav__User__Avatar} 
        onClick={handleNavIconClick ? () => handleNavIconClick() : () => {}}
        style={{ 
            width: width ? width : '', 
            height: height ? height : '',
            ...style
        }}
    >
        {
            displayName?.split(" ").map(item => item[0]?.toLocaleUpperCase()).join("")
        }
    </div>
}

export default AvatarComponent;
