import React from 'react';
import styles from './styles.module.css';

const SelectComponent = ({ placeholder, stateName, required, options, handleItemUpdate, value }) => {
    return <select className={styles.select__Item} onChange={(e) => handleItemUpdate(stateName, e.target.value)}>
        <option value={"default"}>{`${placeholder} ${required ? '*' : ''}`}</option>
        {
            React.Children.toArray(options.map(option => {
                return <option selected={value === option ? true: false} value={option}>{option}</option>
            }))
        }
    </select>
}

export default SelectComponent;