import PageLayout from "../SportsPadi/Layout/PageLayout";
import styles from './style.module.css';

const ShopKitsPage = () => {
    return <>
        <PageLayout>
            <div className={styles.shop__Kits__Page}>
                <h3>Coming soon</h3>
            </div>
        </PageLayout>
    </>
}

export default ShopKitsPage;