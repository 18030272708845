import React, { useContext, useEffect, useState } from "react";
import SVGs from "../imagess/svg";
import PageLayout from "../Layout/PageLayout";
import "./ProfilePage.css";
import ball from "../imagess/coverPic.png";
import profilePic from "../imagess/playerPic.png";
import { UserContext } from "../../../context/UserContext";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PostComponent from "../Posts/PostComponent/PostComponent";
import Skills from "./Skills/Skills";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { validateURL } from "../../../utils/URLValidator";
import { AiOutlineLink } from "react-icons/ai";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const ProfilePage = () => {

    const { search, state, pathname } = useLocation()
    const navigate = useNavigate()

    const profileRoute = ["Posts", "About", "Media", "Activities"]

    const { user, userTokenDetails, posts } = useContext(UserContext)
    const [userInfo, setUserInfo] = useState({
        _id: "",
        name: "",
        accountType: "",
        dateOfBirth: "",
        city: "",
        nationality: "",
        username: "",
        about: "",
        country: "",
        club: "",
        profilePhoto: "",
        social: [],
        awards: [],
        gender: "",
    })

    const [userPosts, setUserPosts] = useState([])

    const userId = new URLSearchParams(search).get("userId");

    // useEffect(() => {
    //     if (userId && !state?.user?.name) {
    //         navigate("/posts")
    //     }
    // }, [])

    // useEffect(() => {
    //     if (user?.name && !userId) {
    //         setUserInfo(user)
    //     }
    // }, [user?.name])

    // useEffect(() => {
    //     if (state?.user?.name && userId) {
    //         setUserInfo(state.user)
    //     }
    // }, [userId])


    useEffect(()=>{
if(userId && state?.user?.name){
    setUserInfo(state.user)
}else{
    navigate("/posts")
}
    },[])

    useEffect(() => {
        if (userInfo._id) {
            const user_posts = posts?.filter(p => p?.user?._id === userInfo._id);
            setUserPosts(user_posts);
        }
    }, [posts?.length, userInfo._id]);

    const PostsRoute = () => (
        <div className="ppC__Route">
            {userPosts.map(post => <PostComponent post={post} />)}
        </div>
    );
    const SkillsRoute = () => (
        <div className="ppC__Route">
            <Skills />
        </div>
    );
    const navigateTo = (link) => {
        if(!link) return navigate("/profile");
        navigate(`/profile/${link}`);
    }

    const handleCopyUsername = async () => {
        await navigator.clipboard.writeText(window.location.origin + "/" + userInfo.username)
        toast.success("Copied username")
    }

    return (
        <PageLayout>
            <div className="profilePage">
                <div className="profilePage__Content">
                    <div className="pP__Content__Top">
                        <div className="ppC__Top">
                            <img src={ball} />
                            <div className="ppC__Top__Absolute">
                                <div className="ppC__WithPic">
                                    {
                                        userInfo.profilePhoto ?
                                        <img src={userInfo.profilePhoto} alt='user profile' /> :
                                        <AvatarComponent style={{ backgroundColor: '#d3d3d3' }} width={'110px'} height={'110px'} displayName={userInfo.name} />
                                    }
                                    {userTokenDetails?.id !== userInfo._id && userInfo.accountType === "player" && userTokenDetails.accountType !== 'player' ? 
                                    <div className="ppC__Interview"
                                     onClick={()=>navigate(`/schedule/book/${userId}`,{state:{user:userInfo}})}>
                                        {SVGs.interview}
                                        <span>Interview</span>
                                    </div>:null}
                                </div>
                               {userTokenDetails?.id === userInfo._id&&
                                <div className="ppC__EditProfile">Edit Profile</div>}
                            </div>
                        </div>
                        <div className="ppC__Mid">
                            <span className="ppC__Big">{userInfo.name}</span>
                            <div className="ppC__Small">
                            {userInfo.username && userInfo.username.length > 1 ? <p className="username__Item" onClick={() => handleCopyUsername()}><span className="username__Span">{userInfo.username} <FontAwesomeIcon icon={faCopy} /></span></p>  : <></>}
                                {userInfo.about}
                                {/* {userInfo.accountType === "coach" ? "Professional Agent" : "Talented Player"} */}
                                <br />
                                Born: {userInfo.dateOfBirth}
                                <br />
                                {
                                    userInfo?.gender && <>
                                    Gender: {userInfo.gender}
                                    <br />
                                    </>
                                }
                                {userInfo.accountType !== 'coach' && userInfo.city + ", "}{userInfo.nationality}
                                <br />
                                {
                                    userInfo?.social?.length > 0 ?
                                    <div className="user__Links__Social">
                                        <>
                                            {
                                                React.Children.toArray(userInfo?.social?.map(socialLink => {
                                                    return <>
                                                        <a href={socialLink} target={'_blank'} rel={'noreferrer noopener'}>
                                                            <AiOutlineLink />
                                                            <span>
                                                                {
                                                                    validateURL(socialLink) ? 
                                                                    new URL(socialLink).host.split(".").slice(0, -1).join('.') :
                                                                    ''
                                                                }
                                                            </span>
                                                        </a>
                                                    </>
                                                }))
                                            }
                                        </>
                                    </div> : <></>
                                }
                            </div>
                        </div>
                        <ul className="ppC__ul">
                            <li className="ppC__li" style={{
                                color: pathname === "/profile" && "#00A01E",
                                borderBottom: pathname === "/profile" && "1.5px solid #00A01E"
                            }} onClick={()=>navigateTo(null)}>Posts</li>
                            {/* <li className="ppC__li" onClick={()=>navigateTo(null)}>About </li> */}
                            <li className="ppC__li" style={{
                                color: pathname === "/profile/skills" && "#00A01E",
                                borderBottom: pathname === "/profile/skills" && "1.5px solid #00A01E"
                            }} onClick={()=>navigateTo("skills")}>Skills </li>
                            {/* {profileRoute.map((val, idx) => (
                                <li className="ppC__li" key={`ppC-${idx}`}>{val}</li>
                            ))} */}
                        </ul>
                    </div>
                    <Routes>
                        <Route path="/" element={<PostsRoute />} />
                        <Route path="/skills" element={<SkillsRoute />} />
                    </Routes>
                </div>
            </div>
        </PageLayout>
    )
}

export default ProfilePage;