import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ width, height }) => {

    return (
        <div className="loadingSpinner"
        style={{width: width, height: height }}></div>
    )
}

export default LoadingSpinner;