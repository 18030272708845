import styles from './styles.module.css';

const DateComponent = ({ label, placeholder, labelPresent, stateName, inputValue, handleUpdateItem, required }) => {
    return <>
        {
            labelPresent ?
            <p className={styles.label}>{label}{required ? '*' : ''}</p> :
            <></>
        }
        <input 
            value={inputValue} 
            onChange={(e) => handleUpdateItem(stateName, e.target.value)} 
            className={`${styles.input__Item} ${labelPresent ? styles.label__Present : ''}`} 
            type={'date'} 
            placeholder={`${placeholder} ${(!labelPresent && required) ? '*' : ''}`} 
        />  
    </>
}

export default DateComponent;