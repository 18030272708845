import React from 'react'
import NewPasswordFormComponent from "../../components/auth/reset-password/new-password-form.component";

const ChoosePasswordPage = () => {
    return (
        <div>
            <NewPasswordFormComponent />
        </div>
    )
}

export default ChoosePasswordPage
