import React, {useContext, useEffect, useState} from "react";
import {Row, Container, Col, Button, Form} from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import './verifyemail.styles.css'
import ButtonSpinner from "../../spinners/button-spinner.component";
import { useParams,useNavigate} from "react-router-dom";
import { verifyUser } from "../../../services/userService";
import {toast} from "react-toastify";
import {UserContext, USER_TOKEN} from "../../../context/UserContext";
import {use} from "react-router-dom"

const VerifyEmailComponent = () => {
  const {token} = useParams();
  const navigate = useNavigate()

  const [loading,setLoading] = useState(false);

  const {userTokenDetailsDispatch} = useContext(UserContext);

  const handleVerification = () =>  {
    if(!token) return;
    setLoading(true)
    verifyUser(token).then(res=>{
      console.log(res)
      setLoading(false)
      toast.success('Account Verified!')
      userTokenDetailsDispatch({
        type:USER_TOKEN,
        payload:res.headers["auth-token"]
      })
navigate("/select-account")
     }).catch(err=>{
      setLoading(false)
      console.log(err);
    toast.error(err.response.data)
     })}

  useEffect(()=>{
    handleVerification()
  },[])


    const handleSubmit = async (event) => {
        event.preventDefault();
        handleVerification()
    }

  return (
    <div>
      <div className="confirmMail">
        <Container>
          <Row>
            <Col>
              <div className="text-body">
                <h2
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    marginBottom: "3rem",
                    fontSize: "23px",
                    fontFamily: "Flutterwave",
                  }}
                >
                    Verifying your email account
                </h2>
                <p id="paragraph">
                  Thank you for choosing us. Sportspadi gives you the best
                  connection you need to take your career to the{" "}
                  <span style={{ display: "block" }}>next level</span>
                </p>
                <p>
                  Confirming your account will give you full access to Sport
                  spadi and all future notifications will be sent to{" "}
                  <span style={{ display: "block" }}>your email</span>
                </p>
              </div>
                <Form
                    onSubmit={handleSubmit}
                >
                    <Button
                        type='submit'
                        style={{
                            width: "50%",
                            fontWeight: 400,
                            marginTop: "2rem",
                            fontSize: "20px",
                            padding: "7px 5px",
                            borderRadius: "20px",
                            color: "#fff",
                            backgroundColor: "#00A01E",
                            textAlign: "center",
                            fontFamily:'Flutter'
                        }}
                        variant="outline-success custon"
                    >
                        {loading?(<ButtonSpinner />): 'Verify email'}
                    </Button>
                </Form>
              <hr
                style={{
                  borderTop: "1px solid #000",
                  marginTop: "4rem",
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}


export default VerifyEmailComponent;
