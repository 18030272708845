import React, { useEffect, useState } from 'react';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import styles from './styles.module.css';

const AddComponent = ({ label, stateName, handleUpdateItem, placeholder, value, editPage }) => {
    const [items, setItems] = useState([]);
    
    useEffect(() => {
        handleUpdateItem(stateName, items);
    }, [items])

    useEffect(() => {
        if (!value || !Array.isArray(value)) return

        setItems(value);
    }, [])

    const handleAddItemClick = () => {
        const newItems = [...items];
        newItems.push("");

        setItems(newItems);
    }

    const handleRemoveItem = (indexPassed) => {
        const currentItems = [...items];
        const updatedItems = currentItems.filter((item, index) => index !== indexPassed);
        setItems(updatedItems);
    }

    const handleUpdateAddItem = (stateIndex, value) => {
        const currentItems = [...items];

        currentItems[stateIndex] = value;
        setItems(currentItems);
    }

    return <>
        <button className={`${styles.add__Btn} ${editPage ? styles.add__Btn__1 : ''}`} onClick={handleAddItemClick}>
            <span>{label}</span>
            <span>+</span>
        </button>
        <div className={styles.add__Item__Container}>
            {
                React.Children.toArray(items.map((item, index) => {
                    return <div className={styles.add__Item}>
                        <TextInputComponent
                            stateName={index}
                            inputValue={item}
                            placeholder={placeholder}
                            placeholderGrey={true}
                            handleUpdateItem={(stateName, valuePassed) => handleUpdateAddItem(stateName, valuePassed)}
                        />
                        <IoIosRemoveCircleOutline onClick={() => handleRemoveItem(index)} className={styles.icon} />
                    </div>
                }))
            }
        </div>
    </>
}

export default AddComponent;
