import React from 'react'
import VerifyEmailComponent from '../../components/auth/verifyEmail/verify-email.component'

const VerifyEmailPage = () => {
    return (
        <div>
            <VerifyEmailComponent />
        </div>
    )
}

export default VerifyEmailPage
