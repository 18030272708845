import React, { useState, useRef, useEffect, useContext } from "react";
import EmojiPicker from "emoji-picker-react";
import "./fullPostComponent.css";
import SVGs from "../imagess/svg";
import image from "../imagess/playerPic.png";
import PageLayout from "../Layout/PageLayout";
import ImagePage from "../ImagePage/ImagePage";
import Images from "../ImageRenders/Images";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { fromNow } from "../../../utils/TimeFormatter";
import { ReturnVidOrImg } from "../Posts/PostComponent/PostComponent";
import usePagination from "../../../hooks/usePagination";
import PaginateButton from "../../../components/PaginateButton";
import { UserContext } from "../../../context/UserContext";
import { commentPost, getPostComments, getSinglePostForExternalUser, likePost } from "../../../services/userService";
import { toast } from "react-toastify";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import ShareModal from "../../../components/shareModal/ShareModal";

const FullPostComponent = () => {

    const { userTokenDetails, user, posts, likedPosts,setLikedPosts } = useContext(UserContext);
    const rowsPerPage = 1;
    const [page, setPage] = useState(1)

    const [post, setPost] = useState({
        _id: "",
        content: "",
        user: {
            name: "",
            accountType: "",
            profilePhoto: "",
        },
        date: "",
        images: [],
        likes: 0
    })
    const [showImg, setShowImg] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('')
    const [loadingComment, setLoadingComment] = useState(false)
    const textRef = useRef(null);
    const [text, setText] = useState("");
    const [like, setLike] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [loadingLike, setLoadingLike] = useState(false)
    const { postId } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    const [updatedPostLikeCount, setUpdatedPostLikeCount] = useState(0);
    const [showShareModal, setShowShareModal] = useState(false);

    useEffect(() => {
        if (!userTokenDetails?.token || !post._id) return;
        getPostComments(userTokenDetails, post._id).then(res => {
            console.log(res)
            setComments(res.data)
        }).catch(console.log)
    }, [post._id])

    useEffect(() => {

        if (!userTokenDetails) {
            getSinglePostForExternalUser(postId).then(res => {
                setPost(res.data)
            }).catch(err => {
                console.log(err.response ? err.response.data : err.message);
                navigate("/")
            })
            return
        } 
        
        if (posts.length < 1) return

        const foundPost = posts.find(post => post._id === postId);
        if (!foundPost) return navigate("/posts");

        setPost(foundPost)

    }, [userTokenDetails, posts, postId])

    const handleEmoji = (emojiObject) => {
        if (textRef.current && showEmoji) textRef.current.focus();
        else textRef.current.blur();
        setShowEmoji(!showEmoji);
    }

    const handleChange = (e) => {
        setComment(e.target.value);
    }

    const emojiClick = (emojiObject) => {
        setComment(prevInput => prevInput + emojiObject.emoji);
        setShowEmoji(false);
    }

    const handleButtonLike = () => {
        console.log(comment);
        if (!userTokenDetails?.token || loadingLike) return;

        setLoadingLike(true)

        const copyOfLikedPosts = likedPosts.slice();
        let updatedLikedPosts = [];
        const postWasPreviouslyLiked = likedPosts.find(likedPost => likedPost.post === post._id)
        if (postWasPreviouslyLiked) {
            setUpdatedPostLikeCount(post.likes - 1);
            updatedLikedPosts = copyOfLikedPosts.filter(likedPost => likedPost.post !== post._id)
        }
        else {
            setUpdatedPostLikeCount(post.likes + 1);
            updatedLikedPosts = copyOfLikedPosts.slice();
            updatedLikedPosts.push({ user: user._id, post: post._id })
        }

        likePost(userTokenDetails, { postId: post._id }).then(res => {
            console.log(res.data)
            setLoadingLike(false)
            setPost({ ...post, likes: res.data.updatedPostLikes })
            setUpdatedPostLikeCount(res.data.updatedPostLikes)
            setLikedPosts(updatedLikedPosts)
            toast.success(res.data.msg)
        }).catch(err => {
            console.log(err)
            setLoadingLike(false)
            setLikedPosts(updatedLikedPosts)
            toast.error(err.response.data)
        })
    }

    const handleSendComment = () => {
        if (!userTokenDetails?.token || !comment || loadingComment) return;

        setLoadingComment(true)
        commentPost(userTokenDetails, { comment, postId: post._id }).then(res => {
            console.log(res)
            setLoadingComment(false)
            setComments([...comments, { ...res.data, user }])
            setComment('')
            toast.success('Successfully posted update!')
        }).catch(err => {
            console.log(err)
            setLoadingComment(false)
            toast.error(err.response.data)
        })

        setText("");
    }

    const formatDate = (val) => {
        const date = String(val).split(" ");
        return `${date[2]}/${val.getMonth()}/${val.getFullYear()}  ${date[4]}`;
    }

    useEffect(() => {
        if (textRef.current) textRef.current.selectionEnd = cursorPosition;
    }, [cursorPosition]);

    const { range, slice } = usePagination(post.images, page, rowsPerPage);

    return (
        <>
            <PageLayout noCurrentUser={!userTokenDetails ? true : false}>

                <div className={`fullPostComponent ${!userTokenDetails ? 'external' : ''}`}>

                    <div className="fullPostComponent_Content">
                        {
                            !userTokenDetails ? <></> : 
                            <div style={{ width: "calc(100% - 20px)", margin: "0 auto 5px", cursor: "pointer" }} onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={faArrowLeft} fontSize="1.4rem" />
                            </div>
                        }
                        <div className="full__Pc__Top">
                            <div className="full__Pc__Img">
                                {
                                    post.user.profilePhoto ?
                                    <img src={post.user.profilePhoto} alt="user profile" /> :
                                    <AvatarComponent displayName={post.user.newAccountStructure ? `${post.user?.firstName} ${post.user?.lastName}`: post.user.name} height={'60px'} />
                                }
                            </div>
                            <div className="full__Pc__Txts">
                                <span className="full__Pc__Name">{post.user.newAccountStructure ? `${post.user?.firstName} ${post.user?.lastName}`: post.user.name}</span>
                                <span className="full__Pc__Role">{post.user.accountType === "coach" ? "Scout" : "Athlete"}</span>
                                <span className="full__Pc__Time">{fromNow(post.date)}</span>
                            </div>
                        </div>
                        <div className="full__Pc__Main">
                            <span className="full__Pc__Message">
                                {post.content}
                            </span>
                            <div className="full__Pc__Message__Img">
                                {/* <Images images={[image]} /> */}
                                {slice.map(val => (
                                    <ReturnVidOrImg url={val.url} type={val.type} />
                                ))}
                            </div>
                        </div>
                        {post.images.length > 1 && <PaginateButton
                            range={range}
                            slice={slice}
                            setPage={setPage}
                            page={page}
                        />}
                        <div className="full__Pc__fullActions">
                            <div className="fullActions__Icon">
                                <div onClick={!userTokenDetails ? () => toast.info('You need to be logged in to perform this action') : () => handleButtonLike()}>
                                    {!likedPosts.find(likedPost => likedPost.post === post._id) ? <AiOutlineLike color="rgb(160, 219, 170)" /> : <AiFillLike color="#00A01E" />}
                                    <span>{loadingLike ? updatedPostLikeCount : post.likes}</span>
                                </div>
                            </div>
                            <div className="fullActions__Icon"
                                onClick={
                                    !userTokenDetails ? () => toast.info('You need to be logged in to perform this action') : 
                                    () => {
                                        if (textRef.current) textRef.current.focus()
                                    }
                                }>
                                <div>
                                    {SVGs.comment}
                                    <span>{loadingComment ? "Please wait..." : comments.length}</span>
                                </div>
                            </div>
                            <div className="fullActions__Icon share">
                                <div onClick={() => setShowShareModal(!showShareModal)}>
                                    {SVGs.share}
                                    <span>Share</span>
                                </div>
                                { 
                                    showShareModal && 
                                        <ShareModal
                                            title={'post'} 
                                            linkToShare={`${window.location.origin}/post/${post._id}`} 
                                            handleCloseModal={() => setShowShareModal(false)} 
                                        /> 
                                }
                            </div>
                        </div>
                        {/* <div className="spinner-border text-success" role="status">
<span className="src-only"></span>
        </div> */}
                        {comments.length > 0 && <div className="comments">
                            {comments.map((val, idx) => (
                                <div className="comments__Wrapper"
                                    style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <div className="comments__Content" key={`comments-${idx}`}>
                                        {
                                            val?.user?.profilePhoto ?
                                            <img src={val?.user?.profilePhoto} alt="user profile" onClick={val?.user?.username ? () => navigate(`/${val?.user?.username}`) : () => {}}/> :
                                            <AvatarComponent displayName={val?.user?.newAccountStructure ? `${val?.user?.firstName} ${val?.user?.lastName}`: val?.user?.name} width={'40px'} height={'40px'} handleNavIconClick={val?.user?.username ? () => navigate(`/${val?.user?.username}`) : () => {}} />
                                        }
                                        <div>
                                            <div className="comments__Txt">
                                                <span className="comments__Big">{val?.user.newAccountStructure ? `${val?.user?.firstName} ${val?.user?.lastName}`: val?.user?.name}</span>
                                                <span className="comments__Small">{val?.user?.accountType === "coach" ? "scout" : val?.user?.accountType === "player" ? "athlete" : ""}</span>
                                                <span className="comments__Small top">{val?.comment}</span>
                                            </div>
                                            <div className="comments__Details">
                                                <span>{fromNow(val?.date ? val.date : Date.now())}</span>
                                                <div>{SVGs.check}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                    {
                        !userTokenDetails ? <></> : 
                        <div className="fP__Input__Wrapper">
                            <div className="fP__Input">
                                <textarea placeholder="Share your thoughts" value={comment}
                                    onChange={handleChange}
                                    disabled={loadingComment}
                                    ref={textRef} 
                                    readOnly={!userTokenDetails ? true : false}
                                />
                                <div className="fP__Div">
                                    <div className="fP__Icons">
                                        <div onClick={!userTokenDetails ? () => toast.info('You need to be logged in to perform this action') : () => handleEmoji()}>{SVGs.emoji}</div>
                                        <div onClick={!userTokenDetails ? () => toast.info('You need to be logged in to perform this action') : () => handleSendComment()}>{SVGs.send}</div>
                                    </div>
                                </div>
                            </div>
                            {showEmoji && <div className="show__Emoji">
                                <EmojiPicker onEmojiClick={!userTokenDetails ? () => toast.info('You need to be logged in to perform this action') : () => emojiClick()} />
                            </div>}
                        </div>
                    }
                    <div className={`absolute__Page ${showImg}`}>
                        <ImagePage hide={() => setShowImg(false)} />
                    </div>
                </div>
            </PageLayout>
        </>
    )
}


// function PostContent({ content }) {
//   const paragraphs = content.split("\n");

//   return (
//     <div>
//       {paragraphs.map((paragraph, index) => (
//         <p key={index}>{paragraph}</p>
//       ))}
//     </div>
//   );
// }


export default FullPostComponent;