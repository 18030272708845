import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import { connect } from 'react-redux';
// import { createStructuredSelector } from "reselect";

// import { checkUserSession } from "./redux/user/user.actions";

import ProfilePage from "./pages/SportsPadi/ProfilePage/ProfilePage";
import ProfilePageX from "./pages/SportsPadi/ProfilePage/ProfilePageX";
import PostMain from "./pages/SportsPadi/Posts/PostMain";
import FullPostComponent from "./pages/SportsPadi/fullPostComponent/fullPostComponent";
import RequestInterview from "./pages/SportsPadi/BookInterview/RequestInterview/RequestInterview";
import BookInterview from "./pages/SportsPadi/BookInterview/BookInterview/BookInterview";
import TalentPool from "./pages/SportsPadi/TalentPool/TalentPool";
import Homepage from "./pages/homepage/homepage.component";
import Message from './pages/SportsPadi/Message/Message/Message';
import Messages from './pages/SportsPadi/Message/Messages/Messages';
import Notifications from './pages/SportsPadi/Notification/Notification';
import Login from "./pages/login/login.page";
import Register from './pages/regitser/register.page';
import { ToastContainer } from "react-toastify";


// Stylesheet
import './App.css';
import FeedsPage from './pages/feeds/feeds.page';
import VerifyEmailPage from './pages/verifyEmail/verify-email.page';
import SelectAccountComponent from './components/auth/register/registeras.component';
import AppWrapperComponent from './AppWrapper.component';
import RegisterPlayerPage from './pages/register-player/register-player.page';
import RegisterCoachPage from './pages/register-coach/register-coach.page';
import UserProfilePage from './pages/SportsPadi/ProfilePage/UserProfilePage';
import CoachEditProfile from './pages/ProfilePage/CoachEditProfile';
import VerifyUserProfilePage from './pages/VerifyUserProfilePage/VerifyUserProfilePage';
import AccountSelectionPage from './pages/AccountSelectionPage/AccountSelectionPage';
import NewAccountSetupPage from './pages/NewAccountSetup/NewAccountSetup';
import ShopKitsPage from './pages/ShopsKitsPage/ShopsKitsPage';
import ResetPasswordPage from './pages/reset-password/reset-password.page';
import ChoosePasswordPage from './pages/reset-password/choose-password.page';

const App = () => {
    return (
        <BrowserRouter>
        <ToastContainer style={{ zIndex: "999999"}}/>
            <Routes>
                <Route exact path="/" element={ <Homepage />}/>
               <Route exact path="/message/chat/:messageItemId" element={<Message />}/>
               <Route exact path="/post/:postId" element={<FullPostComponent />}/>
                <Route exact path="/schedule/book/:userId" element={<BookInterview />}/>
               <Route exact path="/pool" element={<TalentPool />}/>
               <Route exact path="/notifications" element={<Notifications />}/>
                <Route exact path="/profile/*" element={<ProfilePage />}/>
                <Route exact path='/user-profile/*' element={<UserProfilePage/>}/>
                {/* <Route exact path="/profile" element={<ProfilePageX />}/> */}
                <Route exact path="/message" element={<Messages />}/>
                <Route exact path="/schedule" element={<RequestInterview />}/>
                <Route exact path="/select-account" element={<AccountSelectionPage/>}/>
                <Route exact path="/setup-account/:accountType" element={<NewAccountSetupPage/>}/>
        {/* <Route exact path="/register-player" element={<RegisterPlayerPage/>}/>
        <Route exact path="/register-coach" element={<RegisterCoachPage/>}/> */}
                <Route exact path="/login" element={<Login />}/>
                <Route exact path="/signup" element={<Register />}/>
                <Route exact path="/posts" element={ <PostMain/>}/>
                <Route exact path="/verify-account/:token" element={<VerifyEmailPage/>}/>
                <Route exact path=':username/*' element={<VerifyUserProfilePage />} />
                <Route exact path='/shop' element={<ShopKitsPage />} />
                <Route exact path='/reset-password' element={<ResetPasswordPage />} />
                <Route exact path='/reset-password/:token' element={<ChoosePasswordPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
